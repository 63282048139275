import React from 'react';
import CHECKBOX_STATES from './CHECKBOX_STATES';

const Checkbox3Control = ({ label, value, onChange }) => {
    const checkboxRef = React.useRef();

    const [checked, setChecked] = React.useState(CHECKBOX_STATES.Indeterminate);
   
    React.useEffect(() => {                
        if (value === CHECKBOX_STATES.Checked) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
            setChecked(CHECKBOX_STATES.Checked)
        } 
        else if (value === CHECKBOX_STATES.Empty) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
            setChecked(CHECKBOX_STATES.Empty)
        } 
        else if (value === CHECKBOX_STATES.Indeterminate) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
            setChecked(CHECKBOX_STATES.Indeterminate)
        }       
    }, [value]);

    
 
    const handleChange = () => {
      let updatedChecked;
   
      if (checked === CHECKBOX_STATES.Checked) {
        updatedChecked = CHECKBOX_STATES.Empty;
      } else if (checked === CHECKBOX_STATES.Empty) {
        updatedChecked = CHECKBOX_STATES.Indeterminate;
      } else if (checked === CHECKBOX_STATES.Indeterminate) {
        updatedChecked = CHECKBOX_STATES.Checked;
      }   
      setChecked(updatedChecked);
      onChange(updatedChecked);
    };    
   
    return (
      <label>
        <input ref={checkboxRef} type="checkbox" onChange={handleChange} />
        {label}
      </label>
    );
};

export default Checkbox3Control;

