import React, { useEffect, useState } from 'react'
import { AiOutlineSave } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';


import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group';
import AnsprechpartnerList from '../anprechpartner/AnsprechpartnerList';
import KontenList from '../konten/KontenList';
import LastEdit from '../../components/app/last-edit'
import { selectForEinrichtungsartCombo } from '../../util/combo-utils';
import FetchService from '../../services/FetchService';
import { formatPlz } from '../../util/formate';
import ParseResult from '../../util/ParseResult';
// import HistoryStack from '../../util/HistoryStack';
import { useHistory } from 'react-router-dom';
import { authenticationService } from '../../services';
import UpdateHandler from '../../util/UpdateHandler';
import { WindowNames } from '../WindowNames';

const EditEinrichtung = (props) => {

    const H = useHistory()

    const [einrichtungsId, setEinrichtungsId] = useState(new URL(window.location.href).searchParams.get("eid")); 

    const [errorText, setErrorText] = useState(null)
    const [id, setId] = useState(null)
    const [kurzBezeichnung, setKurzbezeichnung] = useState("");
    const [ik, setIk] = useState(null);
    const [prevIk, setPrevIk] = useState(null);
    const [name1, setName1] = useState(null);
    const [name2, setName2] = useState(null);
    const [name3, setName3] = useState(null);
    const [name4, setName4] = useState(null);
    const [wbVerbund, setWbVerbund] = useState(1);
    const [kvBezirk, setKvBezirk] = useState(null);
    const [addressWeitergabe, setAddressWeitergabe] = useState(0);
    const [einrichtungsArt, setEinrichtungsArt] = useState(1);
    const [verwendungszweck, setverwendungszweck] = useState(null);
    const [bemerkungen, setBemerkungen] = useState(null);
    const [postleitzahl ,setPostleitzahl] = useState(null);
    const [ort, setOrt] = useState(null);
    const [strasseHausnrPostfach, setStrasseHausnrPostfach] = useState(null)
    const [addressZusatz, setAddressZusatz] = useState("- Personalverwaltung -")
    const [a2postleitzahl, setA2postleitzahl] = useState(null)
    const [a2Ort, setA2Ort] = useState(null)
    const [a2StrasseHausnrPostfach, setA2StrasseHausnrPostfach ] = useState(null)
    const [anzahlTeilnehmer, setAnzahlTeilnehmer] = useState(null)
    const [anzahlMassnahmen, setAnzahlMassnahmen] = useState(null)
    const [nichtAbgerechneteMassnahmen, setNichtAbgerechneteMassnahmen] = useState(null)
    const [editUser,setEditUser] = useState(null)
    const [editTime, setEditTime] = useState(null)

    const [addressWeitergabeData, setAddressWeitergabeData] = useState([{key:0, value:""}])
    const [canEdit] = useState(authenticationService.currentUser.category > 0)

    useEffect( () => {
        function asyncFetchAddressWeitergabeForSelect()
        {
            const result = []
            result.push( { key: 1, value:"Nein" } );
            result.push( { key: 0, value:"Ja" } );
            setAddressWeitergabeData( result );                     
            return;
        }
        asyncFetchAddressWeitergabeForSelect()
    }, [])

    const [einrichtungsArtData, setEinrichtungsArtData] = useState([{key:0, value:""}])

    useEffect( () => {
        async function asyncFetcheinrichtungsArtForSelect()
        {
            setEinrichtungsArtData(await selectForEinrichtungsartCombo())           
        }
        asyncFetcheinrichtungsArtForSelect()
    },[])

    const [wbVerbundData, setWbVerbundData] = useState([{key:0, value:""}])

    useEffect( () => {
        async function asyncFetchWeiterbildungsVerbundForSelect()
        {
            const defaultError = [{
                key : 0,
                value: "",
            }]
            try {
                const response = await fetch('/data/wbverbund/select')
                if (response.ok) {
                    const result = await response.json();    
                    // console.log("wb_verbund " + JSON.stringify(result))
                    if (Array.isArray(result))
                    {
                        setWbVerbundData(result)
                        return;
                    }
                    defaultError[0].value = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    defaultError[0].value = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setWbVerbundData(defaultError)
                console.log(err)
            }
        }
        asyncFetchWeiterbildungsVerbundForSelect()
    }, [])

    const [kvBezirkData, setkvBezirkData] = useState([{key:0, value:""}])

    useEffect(() => {
        async function asyncFetchKvForSelect()
        {
            const defaultError = [{
                key : 0,
                value: "",
            }]
            try {
                const response = await fetch('/data/kv/select')
                if (response.ok) {
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {
                        setkvBezirkData(result)
                        return;
                    }
                    defaultError[0].value = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    defaultError[0].value = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setkvBezirkData(defaultError)
                console.log(err)
            }
        }        
        asyncFetchKvForSelect()
    }, [])

    useEffect(() => {
        async function asyncFetchEinrichtungForSelect()
        {                        
            try {
                const response = await fetch('/data/einrichtungen/' + einrichtungsId, { method: 'GET' })
                if (response.ok) {
                    // console.log("response ok")
                    const result = await response.json();  
                    setErrorText("") 
                                       
                    setId(result.id);
                    setIk(result.ik);
                    setPrevIk(result.ik_alt);                        
                    setKurzbezeichnung(result.kurzbezeichnung);
                    setName1(result.name_1);
                    setName2(result.name_2);
                    setName3(result.name_3);
                    setName4(result.name_4);
                    setWbVerbund(result.wb_verbund);
                    setKvBezirk(result.kvid_kbv);
                    setBemerkungen(result.bemerkungen);
                    // console.log("result       " + result.bemerkungen);
                    // console.log("bemerkungen  " + bemerkungen);

                    setverwendungszweck(result.verwendungszweck);
                    setAddressWeitergabe(result.keine_adressweitergabe);                        
                    setEinrichtungsArt(result.einrichtungsArt);
                    setStrasseHausnrPostfach(result.strasse_hausnr_postfach);
                    setA2StrasseHausnrPostfach(result.a2_strasse_hausnr_postfach);
                    setOrt(result.ort);
                    setA2Ort(result.a2_ort);
                    setPostleitzahl(formatPlz(result.postleitzahl));
                    setA2postleitzahl(result.a2_postleitzahl);    
                    setEditUser(result.LetzterBearbeiter);
                    setEditTime(result.LetzteAenderung);           
                    // console.log("AddressWeitergabe gelesen " + addressWeitergabe)         
                    return result;
                }
                else 
                {
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                console.log(err)
                console.log(JSON.stringify(err))    
                setErrorText(err) 
            }                
        }   
        if (einrichtungsId) {
            asyncFetchEinrichtungForSelect();
        }
        
    } , [einrichtungsId])

    useEffect(() => {
        async function asyncFetchForStat()
        {
            try {
                const einrichtungsId = new URL(window.location.href).searchParams.get("eid");
                // console.log("einrichtungsId " + einrichtungsId)
                const response = await fetch('/data/einrichtungen/stat/' + einrichtungsId,
                {
                    method: 'POST'
                })
                if (response.ok) {
                    const result = await response.json();    
                    setAnzahlTeilnehmer(result.anzahlTeilnehmer)
                    setAnzahlMassnahmen(result.anzahlMassnahmen)
                    setNichtAbgerechneteMassnahmen(result.nichtAbgerechneteMassnahmen)
                }
                else
                {                    
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {                
                console.log(err)
            }
        }     
        const einrichtungsId = new URL(window.location.href).searchParams.get("eid"); 
        if (einrichtungsId && !id) {  
            setId(einrichtungsId)
            asyncFetchForStat()
        }           
    }, [id])

    const savedata = async () => 
    {
        // @todo alle Felder in der Datenbank können leer sein validierung?        

        if (!kurzBezeichnung) {
            alert("Fehler: Es muss eine KurzBezeichnung angegeben werden.")
            return
        }
        const data = 
        {
            id : einrichtungsId,
            ik : ik, 
            ik_alt : prevIk,
            kurzbezeichnung : kurzBezeichnung,
            name_1 : name1,
            name_2 : name2,
            name_3 : name3,
            name_4 : name4,
            einrichtungsArt : einrichtungsArt,
            wb_verbund : wbVerbund,
            kvid_kbv : kvBezirk,
            bemerkungen : bemerkungen,
            verwendungszweck : verwendungszweck,
            keine_adressweitergabe : addressWeitergabe,
            postleitzahl : postleitzahl,
            ort : ort,
            strasse_hausnr_postfach: strasseHausnrPostfach,
            adressZusatz: addressZusatz,
            a2_postleitzahl : a2postleitzahl,
            a2_ort : a2Ort,
            a2_strasse_hausnr_postfach: a2StrasseHausnrPostfach,
            // LetzterBearbeiter // Datenschicht
            // LetzteAenderung   // Datenschicht
        }
        
        // console.log(JSON.stringify(data))
        const msg = await FetchService.saveEinrichtung(data)
        if (!msg || !msg.startsWith("OK"))
        {
            alert("Fehler: " + msg)
            return;
        } 
        if (id) 
        {
            UpdateHandler.updateStorageEID(id)
            if (window.name === WindowNames.EID)
            {
                window.close()
            }
            else
            {
                window.history.back();
            }          
        }
        else 
        {
            const newid = ParseResult.parseIdFromText(msg);
            setId(newid);
            setEinrichtungsId(newid);
            H.replace('/Einrichtung/Edit?eid=' + newid)            
            UpdateHandler.updateStorageEID(newid)
        }
    }

    const canceldata = () => {
        // window.location = document.referrer;                
        if (window.name === WindowNames.EID)
        {
            window.close()
        }
        else
        {
            window.history.back();
        }     
    }

    function notifyUpdate(e) 
    {     
        if (e.newValue) { // set storage != null, remove storage == null 
            if (e.key === 'UPDATEMM') {                                       
            } 
        }
    }

    useEffect(() => {
        window.addEventListener('storage', notifyUpdate)
        return () => {
            window.removeEventListener('storage', notifyUpdate)        
        }
    }, [])

    
    return (
        <div>
            {            
                errorText && <h3>{`${errorText}`}</h3>   
            }
            <div className="sm_table_div">
                <table>
                    <thead>
                        <tr className="table-blank-row"></tr>         
                        <tr>
                            <th colSpan="6"><h3>{ einrichtungsId ? 'Einrichtung bearbeiten' : 'Einrichtung anlegen'}{canEdit ? '' : ' (Ansicht)'} </h3></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-blank-row"></tr>
                        <tr>
                            <td></td>
                            <td colSpan="1" className="td_label">Kurzbezeichnung:</td>
                            <td colSpan="3" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="kurzBezeichnung"                             
                                    value={kurzBezeichnung} 
                                    onChange={setKurzbezeichnung}
                                    disabled={!canEdit}                                   
                                />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>        
                        <tr>
                            <td></td>
                            <td colSpan="1" className="td_label">ID:</td>                                                                                
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="id"                             
                                    value={id} 
                                    onChange={setId} 
                                    readonly
                                    disabled={!canEdit}  
                                />
                            </td>
                            <td className="td_label">IK:</td>                       
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="ik"                             
                                    value={ik} 
                                    onChange={setIk}   
                                    disabled={!canEdit}                                   
                                />
                            </td>
                            <td className="td_label">Vorherige IK:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="previk"                             
                                    value={prevIk} 
                                    onChange={setPrevIk} 
                                    readonly
                                    disabled={!canEdit}  
                                />
                            </td>
                            <td></td>                            
                        </tr>                        
                        <tr>
                            <td></td>
                            <td colSpan="1" className="td_label">Name 1:</td>                                                        
                            <td colSpan="3" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="name1"                             
                                    value={name1} 
                                    onChange={setName1} 
                                    disabled={!canEdit} 
                                />
                            </td>
                            <td className="td_label">Anzahl Teilnehmer:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name=""                             
                                    value={anzahlTeilnehmer} 
                                    onChange={setAnzahlTeilnehmer} 
                                    readonly
                                    disabled={!canEdit}  
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>                            
                            <td className="td_label">Name 2:</td>                 
                            <td colSpan="3" className="td_input">
                                <FormGroup                             
                                    type="text"
                                    label=""
                                    name="name2"                             
                                    value={name2} 
                                    onChange={setName2} 
                                    placeholder=""
                                    autocomplete="0"
                                    disabled={!canEdit} 
                                />
                            </td>
                            <td className="td_label">Anzahl Maßnahmen:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name=""                             
                                    value={anzahlMassnahmen} 
                                    onChange={setAnzahlMassnahmen} 
                                    readonly
                                    disabled={!canEdit}  
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="td_label">Name 3:</td>
                            <td colSpan="3" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="name3"                             
                                    value={name3} 
                                    onChange={setName3} 
                                    disabled={!canEdit} 
                                />
                            </td>
                            <td className="td_label">Noch nicht abgerechnet:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name=""                             
                                    value={nichtAbgerechneteMassnahmen} 
                                    onChange={setNichtAbgerechneteMassnahmen} 
                                    readonly
                                    disabled={!canEdit}  
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="td_label">Name 4:</td>
                            <td colSpan="3" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="name4"                             
                                    value={name4} 
                                    onChange={setName4} 
                                    disabled={!canEdit} 
                                />
                            </td>
                            <td className="td_label_left">Bemerkungen:</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="td_label">Ist in Weiterbildungsverbund:</td>
                            <td className="td_select">
                                <FormGroup 
                                    type="select" label="" 
                                    name="wbVerbund"
                                    options={wbVerbundData}
                                    value={wbVerbund}
                                    onChange={setWbVerbund} 
                                    readonly={!canEdit} 
                                />
                            </td>                  
                            <td className="td_label">Adressweitergabe:</td>
                            <td className="td_select">
                                <FormGroup 
                                    type="select" label="" 
                                    name="addressWeitergabe"
                                    options={addressWeitergabeData}
                                    value={addressWeitergabe} 
                                    onChange={setAddressWeitergabe} 
                                    readonly={!canEdit} 
                                />
                            </td>
                            <td colSpan="3" rowSpan="3" className="td_input">
                                <FormGroup 
                                    type="textarea"
                                    label=""
                                    name="name4"                             
                                    value={bemerkungen} 
                                    onChange={setBemerkungen} 
                                    disabled={!canEdit} 
                                />
                            </td>                                  
                        </tr>
                        <tr>
                            <td></td>
                            <td className="td_label">KV Bezirk (Region bei Altfällen):</td>                            
                            <td className="td_select">
                                <FormGroup 
                                    type="select" label="" 
                                    name="kvBezirk"
                                    options={kvBezirkData}
                                    value={kvBezirk}
                                    onChange={setKvBezirk} 
                                    readonly={!canEdit} 
                                />
                            </td>                      
                            <td className="td_label">Einrichtungsart:</td>
                            <td className="td_select">
                                <FormGroup 
                                    type="select" label=""
                                    name="einrichtungsArt"
                                    options={einrichtungsArtData}
                                    value={einrichtungsArt} 
                                    onChange={setEinrichtungsArt} 
                                    readonly={!canEdit} 
                                />
                            </td>  
                        </tr>
                        <tr>
                            <td></td>
                            <td className="td_label">Abweichender Verwendungszweck:</td>
                            <td colSpan="3" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="name4"                             
                                    value={verwendungszweck} 
                                    onChange={setverwendungszweck} 
                                    disabled={!canEdit}
                                />
                            </td>    
                        </tr> 
                        <tr className="table-blank-row"></tr>
                        <tr>
                            <td colSpan="2" className="td_label">Hauptadresse für Anschreiben (Straße|PF /PLZ Ort):</td>                            
                        </tr>
                        <tr>
                            <td className="td_label">Straße oder Postfach:</td>
                            <td colSpan="1" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="strasseoderpostfach"                             
                                    value={strasseHausnrPostfach} 
                                    onChange={setStrasseHausnrPostfach} 
                                    disabled={!canEdit}
                                />
                            </td>                                          
                            <td className="td_label">Postleitzahl:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="postleitzahl"                             
                                    value={postleitzahl} 
                                    onChange={setPostleitzahl} 
                                    disabled={!canEdit}
                                />
                            </td>
                            <td className="td_label">Ort:</td>
                            <td colSpan="1" className="td_input">                                
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="ort"                             
                                    value={ort} 
                                    onChange={setOrt} 
                                    disabled={!canEdit}
                                />
                            </td>         
                            <td className="td_label">Adresszusatz:</td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="addressZusatz"                             
                                    value={addressZusatz} 
                                    onChange={setAddressZusatz} 
                                    disabled={!canEdit}
                                />
                            </td>                           
                        </tr>

                        <tr className="table-blank-row"></tr>
                        <tr>
                            <td colSpan="2" className="td_label">Zweite Adresse für Anschreiben (Straße|PF /PLZ Ort):</td>
                        </tr>
                        
                        <tr>
                            <td className="td_label">Straße oder Postfach:</td>        
                            <td colSpan="1" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="a2StrasseHausnrPostfach"                             
                                    value={a2StrasseHausnrPostfach} 
                                    onChange={setA2StrasseHausnrPostfach} 
                                    disabled={!canEdit}
                                />
                            </td>                           
                            <td className="td_label">Postleitzahl:</td>                                
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="a2postleitzahl"                             
                                    value={a2postleitzahl} 
                                    onChange={setA2postleitzahl} 
                                    disabled={!canEdit}
                                />
                            </td>     
                            <td className="td_label">Ort:</td>                                
                            <td colSpan="1" className="td_input">
                                <FormGroup 
                                    type="text"
                                    label=""
                                    name="a2Ort"                             
                                    value={a2Ort} 
                                    onChange={setA2Ort} 
                                    disabled={!canEdit}
                                />
                            </td>                                                        
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className="table-blank-row"></tr>
                        <tr>
                            <td></td>
                            <td colSpan="4">
                                <LastEdit edituser={editUser} edittime={editTime}></LastEdit>
                            </td>
                        </tr>
                        <tr className="table-blank-row"></tr>
                        <tr>                        
                            <td></td>
                            <td></td>

                            <td className="td_label_right">
                                <button className="exportbtn" title="Speichern der Einrichtung" onClick={savedata} disabled={!canEdit}>
                                    <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                                </button>                              
                            </td>                        
                            <td >                               
                                <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>
                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                </button>    
                            </td>                          
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className="table-blank-row"></tr>
                    </tbody>
                </table>                
            </div>    
            <div className="sm_table_div">
                <table className="sm_table"> 
                    <tbody>
                        <tr>
                            {einrichtungsId && <td width="37%" valign="top" colSpan="1" ><KontenList eid={einrichtungsId}/></td> }
                            {einrichtungsId && <td valign="top" colSpan="1" ><AnsprechpartnerList eid={einrichtungsId}/></td> }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default EditEinrichtung



