import React, { useEffect, useState } from 'react'
import { AiOutlineSave } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';

import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group'
import LastEdit from '../../components/app/last-edit'
import FetchService from '../../services/FetchService';
import { formatPlz } from '../../util/formate';
import UpdateHandler from '../../util/UpdateHandler';
import { WindowNames } from '../WindowNames';

function AnsprechpartnerEdit( props ) {    
    
    const uri = new URL(window.location.href)
    const eid = uri.searchParams.get("eid");
    const aspid = uri.searchParams.get("aspId");

    const [einrichtungsName, setEinrichtungsName] = useState(null);  
    
    const [anrede, setAnrede] = useState(null)
    const [titel, setTitel] = useState(null)
    const [vorname, setVorname] = useState(null)
    const [nachname, setNachname] = useState(null)
    const [telefon, setTelefon] = useState(null)
    const [email, setEmail] = useState(null)
    const [verwendungszweck, setVerwendungszweck] = useState(null)
    const [hatSonderAnschrift, setHatSonderAnschrift]  = useState(false)
    const [adresszusatz, setAdresszusatz] = useState(null)
    const [strasseOderPostfach, setStrasseOderPostfach] = useState(null)
    const [plz, setPlz] = useState(null)
    const [ort, setOrt] = useState(null)
    const [ausgeschieden, setAusgeschieden] = useState(false)
    const [anzahlMassnahmen, setAnzahlMassnahmen] = useState(-1)

    const [editUser, setEditUser] = useState(null);
    const [editTime, setEditTime] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    const [anreden, setAnreden] = useState(null)
   
    useEffect(() => {
        async function asyncLoadAnredenForSelect()
        {       
            const defaultError = [{
                key : 0,
                value: "",
            }]
            try {
                const response = await fetch('/data/anreden/select')
                if (response.ok) {
                    const result = await response.json();                        
                    if (Array.isArray(result))
                    {                        
                        setAnreden(result)                        
                        return;
                    }
                    defaultError[0].value = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    defaultError[0].value = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                setAnreden(defaultError)
                console.log(err)
            }
        }
        asyncLoadAnredenForSelect();
    }, [])

    useEffect(() => {        
        async function asyncLoadAnsprechpartner()
        {
            try {                
                const response = await fetch('/data/ansprechpartner/' + eid + "/" + aspid)
                if (response.ok) {                    
                    const result = await response.json();   
                    // console.log(result) 
                    if (Array.isArray(result) && (result.length > 0))
                    {                        
                        setEditUser(result[0].LetzterBearbeiter)
                        setEditTime(result[0].LetzteAenderung)              
                        setEinrichtungsName("Ansprechpartner der Einrichtung " +
                            result[0].Einrichtungen.kurzbezeichnung + ", " +
                            result[0].Einrichtungen.ort
                        )                        
                        // setIsLoaded(true)   
                        setVorname( result[0].vorname)
                        setNachname( result[0].nachname)
                        setTitel( result[0].titel)
                        setAnrede( result[0].anrede)
                        setTelefon( result[0].telefon)
                        setEmail( result[0].email)
                        setSonderAnschriftCheck ( result[0].hatSonderAnschrift)
                        setStrasseOderPostfach( result[0].strasseOderPostfach)
                        setAdresszusatz( result[0].adresszusatz)
                        setPlz( formatPlz(result[0].plz))
                        setOrt( result[0].Ort)
                        setVerwendungszweck( result[0].verwendungszweck)
                        setAusgeschiedenCheck( result[0].ausgeschieden)  
                        setAnzahlMassnahmen( result[0].anzahlmassnahmen)                          
                        return;
                    }                   
                    throw new Error("Ansprechpartner mit ID = " + aspid + " nicht gefunden")                                     
                }
                else
                {                     
                   throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {                
                 console.log(err)
                 setErrorMessage(err)
            }              
        }
        if ((aspid) && (eid)) {
            asyncLoadAnsprechpartner()
        }
    }, [aspid, eid])

    useEffect(() => {
        async function asyncLoadEinrichtung()
        {               
            try {                                
                const response = await fetch('/data/einrichtungen/' + eid)
                if (response.ok) {   
                    const result = await response.json();
                    setEinrichtungsName("Neuen Ansprechpartner für die Einrichtung " +
                        result.kurzbezeichnung + ", " +
                        result.ort)                    
                    return;
                }
                throw new Error(response.status + " " + response.statusText)
            }
            catch (err) {                
                console.log(err)
                setErrorMessage(err)
           }              
        }
        if ((!aspid) && (eid)) {
            asyncLoadEinrichtung()
        }        
    }, [aspid, eid])

    const setSonderAnschriftCheck = (value) =>
    {
        // console.log("setSonderAnschriftCheck " + value)
        setHatSonderAnschrift(value);        
        document.getElementsByName("adresszusatz")[0].disabled = !value;
        document.getElementsByName("strasseoderpostfach")[0].disabled = !value;
        document.getElementsByName("postleitzahl")[0].disabled = !value;
        document.getElementsByName("ort")[0].disabled = !value;
    }

    const setAusgeschiedenCheck = (value) => 
    {
        setAusgeschieden(value)
        document.getElementsByName("anrede")[0].disabled = value;
        document.getElementsByName("titel")[0].disabled = value;
        document.getElementsByName("vorname")[0].disabled = value;
        document.getElementsByName("nachname")[0].disabled = value;
        document.getElementsByName("telefon")[0].disabled = value;
        document.getElementsByName("email")[0].disabled = value;
        document.getElementsByName("verwendungszweck")[0].disabled = value;
    }

    const savedata = async () =>
    {    
        const data = { 
            id:aspid , einrichtungen_id : eid,
            vorname, nachname,  titel, anrede, telefon, email, 
            hatSonderAnschrift, strasseOderPostfach, adresszusatz,
            plz, ort, verwendungszweck,  ausgeschieden            
        };
        if (!nachname) {
            alert("Fehler: der Nachname ist erforderlich")
            return;
        }
        const msg = await FetchService.saveAnsprechpartner(data);
        if (!msg || !msg.startsWith("OK")) // !== "OK")
        {
            alert("Fehler: " + msg)
            return;
        } 
        console.log(msg)
        if (aspid)
        {
            //update
            UpdateHandler.updateStorageASP(eid)
            window.history.back();        
        }
        else
        {            
            //new
            
            if (window.name ===  WindowNames.ASP)
            {
                window.close()
                UpdateHandler.updateStorageASP(eid)
            }
            else
            {
                UpdateHandler.updateStorageASP(eid)
                window.history.back()
            }            
        }
        // window.location = document.referrer;
        
    }

    const canceldata = () =>
    {
        if (window.name === WindowNames.ASP)
        {
            window.close()
        }
        else
        {
            window.history.back();
        }
    }

    return (
        <>
        {
            (errorMessage) && <h3>{`${errorMessage}`}</h3>   
        }
        { 
            (!errorMessage) && <div className="sm_table_div"> 
            <table  className="sm_table_60"> 
                <thead>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <th colSpan="5"><h3>{`${einrichtungsName}`}</h3></th>                      
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td className="td_label">Anrede:</td>
                        <td className="td_select">
                            <FormGroup 
                                type="select"
                                label=""
                                name="anrede"                             
                                options={anreden}
                                value={anrede} 
                                onChange={setAnrede}                                 
                            />                          
                        </td>
                        <td width="20px"></td>
                        <td className="td_label">Sonderanschrift:</td>
                        <td className="td_check" colSpan="2">                                
                            <FormGroup 
                                type="checkbox"
                                label=""
                                name="hatsonderanschrift"                             
                                value={hatSonderAnschrift} 
                                onChange={setSonderAnschriftCheck}                                 
                            />                          
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Titel:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="titel"                             
                                value={titel} 
                                onChange={setTitel}                              
                            />                     
                        </td>
                        <td></td>                        
                        <td className="td_label">Ort:</td>
                        <td className="td_input">
                            <FormGroup
                                type="text" 
                                label="" 
                                name="ort"
                                value={ort} 
                                onChange={setOrt} 
                                disabled
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Vorname:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="vorname"                             
                                value={vorname} 
                                onChange={setVorname}                                                            
                            />
                        </td>
                        <td></td>
                        <td className="td_label">Straße oder Postfach:</td>
                        <td className="td_input">
                            <FormGroup
                                type="text" label="" 
                                name="strasseoderpostfach"
                                value={strasseOderPostfach} 
                                onChange={setStrasseOderPostfach} 
                                disabled
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Nachname:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="nachname"                             
                                value={nachname} 
                                onChange={setNachname}                                                   
                            />
                        </td>
                        <td></td>
                        <td className="td_label">Postleitzahl:</td>
                        <td className="td_input">
                            <FormGroup
                                type="text" 
                                label="" 
                                name="postleitzahl"
                                value={plz} 
                                onChange={setPlz} 
                                disabled
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Telefon:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="telefon"                             
                                value={telefon} 
                                onChange={setTelefon}                                                        
                            />
                        </td>
                        <td></td>
                        <td className="td_label">Adresszusatz:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="adresszusatz"                             
                                value={adresszusatz} 
                                onChange={setAdresszusatz}       
                                disabled                       
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Email:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="email"                             
                                value={email} 
                                onChange={setEmail}                                                             
                            />
                        </td>
                        <td></td>
                        <td className="td_label">Ausgeschieden:</td>
                        <td className="td_check">
                            <FormGroup
                                type="checkbox" 
                                label="" 
                                name="ausgeschieden"
                                value={ausgeschieden} 
                                onChange={setAusgeschiedenCheck} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Abweichender Verwendungszweck:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="verwendungszweck"                             
                                value={verwendungszweck} 
                                onChange={setVerwendungszweck}                                                             
                            />
                        </td>
                        <td></td>
                        <td className="td_label">Anzahl Maßnahmen:</td>
                        <td className="td_input">
                            <FormGroup
                                type="text" 
                                label="" 
                                name="anzahlMassnahmen"
                                value={anzahlMassnahmen} 
                                onChange={setAnzahlMassnahmen}
                                readonly 
                            />
                        </td>
                    </tr>                    
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td></td>
                        <td colSpan="4">
                            <LastEdit edituser={editUser} edittime={editTime}></LastEdit>
                        </td>
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr>                        
                        <td className="td_label_right">
                            <button className="exportbtn" title="Änderungen speichern" onClick={savedata}>                                
                                <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                            </button>                                
                        </td>                        
                        <td >
                            <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>                                
                                <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                            </button>    
                        </td>                
                    </tr>
                </tbody>
            </table>            
        </div>
        }
        </>
    )
}

export default AnsprechpartnerEdit
