import React, { useEffect, useState } from 'react' // , useRef
import { AiOutlineSave, AiOutlineFileWord, AiOutlineMail } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';
import FileSaver from 'file-saver';
import { registerLocale } from 'react-datepicker';


// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import "react-datepicker/dist/react-datepicker.css";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import de from "date-fns/locale/de";

import { ZConfig } from '../../context/ZConfig';
import ConfigIcon from '../../util/configicon';
import LastEdit from '../../components/app/last-edit'
import FormGroup from '../../components/shared/form-group'
import Modal from '../../components/shared/modal/Modal';
import { FetchService, StorageService } from '../../services';
import ParseResult from '../../util/ParseResult';
import { formatTeilnehmer, formatEinrichtung } from '../../util/formate'
import { 
        selectForStatusCombo , selectForEinrichtungsartCombo, 
        selectForAspCombo, selectForFachgebietCombo , selectForKontenCombo
       } from '../../util/combo-utils.js' // , selectForFachgebietComboAll

import { round, formatCurrency } from '../../util/number-utils.js'
// import { getFuseOptions } from '../../util/search';
import { getReactSearchAutocompleteStyle } from '../../util/style';
import HorizontalTabs from '../../components/shared/horizontal-tabs/HorizontalTabs';
// import VerticalTabs from '../../components/shared/vertical-tabs/VerticalTabs';
import Auszahlung from './Auszahlung';
import Kuerzung from './Kuerzung';
import Verrechnung from './Verrechnung';
import KuerzungNachZahlung from './KuerzungNachZahlung';
import MassnahmeAufteilen from './MassnahmeAufteilen';
import KuerzungNachZahlungDialog from './KuerzungNachZahlungDialog';
import Dokumente from './DokumenteEinerMassnahme.js';
import { useHistory } from 'react-router-dom';
import { authenticationService } from '../../services';
import { X, Y } from './X';
import { TABS } from './TABS';
import { WindowNames } from '../WindowNames';
import { toShortDateString } from '../../util/date-utils';
import NonModal from '../../components/shared/nonmodal/NonModal';
import { PO } from './PO';
import BescheinigungErstellen from './BescheinigungErstellen';
// import DragDropUploadForm from '../../components/shared/dndupload/DragDropUploadForm';
import UpdateHandler from '../../util/UpdateHandler';
// import { AppContext } from '../../context/AppContext';

registerLocale("de", de)

const po = new PO("EditMassnahme")

const EditMassnahme = (props) => 
{
    const YY5 = 86400 * 1000 * 365 * 5;

    // console.log(Values.INV_DATE)
    // console.log(Dates.INV_DATE)
    // new Dates().print(" message " )

    // const X.B1  = 0x1      // 1  | Beantragt                    | NULL                                                                                |    NULL |
    // const X.B2  = 0x2      // 2  | RückfrageZuAntrag            | NULL                                                                                |    NULL |
    // const X.B3  = 0x4      // 3  | Bestätigt                    | NULL                                                                                |    NULL |
    // const X.B4  = 0x8      // 4  | ÄnderungAntrag               | NULL                                                                                |    NULL |
    // const X.B5  = 0x10     // 5  | KorrekturBestätigt           | NULL                                                                                |    NULL |
    // const X.B6  = 0x20     // 6  | AbgeschlossenOhneNachweis    | Ist geschlossen worden (Storniert)                                                  |    NULL |
    // const X.B7  = 0x40     // 7  | NachweisEingegangen          | NULL                                                                                |    NULL |
    // const X.B8  = 0x80     // 8  | RückfrageZuNachweis          | NULL                                                                                |    NULL |
    // const X.B9  = 0x100    // 9  | NachweisBestätigt            | Vorgesehen für nächsten Zahlungslauf                                                |    NULL |
    // const X.B10 = 0x200    // 10 | ÄnderungNachweis             | Änderungswunsch ging ein -> entweder Rückfrage oder neue Bestätigung                |    NULL |
    // const X.B11 = 0x400    // 11 | MassnahmeAusTeilnachweis     | Entsteht wenn Teilnachweise erstellt werden für die Restlaufzeit der Massnahme      |    NULL |
    // const X.B12 = 0x800    // 12 | ZahlungAngewiesen            | In SEPA-Liste aufgenommen                                                           |    NULL |
    // const X.B13 = 0x1000   // 13 | ZahlungsRücklauf             | Überweisung nicht erfolgreich                                                       |    NULL |
    // const B14 = 0x2000     // 14 | KorrekturNachweis            | NULL                                                                                |    NULL |
    // const B15 = 0x4000     // 15 | KorrekturNachweisVerrechnet  | NULL                                                                                |    NULL |
    // const B16 = 0x8000     // 16 | MaßnahmeStorniert            | Endgültig zurückgezogen oder Fehleingabe                                            |    NULL |
    // const B17 = 0x10000    // 17 | ZahlungAngewiesenGekürzt     | Nach Abrechnung wurde Kürzung vorgenommen                                           |    NULL |
    // const X.B18 = 0x20000  // 18 | VormerkungKürzungNachZahlung | Markierung für Ausgezahlte Maßnahmen für die noch Maßnahmen zur Verrechnung fehlen! |    NULL |
    // const X.B19 = 0x40000  // 19 | RechnungGestellt             | Maßnahme ist in Rechnung an Kassen aufgenommen                                      |    NULL |   
    
    let mid = new URL(window.location.href).searchParams.get("mid");    
    let tabpage = new URL(window.location.href).searchParams.get("tabpage");
    let TID 
    let EID    

    const [teilnehmerId, setTeilnehmerId] = useState(null)    
    const [einrichtungsId, setEinrichtungsId] = useState(null)
    const [massnahmeId, setMassnahmeId] = useState(null)  
    const [reload_on_update, set_reload_on_update] = useState(false)

    const modalid = 'ModalKnz';
    const nonmodalid = 'NonModalEditMassnahme';

    //#region     
    const [showprintdialog, setshowprintdialog] = useState(false)    

    po.initComponent(this, setshowprintdialog)
    
    function showPrintDialog()
    {            
        if (po && po.printid && po.printmessage) 
        {
            const element = document.getElementById(nonmodalid)
            if (element) {
                element.style.display = 'block';                                    
            }        
        }
    }

    function documentprint_callback(cmd, param) { po.documentprint_callback(cmd, param)}

    useEffect(() => {
        async function func()
        { 
            if (showprintdialog) { showPrintDialog() }
        }        
        func()
    }, [showprintdialog]); 
    // #end region

    if (!mid)
    {
        let T = new URL(window.location.href).searchParams.get("tid");          
        if (!T) {            
            T = props.tid            
            // if (!T)
            //     T = StorageService.getTeilnehmerId()
        }
        let E = new URL(window.location.href).searchParams.get("eid");
        if (!E) {
            E = props.eid            
            // if (!E)
            //     E = StorageService.getEinrichtungenId()
        }
        if (!T && !E)
        {
            const mpt = StorageService.getMptData()
            T = mpt.tid
            E = mpt.eid 
        }
        TID = T
        EID = E        
        // console.log("EditMassnahme TID " + T + " EID " + E)
        // console.log(  (new Date(Date.now()-YY5).toISOString()).substring(0,10) )
        
    }
    
    const H = useHistory();

    function getMassnahmeStatusString() {
        if (massnahmenStatusSelectData && massnahmenStatusSelectData.length > 0) {
            const kvp = massnahmenStatusSelectData.find( (item) => { return item.key===status; })
            if (kvp) {
                return kvp.value + " (" + status + ")"
            }
        }             
        return ""
    }

    const [mindate, setmindate] = useState(null)
    const [maxdate, setmaxdate] = useState(null)
    
    //#region "Comboboxes"
    const [fachgebietSelectData, setFachgebietSelectData] = useState([{key:0, value:""}])
    useEffect( () => {
        async function asyncFetchFachgebietDataForSelect()
        {            
            setFachgebietSelectData(await selectForFachgebietCombo())            
        }
        setmindate ( (new Date(Date.now()-YY5).toISOString()).substring(0,10) )
        setmaxdate ( (new Date(Date.now()+YY5).toISOString()).substring(0,10) )
        asyncFetchFachgebietDataForSelect()
    },[])

    const [massnahmenStatusSelectData, setMassnahmenStatusSelectData] = useState([{key:1, value:""}])
    useEffect(() => {
        async function asyncFetchMassnahmenStatusForSelect()
        {            
            setMassnahmenStatusSelectData(await selectForStatusCombo())                          
        }
        asyncFetchMassnahmenStatusForSelect()
    }, [])
    
    const [einrichtungsArtSelectData, setEinrichtungsArtSelectData] = useState([{key:0, value:""}])
    useEffect( () => {
        async function asyncFetcheinrichtungsArtForSelect()
        {
            setEinrichtungsArtSelectData(await selectForEinrichtungsartCombo())            
        }
        asyncFetcheinrichtungsArtForSelect()
    },[])

    const [aspSelectData, setAspSelectData] = useState([{key:0, value:""}])
    const [aspSelectLoaded, setAspSelectLoaded] = useState(false)

    async function asyncFetchaspDataForSelect(eid)
    { 
        // console.log("asyncFetchaspDataForSelect")
        if ((eid)  || reload_on_update)
        {   
            const ad = await selectForAspCombo( eid )            
            setAspSelectLoaded(true)                 
            setAspSelectData(ad)                            
            return;
        }
        else
        {
            // console.log("aspSelectData NICHT gelesen.")
        }
    }

    const [kontenSelectData, setKontenSelectData] = useState([{key:0, value:""}])
    const [kontenSelectLoaded, setKontenSelectLoaded] = useState(false)

    async function asyncFetchKontenDataForSelect(eid)
    {   
        if (eid) {
            const kd = await selectForKontenCombo( eid )                   
            setKontenSelectLoaded(true)            
            setKontenSelectData(kd)            
            return
        }
        else
        {
            // console.log("kontenSelectData NICHT gelesen.")   
        }
    }
  
    const [errorText, setErrorText] = useState(null)   
    
    const [teilnehmerData, setTeilnehmerData] = useState("")
    const [einrichtungsData, setEinrichtungsData] = useState("")
    const [einrichtungsArt, setEinrichtungsArt] = useState(null)
    const [ik, setIk] = useState(null)
    const [aspId, setAspId] = useState(null)
    const [fvon, setfvon] = useState(null)
    const [fbis, setfbis] = useState(null)
    const [antragGekuerzt, setAntragGekuerzt] = useState(false)
    const [fachId, setFachId] = useState(null)
    const [prozentual, setProzentual] = useState(null)
    const [status, setStatus] = useState(1)
    const [antragsEingang, setAntragsEingang] = useState(null)
    const [nachweisBearbeitet, setNachweisBearbeitet] = useState("Invalid Date")
    const [bestaetigungsAusgang, setBestaetigungsAusgang] = useState("Invalid Date")
    const [nachweisBestaetigt1, setNachweisBestaetigt1] = useState("Invalid Date")
    const [nachweisBestaetigt2, setNachweisBestaetigt2] = useState(null)
    const [bescheinigungAerzteKammer, setBescheinigungAerzteKammer]  =useState(false)
    const [kontoid, setKontoId] = useState(null)
    const [orgdata, setorgdata] = useState(null)

    const [ausgezahltAm, setAusgezahltAm] = useState("Invalid Date")
    const [ausgezahlt, setAusgezahlt] = useState(null)
    const [betragBerechnet, setBetragBerechnet] = useState(null)    
    const [auszahlVermerk, setAuszahlVermerk] = useState(null)
    const [bemerkung, setBemerkung] = useState(null)
    const [kursHinweise, setKurshinweise] = useState(null)
    const [verrechnungsId, setVerechnungsId] = useState(0)    
    const [auszahlungen_id, setauszahlungen_id] = useState(0)
    const [kuerzungsId, setKuerzungsId] = useState(0)
    const [korr_mid_verrechnung, setkorr_mid_verrechnung] = useState(null)
    
    const [editUser, setEditUser] = useState(null)
    const [editTime, setEditTime] = useState(null)    
    
    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    const [canEditTID, setCanEditTID] = useState(canEdit)
    const [canEditEID, setCanEditEID] = useState(canEdit)

    
    useEffect( () => {   
        // console.log("load ASP from EinrichtungsId")        
        asyncFetchaspDataForSelect(einrichtungsId)
    },[einrichtungsId, reload_on_update])
    
    useEffect( () => {
        // console.log("load Konten")
        asyncFetchKontenDataForSelect(einrichtungsId)        
    },[einrichtungsId])    

    useEffect(() => {
        async function asyncFetchMassnahmeForSelect()
        {     
            setorgdata(null);     
            if (mid) {                
                try {                    
                    const response = await fetch('/data/massnahmen/' + mid, { method: 'GET' })
                    if (response.ok) {                        
                        const result = await response.json();                         
                        // console.log("MASSNAHME: " + JSON.stringify(result)) 
                        setorgdata(result)                       
                        setCanEditTID(false)
                        setCanEditEID(false)
                        setMassnahmeId(result.id)                        
                        setEinrichtungsId(result.einrichtungen_id) //, "fetch Massnahme")   
                        setTeilnehmerId(result.teilnehmer_id)

                        setVerechnungsId(result.verrechnungen_id)
                        setauszahlungen_id(result.auszahlungen_id)
                        setKuerzungsId(result.kuerzungen_id)

                        const en = formatEinrichtung(result.Einrichtungen, "inok")
                        setEinrichtungsData(en)

                        const tn = formatTeilnehmer(result.Teilnehmer)
                        setTeilnehmerData(tn)                        

                        setStatus(result.status)                        
                        setEinrichtungsArt(result.einrichtungsArt)
                        setIk(result.ik)
                        setKontoId(result.kontoid)                                             
    
                        _setfvon(new Date(Date.parse(result.f_von)), "load")    
                        _setfbis(new Date(Date.parse(result.f_bis)), "load")
    
                        
                        setAntragGekuerzt(result.antrag_gekuerzt)
                        setProzentual(result.prozentual)                       
                        _setFachId(result.fachId)
                        setAspId(result.asp_id)
                        setBescheinigungAerzteKammer(result.bescheinigung)                        
                        if (result.eingang_antrag) {
                            setAntragsEingang(new Date(Date.parse(result.eingang_antrag)))
                        }
                        if (result.nachweis_bearbeitet_am) {                       
                            setNachweisBearbeitet(new Date(Date.parse(result.nachweis_bearbeitet_am)))
                        }
                        if (result.ausgang_bestaet) {
                            setBestaetigungsAusgang(new Date(Date.parse(result.ausgang_bestaet)))
                        }
                        if (result.ausgezahlt_am) {
                            setAusgezahltAm(new Date(Date.parse(result.ausgezahlt_am)))
                        }

                        if (result.nachweis_gedruckt) {
                            setNachweisBestaetigt1(new Date(Date.parse(result.nachweis_gedruckt)))
                        }
                                     
                        setAusgezahlt(result.betragAusgezahlt) // formatCurrency(
                        setNachweisBestaetigt2(result.betragBestaetigt) // formatCurrency(
                        setBetragBerechnet(result.betragBerechnet) // formatCurrency(                          
                            
                        setAuszahlVermerk(result.auszahlvermerk)

                        setBemerkung(result.bemerkung)
                        setKurshinweise(result.kurs_bem)
                        
                        setEditUser(result.LetzterBearbeiter)
                        setEditTime(result.LetzteAenderung)

                        if (result.Auszahlungen) {
                            setVerwendungszweck(result.Auszahlungen.verwendungszweck)
                        }
                        
                        // console.log(result)                        
                        if ((result.einrichtungen_id) && (!aspSelectLoaded))
                        {
                            // console.log("Fetch ASP Data")
                            asyncFetchaspDataForSelect(result.einrichtungen_id)
                        }
                        if ((result.einrichtungen_id) && (!kontenSelectLoaded))
                        {                  
                            asyncFetchKontenDataForSelect(result.einrichtungen_id)
                        }
                        if (result.KuerzungenNachZahlung) {
                            setkorr_mid_verrechnung(result.KuerzungenNachZahlung.korr_mid_verrechnung)
                        }
                        const ti = 
                        [
                            { title : TABS.TAB_DOKUMENTE, component: Dokumente, data : JSON.stringify( 
                                { mid: result.id , aid: result.asp_id, eid: result.einrichtungen_id, tid: result.teilnehmer_id} )
                            },
                            { title : TABS.TAB_AUSZAHLUNG, component : Auszahlung, data : JSON.stringify(
                                [{ mid: result.id } , { aid: result.auszahlungen_id }] )
                            },
                            { title : TABS.TAB_KUERZUNG, component : Kuerzung, data : JSON.stringify(
                                [{ mid : result.id } , { kid: result.kuerzungen_id }]  )
                            },
                            { title : TABS.TAB_VERRECHNUNG, component : Verrechnung, data : JSON.stringify(
                                [{ mid: result.id } , { vid: result.verrechnungen_id }] )
                            }, 
                            { title : TABS.TAB_KUERZUNG_NZ, component: KuerzungNachZahlung, data : JSON.stringify(
                                [{ mid: result.id } , { kid: result.kuerzungennachzahlung_id }] )
                            },
                            { title : TABS.TAB_TEILEN, component : MassnahmeAufteilen, data : JSON.stringify(
                                [{mid: result.id }] )
                            }
                        ]
                        setTabitems(ti)                        
                        return result;
                    }
                    else 
                    {
                        throw new Error(response.status + " " + response.statusText)
                    }
                }
                catch (err) {
                    console.log(err)
                    console.log(JSON.stringify(err))    
                    setErrorText(err)                                                
                }    
            }
        }            
        asyncFetchMassnahmeForSelect();
    } , [mid]) // [massnahmeId])

    const [weitereEinrichtungen, setWeitereEinrichtungen] = useState(null)    
    const [monateBerechnet, setMonateBerechnet] = useState(null)
    const [monateInEinrichtung, setMonateInEinrichtung] = useState(null)
    const [monateGesamt, setMonateGesamt] = useState(null)

    async function asyncFetchMassnahmeStatForSelect()
    {         
        if (mid) {                
            try 
            {  
                const response = await fetch('/data/massnahmen/stat/' + mid, { method: 'GET' })
                if (response.ok) {
                    // console.log("response ok")
                    const result = await response.json(); 
                    // console.log(result);
                    setMonateBerechnet(result.monateBerechnet);
                    setMonateInEinrichtung(result.monateInEinrichtung);
                    setMonateGesamt(result.monateGesamt);
                    setWeitereEinrichtungen(result.anzEinrichtungen);
                    // kommt doppelt einmal aus der Massnahmetabelle oder hier berechnet aus Dauer/Vergütung
                    setBetragBerechnet(result.betragBerechnet); 
                }
                else
                {
                    throw new Error(response.status + " " + response.statusText)
                }           
            }
            catch (err) 
            {
                console.log(err)
                console.log(JSON.stringify(err))    
                setErrorText(err)       
            }
        }
    }

    useEffect(() => {               
        asyncFetchMassnahmeStatForSelect()
    }, [mid])

    const [vrlist, setvrlist] = useState(null)

    useEffect(() => {
        async function asyncLoadZuverechnendeMassnahmen()
        {
            // console.log("korr_mid_verrechnung " + korr_mid_verrechnung)
            if (korr_mid_verrechnung) {
               const response = await fetch('data/verrechnungen/zuverrechnen/' + korr_mid_verrechnung, { method: 'GET' })
               if (response.ok)
               {
                    const res = await response.json();                    
                    if (Array.isArray(res)) {
                        setvrlist(res)
                    }
               }
            }
        }        
        asyncLoadZuverechnendeMassnahmen();      
    }, [korr_mid_verrechnung]);

    let tabitemsActionFunc = null

    const tabitemsAction = (action) => 
    {   
        if (tabitemsActionFunc !== action)
        {     
            tabitemsActionFunc = action
            if (tabitemsActionFunc && tabpage)
            {
                // console.log("tabpage " + tabpage)
                const s = TABS.idToTabname(Number.parseInt(tabpage))            
                tabitemsActionFunc(s , 'edit')     
                tabpage = null                       
            }
        }
    }

    // wird von den tabpages gerufen
    // cmd : 'reload' wenn erfolgreich gespeichert
    const tabitemsCallback = (pagename, cmd) => {
        // console.log("Command from page " + pagename + " " + cmd)
        if (cmd === 'reload') {
            document.location.reload(false)
        }
    }

    const teilnehmerBearbeiten = () => {
        if (teilnehmerId) {
            // console.log("Bearbeite Teilnehmer mit Id " + teilnehmerId)            
            // window.location.href = "/Teilnehmer/Edit?tid=" + teilnehmerId
            window.open("/Teilnehmer/Edit?tid=" + teilnehmerId, WindowNames.TID)
        }
    }

    const einrichtungBearbeiten = () => {        
        if (einrichtungsId) {
            // console.log("Bearbeite Einrichtung mit Id " + einrichtungsId)
        //     window.location.href = "/Einrichtung/Edit?eid=" + einrichtungsId
        // }
        // else {
            window.open("/Einrichtung/Edit?eid=" + einrichtungsId, WindowNames.EID)
        }
    }
    
    const neuerAnsprechpartner = () => {
        if (einrichtungsId) {
            // console.log("Neuer Ansprechpartner für die Einrichtung " + einrichtungsId)
            if (massnahmeId) {
                window.location.href="/Ansprechpartner/New?eid=" + einrichtungsId
            } else {
                // window.location.href="/Ansprechpartner/New?eid=" + einrichtungsId
                window.open("/Ansprechpartner/New?eid=" + einrichtungsId, WindowNames.ASP)
            }
        }
    }

    const neuesKonto = () => {
        if (einrichtungsId) {
            console.log("Neues Konto für die Einrichtung " + einrichtungsId)        
            window.location.href="/Konto/New?eid=" + einrichtungsId        
        }
    }

    const _setEinrichtungsArt = (eart) => {        
        const id = Number.parseInt(eart)
        setEinrichtungsArt( Number.isNaN(id) ? null : id)
    }

    const _setAspId = (aspId) => {
        const id = Number.parseInt(aspId)
        setAspId(Number.isNaN(id) ? null : id)        
    }

    const _setFachId = (fachId) => {
        const id = Number.parseInt(fachId)
        setFachId(Number.isNaN(id) ? null : id)        
    }

    const _setKontoId = (kontoid) => {
        const id = Number.parseInt(kontoid)
        setKontoId(Number.isNaN(id) ? 0 : id)        
    }

    const _setBescheinigungAerzteKammer = (value) => {
        setBescheinigungAerzteKammer( value ? 1 : 0)
    }

    const _setfvon = ((value, from) => {
        // console.log("fvon set from " + from)
        if (value === null) {
            value = 'Invalid Date'
        }
        setfvon(value)
    })

    const _setfbis = ((value, from) => {
        // console.log("fbis set from " + from)
        if (value === null) {
            value = 'Invalid Date'
        }
        setfbis(value)
    })

    const _setProzentual = (txt) => {
        const value = Number.parseInt(txt)
        setProzentual(Number.isNaN(value) ? null : value) 
    }
 
    useEffect(() => {
        async function kontoOnChange()
        {
            // console.log("kontoID " + kontoid + " kontenSelectLoaded " + kontenSelectLoaded + " kontenSelectData.length " + kontenSelectData.length)
            const elems = document.getElementsByClassName("konto-select")
            for (let i = 0; i < elems.length; i++) {                
                if (kontoid && kontenSelectLoaded && kontenSelectData) {                                            
                    const data = kontenSelectData.find( (key, value) => { 
                         // console.log("key.key " + key.key)
                         // console.log("kontoid " + kontoid)
                        return (key.key === kontoid);
                    })
                    // console.log("data " + JSON.stringify(data))
                    if ((data) && data.iban_isok === 0)
                    {                    
                        elems[i].style.backgroundColor = ZConfig.KontoErrorBackground
                        continue;
                    }                
                }            
                elems[i].style.backgroundColor = ''
            }        
        }
        kontoOnChange()
    }, [kontoid, kontenSelectData, kontenSelectLoaded])    

    useEffect(() => {
        window.addEventListener('storage', notifyUpdate)    
        return () => {
            window.removeEventListener('storage', notifyUpdate)
        }
    }, [])    

    async function notifyUpdate(e)
    {    
        // console.log("notifyupdate " + e.key)        
        if (e.key === 'UPDATEBL') {            
            asyncFetchMassnahmeStatForSelect()             
        }
        else if (e.key === 'UPDATEASP') { //  && (einrichtungsId)) {   
            // console.log("UPDATEASP '" + einrichtungsId + "'")  
            set_reload_on_update(false)
            set_reload_on_update(true)            
            // asyncFetchaspDataForSelect(einrichtungsId)
        }
        else if ((e.key === 'UPDATEKTO') && (kontoid)) {            
            asyncFetchKontenDataForSelect(kontoid)
        }
        // console.log("end notifyupdate")
    }    

    const savedata = async () => 
    {
        let err = null;
        // console.log(" BETRAG " + betragBerechnet)
        if (!fvon || fvon === 'Invalid Date') {
            err = "Startdatum nicht eingegeben!"
        } else if (!fbis || fbis === 'Invalid Date') {
            err = "Enddatum nicht eingegeben!"
        } else if (fvon >= fbis) {
            err = "Startdatum ist größer als das Enddatum!"
        }
        else if (!prozentual || (prozentual > 100 || prozentual <30)) {
            err = "Es muss ein gültiger Prozentsatz angegeben werden! (30-100)"
        }
        else if (!aspId) {
            err = "Es muss ein Ansprechpartner zugeordnet werden!"
        }
        else if (!fachId) {
            err = "Es muss ein Fachgebiet ausgewählt werden!"
        }
        else if (!antragsEingang || antragsEingang === 'Invalid Date') {
            err = "Es muss das Datum des Antragseingangs eingegeben werden!"
        }
        if (err) {
            alert(err)
            return;
        }

        const data = 
        {
            id : massnahmeId,
            einrichtungen_id : einrichtungsId,
            teilnehmer_id : teilnehmerId,
            status : status,
            einrichtungsArt : einrichtungsArt,
            ik : ik,
            kontoid : kontoid,
            kontoid : kontoid,
            kontoid_dirty: isKontoIdDirty(),
            f_von : fvon,
            f_bis : fbis,
            prozentual : prozentual,
            fachId : fachId,
            // @todo redundanz tid=36991
            // select m.id, teilnehmer_id, m.vorname, m.nachname, t.vorname, t.nachname from massnahmen as m JOIN teilnehmer as t ON m.teilnehmer_id = t.id where m.vorname <> t.vorname or m.nachname <> t.nachname;
            // vorname : vorname,
            // nachname : nachname,
            asp_id : aspId,
            // @todo redundanz
            // asp_titel: asp_titel,
            // asp_vorname : asp_vorname,
            // asp_nachname : asp_nachname,
            // asp_anrede : asp_anrede,
            kurs_bem: kursHinweise,
            bemerkung: bemerkung,
            eingang_antrag: antragsEingang,
            antrag_gekuerzt: antragGekuerzt,
            // always readonly
            // ausgang_bestaet: bestaetigungsAusgang,

            bescheinigung: bescheinigungAerzteKammer, 

            // @todo wo werden die gesetzt? Verrechnung
            // sonderfall: sonderfall,
            // sonderfall_bem: sonderfall_bem,
            // Sonderfall_betrag: Sonderfall_betrag,
            // Sonderfall_mid_quelle: Sonderfall_mid_quelle,
            // mid_Vorgaenger: mid_Vorgaenger,

            // always readonly
            // nachweis_bearbeitet_am: nachweisBearbeitet,
            
            // @todo kürzungen eigene tabelle?
            // kuerzungsgrund: kuerzungsgrund,
            // A_von: A_von,
            // A_bis: A_bis,
            // A_Prozentual: A_Prozentual,    

            // Auszahlung (vorname, nachname werden hier benutzt, ebenfalls Ansprechpartner?)
            // Verwendungszweck: Verwendungszweck,
            // VerwendungszweckZahlung: VerwendungszweckZahlung,
            auszahlungen_id : auszahlungen_id,
            verwendungszweck : verwendungszweck,
            verwendungszweck_dirty: isVerwendungszweckDirty(),

            // nicht in der Oberfläche
            // wenn man den dialog wurde die Seite gedruckt? mit ja bestätigt 
            // gedruckt: gedruckt,
            // nachweis_gedruckt: nachweis_gedruckt,
            
            betragBerechnet: betragBerechnet,
            auszahlvermerk: auszahlVermerk,
            ausgezahlt: ausgezahlt,  
            
            // always readonly
            // ausgezahlt_am: ausgezahltAm,            
            
            // @todo nicht in der Oberfläche
            // BetragBestaetigt: BetragBestaetigt,
            // BetragAusgezahlt: BetragAusgezahlt,
            // speicherdatum: speicherdatum,
            // abrechnungswaehrung: abrechnungswaehrung,
            // lfd_Nr: lfd_Nr,
            
            // @todo unbekannt
            // REG_GES: REG_GES,

            // LetzterBearbeiter: // Datenschicht
            // LetzteAenderung:   // Datenschicht            
        }
        // console.log(JSON.stringify(data))
        const msg = await FetchService.saveMassnahme(data)
        if (!msg || !msg.startsWith("OK"))
        {
            alert("Fehler: " + msg)
            return;
        } 
        window.alert("Massnahme gespeichert!")
        if (massnahmeId) {
            // window.location = document.referrer;  
            // window.history.back(); 
            UpdateHandler.updateStorageMM(massnahmeId)
            // document.location.reload(false)            
            setMassnahmeId(massnahmeId)
        }
        else {
            const newId = ParseResult.parseIdFromText(msg);
            setMassnahmeId(newId);           
            H.replace('/Massnahme/Edit?mid=' + newId)    
            UpdateHandler.updateStorageMM(newId)      
        }
        updateisdirty()
        asyncFetchMassnahmeStatForSelect()
    }

    const canceldata = () => {
        // window.location = document.referrer;  
        window.history.back();    
    }
    
    const handleOnSelectTid = (item) => {  // the item selected
        // console.log(item)        
        setTeilnehmerId(item.id)
    }
    
    const handleOnClearTid = () => 
    { 
        setTeilnehmerId(null) // , "handleOnClearTid") 
        setEinrichtungsId(null) //, "handleOnClearTid")
    //  StorageService.setEinrichtungenId(null)
    //  StorageService.setTeilnehmerId(null)
    }
    // const handleOnFocus = () => { console.log('Focused')  }
    // const handleOnHover = (result) => { console.log(result) }

    const formatResult = (item) => { return item.name; }    

    const [acTeilnehmerList, setacTeilnehmerList] = useState( [{id:0,name:""}]);    
    useEffect(() => {
        async function loadTlist()
        {            
            if (!mid)
            {
                const res = await FetchService.loadTeilnehmerDatalist(null)       
                if (Array.isArray(res))
                {   
                    let lst = []
                    res.forEach(function(item) 
                    { 
                        const val = { id:item.id, name: formatTeilnehmer(item) } 
                        lst.push(val)
                    });                     
                    setacTeilnehmerList(lst)                   
                    if (TID > 0) {                        
                        const fi = lst.find( item => item.id === parseInt(TID))                    
                        setTeilnehmerId(TID) //, "from TID")
                        if (fi) {
                            setTeilnehmerData(fi.name)
                        }
                        TID = null;
                    }                
                }
            }
        }        
        loadTlist()
    }, [])

    const [cbEinrichtungenList, setcbEinrichtungenList] = useState( [{key:0,value:"", data:null}]);    
    useEffect(() => {
        async function loadElist()
        {            
            if (teilnehmerId > 0) {                
                const res = await FetchService.loadEinrichtungsDatalist(teilnehmerId)                  
                if (Array.isArray(res))
                {                       
                    let lst = []
                    res.forEach(function(item) 
                    {   
                        const val = { key:item.id, value: formatEinrichtung(item), data:item } 
                        lst.push(val)
                    });                      
                    setcbEinrichtungenList(lst)                      
                    if (EID > 0)
                    {                           
                        setEinrichtungsId(EID) //, "set from EID")
                        EID = null
                    }  
                    else 
                    {
                        // console.log("EID null")
                    }                          
                }
                else 
                {  
                    setcbEinrichtungenList([{key:0, value: res, data:null}])
                }
            } 
            else 
            {
                setcbEinrichtungenList([{key:0,value:"", data:null}])
            }
            
        }
        // console.log("Load Einrichtungs List: TeilnehmerID " + teilnehmerId + " einrichtungsID " + einrichtungsId)
        loadElist()
    }, [teilnehmerId])

    useEffect(() => {
        async function loadData()
        {   
            if (mid)
            {
                return
            }         
            if ( (einrichtungsId > 0) && (teilnehmerId > 0))
            {
                const edata = await FetchService.loadEinrichtungsData(einrichtungsId);                                          
                setIk(edata.ik)
                setEinrichtungsArt(edata.einrichtungsArt)
                setProzentual(100)
                setFachId(1)
                setStatus(Y.B1Beantragt)
                const currentDate = new Date()
                setAntragsEingang(currentDate)      
                _setfvon(null, "loaddata 1")
                _setfbis(null, "loaddata 1")                     
            }
            else
            {
                setEinrichtungsId(einrichtungsId) //, "loadData") 
                setAspId(null)
                setKontoId(null)        
                setIk(null)
                setProzentual(null)
                setStatus(Y.B1Beantragt)
                setFachId(null)
                setEinrichtungsArt(null)
                _setfbis(null, "loaddata 2")
                _setfvon(null, "loaddata 2")
                setAntragsEingang(null)
            }
        }            
        loadData()
    }, [einrichtungsId, teilnehmerId])

    const [verwendungszweck, setVerwendungszweck] = useState(null)    

    function isVerwendungszweckDirty() 
    { 
        return ((orgdata && orgdata.Auszahlungen) ? orgdata.Auszahlungen.verwendungszweck !== verwendungszweck : false) 
    }
    function isKontoIdDirty() { return (orgdata ? kontoid !== orgdata.kontoid : false) }

    function isdirty() {
        if (orgdata) {                
            // console.log(prozentual + "  " + typeof(prozentual))  
            // console.log(orgdata.prozentual + "  " + typeof(orgdata.prozentual))

            if (einrichtungsArt !== orgdata.einrichtungsArt) 
            {
                console.log("Dirty: einrichtungsart")
            } 
            else if (Date.parse(fvon) !== Date.parse(orgdata.f_von))
            {
                console.log("Dirty: von")
            }
            else if (Date.parse(fbis) !== Date.parse(orgdata.f_bis))
            {
                console.log("Dirty: bis")
            }
            else if (aspId !== orgdata.asp_id)
            {
                console.log("Dirty: asp_id")
            }
            else if (fachId !== orgdata.fachId)
            {
                console.log("Dirty: fachid")

            }
            else if (isKontoIdDirty())
            {
                console.log("Dirty: konto")

            }
            else if (bescheinigungAerzteKammer !== orgdata.bescheinigung)
            {
                console.log("Dirty: bescheinigung Ärztekammer")
            }
            else if (prozentual !== orgdata.prozentual)
            {
                console.log("Dirty: prozentual")
            }
            else
            {
                return false
            }
            return true

            // const result = 
            //     einrichtungsArt !== orgdata.einrichtungsArt ||
            //     Date.parse(fvon) !== Date.parse(orgdata.f_von) ||
            //     Date.parse(fbis) !== Date.parse(orgdata.f_bis) ||
            //     aspId !== orgdata.asp_id ||
            //     fachId !== orgdata.fachId ||
            //     isKontoIdDirty() ||
            //     bescheinigungAerzteKammer !== orgdata.bescheinigung ||
            //     prozentual !== orgdata.prozentual
            // return result;
        }        
        return false
    }

    function updateisdirty() {
        if (orgdata) {
            orgdata.einrichtungsArt = einrichtungsArt
            orgdata.f_von = fvon
            orgdata.f_bis = fbis
            orgdata.asp_id = aspId 
            orgdata.fachId = fachId 
            orgdata.kontoid = kontoid 
            orgdata.bescheinigung = bescheinigungAerzteKammer 
            orgdata.prozentual = prozentual 
        }
    }

    function checkdirty()
    {        
        if (isdirty()) {
            alert("isdirty")
        }
    }
    
    const briefClick = ( async (evt) => {        
        if (mid) {            
            const res = await FetchService.generateMassnahmeBrief(mid)            
            if (res.status === 200 && res.data) {
                console.log("showprintdialog = " + showprintdialog)
                const pd = po.createPrintData('Brief_MID_' + mid + '.docx', res.data.printid, mid)
                FileSaver.saveAs( res.data.document, pd.filename );
                console.log("showprintdialog = " + showprintdialog)                
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    })

    const emailClick = ( async (evt) => {
        if (mid) {            
            const res = await FetchService.generateMassnahmeMail(mid)
            if (res.status === 200 && res.data) {                                      
                window.location.href = res.data
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    })

    const teilnahmeBestaettigungClick = ( async (evt) => {
        if (mid) {            
            const res = await FetchService.generateTeilnahmebestaetigung(mid)            
            if (res.status === 200 && res.data) {
                const pd = po.createPrintData('Teilnahmebestaetigung_' + mid + '.docx', res.data.printid, mid)
                FileSaver.saveAs( res.data.document, pd.filename);                              
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
        else
        {
            alert("Keine Maßnahmen ID gefunden")
        }
    }) 
    
    const bestaetigungsSchreibenClick = ( async (evt) => {
        if (mid) {            
            const res = await FetchService.generateBestaetigungsschreiben(mid)
            if (res.status === 200 && res.data) {                      
                const pd = po.createPrintData('Bestaetigungsschreiben_' + mid + '.docx', res.data.printid, mid)
                FileSaver.saveAs( res.data.document, pd.filename);                                 
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
        else
        {
            alert("Keine Maßnahmen ID gefunden")
        }
    })

    function calcDisabled(flags)
    {
        // console.log("Flags " + flags)
        const mask = Math.pow(2, status-1)
        // console.log("Status Mask " + mask)  
        if ((flags & mask) !== 0) {
            return false
        }
        return true              
    }

    function calcRuecklauf()
    {
        if (auszahlVermerk) { 
            const azjs = auszahlVermerk.substring(0,4)
            const azjn = parseInt(azjs)
            if (azjn >= new Date().getFullYear()-1) { // Val(Left$(auszahlvermerk & "", 4)) >= Year(Now) - 1
                return false
            }
        }
        return true
    }


    const cmdStornoAufhebenClick = ( () => {
        setStatus(Y.B3Bestaetigt)           // Me!Status = 3
        setAuszahlVermerk("")               // Me!auszahlvermerk = ""
    })

    const cmdMassnahmeZurueckgezogenClick = (() => {
        setStatus(Y.B16MassnahmeStorniert)  // Status = 16
    })

    const cmdZahlungsRuecklaufClick = (() => {
        setStatus(Y.B19RechnungGestellt)
        if (auszahlVermerk) {
            setAuszahlVermerk(auszahlVermerk.substring(0,4)) // auszahlvermerk = Left$(auszahlvermerk, 4)
        }
        setAusgezahltAm(null)                       // ausgezahlt_am = ""
        const obm = (bemerkung ? bemerkung : "")
        // bemerkung = bemerkung & Chr$(13) & Chr$(10) & "Zahlungsrücklauf am " & Format$(Now(), "d.m.yyyy")
        setBemerkung(obm + "\r\n" + "Zahlungsrücklauf am " + toShortDateString(new Date())) 

    })

    const cmdNachweisAendernClick = ( async() => {
        if (status === Y.B19RechnungGestellt) {
            alert("Diese Maßnahme ist schon in der Rechnung an die Kassen enthalten und es kann nur die IBAN und der Verwendungszweck geändert werden!")            
            setStatus(Y.B24RechnungGestelltAenderung)                            
        } 
        else 
        {
            setStatus(Y.B10AenderungNachweis)
            const data = {
                id : mid,
                status : 10
            }
            const msg = await FetchService.saveMassnahme(data)
        }
    })

    const cmdNachweisAendernSpeichernClick = ( async() => {        
        let msg = null        
        if (!kontoid) {
            msg = "Das Konto ist ungültig"
        } else if (!verwendungszweck) {
            msg = "Der Verwendungszweck ist ungültig"
        }
        if (msg) {
            alert(msg)
            return;
        }
        if (isKontoIdDirty() || isVerwendungszweckDirty()) {
            console.log("Speichern Konto + Verwendung ")
            const data = {
                id : mid,                
                kontoid : kontoid,
                kontoid_dirty: isKontoIdDirty(),
                auszahlungen_id : auszahlungen_id,
                verwendungszweck : verwendungszweck,
                verwendungszweck_dirty: isVerwendungszweckDirty(),
            }
            const msg = await FetchService.saveMassnahme(data)
        }
        document.location.reload(false)        
    })

    const cmdNachweisBearbeitenClick = (() => {
        setStatus(Y.B7NachweisEingegangen)
        setNachweisBearbeitet(new Date())       // Me.nachweis_bearbeitet_am = Format$(Now, "dd.mm.yyyy")
    })

    const cmdMassnahmeAufteilenClick = (() => {
        // console.log("Massnahme aufteilen")
        if (tabitemsActionFunc) {
            tabitemsActionFunc('Massnahme teilen', 'edit')
        }
    })

    const cmdBescheinigungClick = (async () => {
        // console.log("Bescheinigung erfassen")       
        if (mid) 
        {   
              
            if (isdirty()) {
                alert("Bitte erst die Änderungen speichern!")
                return;
            }  

            const res = await BescheinigungErstellen(mid, fbis)

            // const zielNachweisDatum = new Date(fBis.getFullYear()+1, 5, 30, 23, 59, 59)
            // if (zielNachweisDatum < new Date())
            // {
            //     if (!window.confirm("Die Maßnahme hätte bis zum " + toShortDateString(zielNachweisDatum) + 
            //         " nachgewiesen werden müssen!\n" + "Wollen Sie den Nachweis trotzdem drucken?"))
            //     {
            //         return;
            //     }
            // }
            // const res = await FetchService.generateNachweisSchreiben(mid)

            if (res) {
                if (res.status === 200 && res.data) {
                    const pd = po.createPrintData('Nachweis' + mid + '.docx', res.data.printid, mid)
                    FileSaver.saveAs( res.data.document, pd.filename);                                 
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }
            }
        }
        else
        {
            alert("Keine Maßnahmen ID gefunden")
        }    
    })

    async function cmdBescheinigungForClick(evt)  { 
        const mid = Number.parseInt(evt.currentTarget.getAttribute('mid'))
        const mn = vrlist.find( (item) => { return item.id === mid})
        if (mn) {            
            const res = await BescheinigungErstellen(mid, new Date(Date.parse(mn.f_bis)))
            if (res) {
                if (res.status === 200 && res.data) {                         
                    const pd = po.createPrintData('Nachweis' + mid + '.docx', res.data.printid, mid)                    
                    FileSaver.saveAs( res.data.document, pd.filename);                                 
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }
            }            
        }        
    }

    // Verrechnung erfassen = KürzungNachZahlung
    const cmdVerrechnungClick = (() => {
        // console.log("Verrechnung erfassen")
        document.getElementById(modalid).style.display = 'block';                                    
        modalcallback('show', true);
    })

    const cmdKuerzungErfassenClick = (() => {
        console.log("Kürzung erfassen")
        if (tabitemsActionFunc) {
            tabitemsActionFunc('Kürzung', 'edit')
        }
    })

    const [tabitems, setTabitems] = useState(null)   
    
    let modalcallback = null;

    async function kuerzungnachzahlung_callback (cmd, param)  
    { 
        if (cmd === 'actionfunc') {
            modalcallback = param
        }
        else if (cmd === 'save') {
            if (param && param.startsWith('OK')) {
                console.log(param) // OK;38;MIDK=49351;MIDV=46002;
                // const res = await FetchService.generateNachweisSchreiben(xx)
                // if (res.status === 200 && res.data) {
                //     const pd = createPrintData('Nachweis' + mid + '.docx', res.data.printid, mid)
                //     FileSaver.saveAs( res.data.document, pd.filename);                                 
                // }
                await generateKorrekturSchreiben()
            } 
            else 
            {
                document.location.reload(false)
            }                        
        } 
        else 
        {
            console.log('callback from modal ' + cmd)
        } 
    }

    async function generateKorrekturSchreiben()
    {  
        console.log("generate korektur schreiben") 
        const res = await FetchService.generateKorrekturSchreiben(mid)                
        if (res.status === 200 && res.data) {
            const pd = po.createPrintData('Korrektur_MID_' + mid + '.docx', res.data.printid, mid)
            FileSaver.saveAs( res.data.document, pd.filename );                
        }
        else {
            alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
        }
    }

    // console.log("Nachweis bestätigt1 " + nachweisBestaetigt1)
    // console.log("Nachweis bestätigt2 " + nachweisBestaetigt2)
   
    return (
        <div>
            {/* <h1>Edit Ma&szlig;nahme</h1>                            
            <br></br> */}
            {/* <h3>{`${errorText}`}</h3>   */}
            <div className="sm_table_div">
                <table>
                    <thead>
                        <tr className="table-blank-row"></tr>         
                        <tr>
                            <th></th>                            
                            <th colSpan="2">
                                <h3>{ massnahmeId ? 'Maßnahme bearbeiten' : 'Maßnahme anlegen'}{canEdit ? '' : ' (Ansicht)'}</h3>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-blank-row"/>
                        <tr>
                            <td className="td_label">Teilnehmer:</td>                                                   
                            {canEditTID && 
                                <td className="td_select" colSpan="4">                                
                                    <div id="div_reactsearchautocomplete_tid" className="rsac_tid">
                                        <ReactSearchAutocomplete             
                                            className="rsac"                      
                                            items={acTeilnehmerList}                                          
                                        // fuseOptions={getFuseOptions(0.5, 3)}
                                        // onSearch={handleOnSearch}
                                        // onHover={handleOnHover}
                                            onSelect={handleOnSelectTid}
                                        // onFocus={handleOnFocus}
                                            onClear={handleOnClearTid}
                                            autoFocus
                                            formatResult={formatResult}
                                            inputSearchString={teilnehmerData}
                                            showIcon={false}
                                            placeholder="Suchtext eingeben"
                                            styling={getReactSearchAutocompleteStyle()}
                                        />
                                    </div>
                                </td>
                            }
                            {!canEditTID &&
                                <td className="td_input" colSpan="4">
                                    <FormGroup
                                        type="text"
                                        value={teilnehmerData}                                           
                                        disabled={true}                                  
                                    />       
                                </td>   
                            }                                
                            
                            <td>
                                <button 
                                    title="Teilnehmer bearbeiten" 
                                    onClick={teilnehmerBearbeiten}
                                    // hidden={!massnahmeId}
                                    disabled={!canEdit || !teilnehmerId} // || !massnahmeId}
                                >
                                    <img src="edit.png" width="17" height="15" alt="edit" />
                                </button>                                
                            </td>    
                            <td></td>                                                                        
                            <td></td>   
                            <td rowSpan={6}>
                                <table className='sm_table'>
                                    <tbody>
                                        <tr>
                                            <td className="td_label">Weitere Einrichtungen:</td>
                                            <td>{(weitereEinrichtungen > 1) ? weitereEinrichtungen-1 : "nein"}</td>
                                        </tr>
                                        <tr>
                                            <td className="td_label">Maßnahme ID:</td>
                                            <td>{massnahmeId}</td>
                                        </tr>                                        
                                        <tr>
                                            <td className="td_label">Betrag berechnet:</td>
                                            <td>{formatCurrency(betragBerechnet)}</td>
                                        </tr>
                                        <tr>
                                            <td className="td_label">Monate berechnet:</td>
                                            <td>{round(monateBerechnet, 2, '')}</td>
                                        </tr>
                                        <tr>
                                            <td className="td_label">Monate in Einrichtung:</td>
                                            <td>{round(monateInEinrichtung, 2, '')}</td>        
                                        </tr>
                                        <tr>
                                            <td className="td_label">Monate insgesamt:</td>
                                            <td>{round(monateGesamt, 2, '')}</td>
                                        </tr>
                                        {authenticationService.isAdmin && <tr>
                                            <td className="td_label">Betrag bestätigt:</td>
                                            <td>{formatCurrency(nachweisBestaetigt2)}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </td>                              
                        </tr>                        
                        <tr>                            
                            <td className="td_label">Einrichtung:</td>
                            {canEditEID && 
                                <td className="td_select" colSpan="4">
                                    <div>
                                        <FormGroup
                                            type="select"
                                            name="einrichtungenselect"
                                            options={cbEinrichtungenList}                                
                                            value={einrichtungsId}
                                            readonly= {!canEditEID || !teilnehmerId}   
                                            onChange={setEinrichtungsId}                                    
                                        />
                                    </div>
                                </td>
                            }                                                        
                            {!canEditEID && 
                                <td className="td_input" colSpan="4">
                                    <FormGroup
                                        type="text"
                                        value={einrichtungsData}
                                        disabled={!canEditEID}     
                                    /> 
                                </td>                
                            }                                                                                 
                            <td>
                            <button 
                                    title="Einrichtung bearbeiten" 
                                    onClick={einrichtungBearbeiten}
                                    // hidden={!massnahmeId}
                                    disabled={!canEdit || !einrichtungsId } // || !massnahmeId}
                                >
                                    <img src="edit.png" width="17" height="15" alt="edit"/>
                                </button>
                            </td>
                            <td className="td_label">IK:</td>
                            <td>{ik}</td>                              
                        </tr>                        
                        <tr>
                            <td className="td_label">Einrichtungsart:</td>
                            <td className="td_select" colSpan={2}>
                                <FormGroup
                                    type="select"
                                    name="einrichtungsartselect"
                                    options={einrichtungsArtSelectData}                                
                                    value={einrichtungsArt}
                                    readonly= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}   
                                    onChange={_setEinrichtungsArt}
                                />
                            </td>                           
                            <td className="td_label">Ansprechpartner:</td>
                            <td className="td_select" colSpan="1">
                                <FormGroup 
                                    type="select"
                                    name="aspselect"
                                    options={aspSelectData}
                                    value={aspId}
                                    readonly= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                    onChange={_setAspId}
                                />                                
                            </td>
                            <td>
                                <button 
                                    title="Neuen Ansprechpartner anlegen" 
                                    onClick={neuerAnsprechpartner}
                                    // hidden={!massnahmeId}
                                    disabled={!canEdit || !einrichtungsId} //</td> || !massnahmeId}
                                >
                                    <img src="neu.png" width="17" height="15" alt="neu" />
                                </button>
                            </td>                                                                                      
                            <td></td>
                            <td></td>                        
                        </tr>                        
                        {/* <tr className="table-blank-row"></tr> */}
                        <tr>
                        <td className="td_label">Zeitraum:</td>
                            <td>  
                                <FormGroup 
                                    type="html-date"
                                    contentClassName="react-datepicker-popper"
                                    className="form-control" //"td_datepicker"
                                    value={fvon}
                                    onChange={_setfvon}  
                                    min= {mindate}
                                    max= {maxdate}                                 
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}                                    
                                />                              
                                {/* <DatePicker
                                    popperClassName="react-datepicker-popper"
                                    className="form-control" //"td_datepicker"
                                    selected={fVon}
                                    onChange={setfVon} 
                                    dateFormat={"dd.MM.yyyy"}                                                                        
                                    // maxDate={Moment().add(-16,'years').toDate()}
                                    // minDate={Moment().add(-100, 'years').toDate()}
                                    // showFullMonthYearPicker={true}
                                    // showMonthYearPicker={true}
                                    // filterDate={ date => date.getDay() != 6 && date.getDay() != 0 }
                                    // placeholderText="Beginn auswählen"
                                    showYearDropdown={false}
                                    locale={de}     
                                    // isClearable={canEdit}       
                                    // clearButtonClassName="react-datepicker-clearbutton"
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                /> */}
                            </td>
                            <td>
                                <FormGroup
                                    type="html-date"
                                    contentClassName="react-datepicker-popper"
                                    className="form-control" //"td_datepicker"
                                    value={fbis}
                                    onChange={_setfbis}   
                                    min= {mindate}
                                    max= {maxdate}                                 
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                />
                            </td>                            
                            <td>
                                {/* TEST Datepicker mit Input */}
                                {/* <FormGroup 
                                    type="html-date"
                                    value={fVon}
                                    onChange={setfVon}                                
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                /> */}
                            </td>
                            <td>
                                {/* TEST Datepicker mit Input */}
                                {/* <FormGroup
                                    type="html-date"
                                    value={fBis}
                                    onChange={setfBis}                                    
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                /> */}
                            </td>
                            <td></td>         
                            <td></td>         
                            <td></td>                                                                 
                        </tr>
                        <tr>
                        <td className="td_label">Fachgebiet:</td>
                            <td className="td_select" colSpan={2}>
                                {/* {canEdit && !calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11) && */}
                                    <FormGroup
                                        type="select"
                                        name="fachgebieteselect"
                                        options={fachgebietSelectData}
                                        value={fachId}      
                                        readonly= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                        onChange={_setFachId}
                                    />                               
                            </td>
                            
                            <td className="td_label">Antrag gekürzt:</td>
                            <td>
                                <FormGroup 
                                    label=""
                                    name="antragGekuerzt"
                                    type="checkbox"
                                    value={antragGekuerzt}
                                    readonly= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                    onChange={setAntragGekuerzt}
                                />
                            </td>                                                        
                            <td></td><td></td><td></td>                                                
                        </tr>
                        <tr>
                        <td className="td_label">Status:</td>
                            <td>
                                <FormGroup
                                    type='text'
                                    value={getMassnahmeStatusString()}
                                    readonly={true}
                                    // disabled={true}
                                />
                            </td>
                            
                            <td className="td_label">Anteil:</td>
                            <td className="td_input" colSpan={2}>
                                <FormGroup 
                                    type="text"
                                    value={prozentual}
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                    onChange={_setProzentual}
                                />                                
                            </td>                            
                            <td>%</td>
                            <td></td><td></td>                                                                                               
                        </tr>                        
                        <tr>
                            <td className="td_label">Antragseingang:</td>
                            <td>
                                <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={antragsEingang}
                                    onChange={setAntragsEingang}
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}             
                                />
                            </td>                            
                            <td className="td_label">Bestätigungsausgang:</td>
                            <td colSpan={2}>
                                <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={bestaetigungsAusgang}
                                    onChange={setBestaetigungsAusgang}
                                    disabled= {true || !canEdit || !einrichtungsId}
                                />                                
                            </td>
                            <td></td>
                            <td></td>
                            {/* <td></td>         */}
                            <td rowSpan={7} colSpan={2} valign='top'>
                                <table>{/*  className='sm_table'> */}
                                    <tbody>
                                        <tr>
                                            <td className="td_button">
                                                <button className='exportbtn' onClick={cmdNachweisBearbeitenClick}
                                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B11)}
                                                >&nbsp;Nachweis bearbeiten
                                            </button>
                                        </td>
                                        <td rowSpan={5} colSpan={1}>
                                                { massnahmeId && <table className='sm_table'>
                                                    <tbody>
                                                    <tr>
                                                        {/* <td className='td_label_center'>
                                                            <DragDropUploadForm 
                                                                mid={massnahmeId} 
                                                                eid={einrichtungsId}
                                                            tid={teilnehmerId}
                                                            />
                                                        </td> */}
                                                    </tr>
                                                    </tbody>
                                            </table>}                       
                                       </td>
                                        </tr>
                                        <tr>
                                            <td className="td_button">
                                                <button className='exportbtn'
                                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                                    onClick={cmdMassnahmeAufteilenClick}
                                                >&nbsp;Ma&szlig;nahme aufteilen
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="td_button">
                                                <button className="exportbtn"
                                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                                    onClick={cmdKuerzungErfassenClick}
                                                >&nbsp;K&uuml;rzung erfassen
                                                </button>
                                            </td>                                            
                                        </tr>                                        
                                        <tr>
                                            <td className="td_button">
                                                <button className="exportbtn"
                                                    onClick={cmdBescheinigungClick}
                                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                                >
                                                    <ConfigIcon><AiOutlineFileWord/></ConfigIcon>&nbsp;&nbsp;Bescheinigung erstellen
                                                </button>
                                            </td>                                            
                                        </tr>
                                        <tr>
                                            <td className='td_button'>
                                                <button
                                                    className='exportbtn'
                                                    onClick={cmdNachweisAendernClick}
                                                    disabled={!canEdit || calcDisabled(X.B9 | X.B19)}
                                                >&nbsp;Nachweis &auml;ndern
                                                </button>
                                            </td>                                                                                                                    
                                        </tr>
                                        <tr>
                                            <td className='td_button'>
                                                <button
                                                    className='exportbtn'
                                                    disabled={!canEdit || calcDisabled(X.B12 | X.B18)}
                                                    onClick={cmdVerrechnungClick}
                                                >&nbsp;Verrechnung erfassen
                                                </button>
                                            </td>                                            
                                            <td className="td_button">                               
                                                <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>
                                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                                </button> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="td_button">
                                                <button className="exportbtn"
                                                    onClick={cmdZahlungsRuecklaufClick}
                                                    disabled={!canEdit || calcDisabled(X.B12) || calcRuecklauf() }
                                                >&nbsp;Zahlungsr&uuml;cklauf
                                                </button>
                                            </td>
                                            <td className="td_button">
                                                <button 
                                                    className="exportbtn" 
                                                    title="Speichern der Maßnahme"  
                                                    onClick={savedata}
                                                    disabled={!canEdit || !einrichtungsId || !teilnehmerId}  
                                                >
                                                    <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                                                </button>
                                            </td>         
                                        </tr>
                                    </tbody>
                                </table>                                
                            </td>                    
                        </tr>                        
                        <tr>
                        <td className="td_label">Nachweis bearbeitet:</td>
                            <td>
                                <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={nachweisBearbeitet}
                                    onChange={setNachweisBearbeitet}
                                    disabled= {true || !canEdit || !einrichtungsId}
                                />                               
                            </td>
                            <td className="td_label">Nachweis bestätigt:</td>
                            <td className="td_input">
                                <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={nachweisBestaetigt1}
                                    onChange={setNachweisBestaetigt1}
                                    disabled= {true || !canEdit || !einrichtungsId} 
                                />                                
                            </td>
                            <td className="td_input">
                                <FormGroup 
                                    type="text"
                                    value={formatCurrency(nachweisBestaetigt2)}
                                    onChange={setNachweisBestaetigt2}
                                    disabled={true || !canEdit || !einrichtungsId}                           
                                />                                
                            </td>
                            <td></td>
                            {/* <td></td>
                            <td></td>                                                                    */}
                        </tr>
                        <tr>
                            <td className="td_label">Bescheinigung Ärztekammer:</td>
                            <td>
                                <FormGroup 
                                    type="checkbox"
                                    value={bescheinigungAerzteKammer}
                                    onChange={_setBescheinigungAerzteKammer}
                                    readonly={!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}            
                                />                                
                            </td>
                            <td className="td_label">Konto:</td>
                            <td className="td_select" colSpan="2">
                                <FormGroup 
                                    contentClassName="konto-select"
                                    type="select"                                
                                    name="kontoselect"
                                    options={kontenSelectData}
                                    value={kontoid}
                                    onChange={_setKontoId}                                      
                                    readonly= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11 | X.B13 | X.B24)}
                                />                               
                            </td>
                            <td>
                                <button 
                                    title="Neues Konto anlegen" 
                                    onClick={neuesKonto}
                                    hidden={!massnahmeId}
                                    disabled={!canEdit || !einrichtungsId || !massnahmeId}     
                                >
                                    <img src="neu.png" width="17" height="15" alt="neu"/>
                                </button>
                            </td> 
                            {/* <td></td>
                            <td></td> */}
                        </tr>
                        <tr hidden={!canEdit || calcDisabled(X.B24)}>
                            <td  className='td_label'>Verwendungszweck:</td>
                            <td className="td_input" colSpan={2}>
                                <FormGroup
                                    type="text"
                                    value={verwendungszweck}
                                    disabled= {!canEdit || calcDisabled(X.B24)}
                                    onChange={setVerwendungszweck}                                  
                                />
                            </td>
                            <td >
                                <button 
                                    className="exportbtn" 
                                    onClick={cmdNachweisAendernSpeichernClick}
                                >&nbsp;Speichern
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="td_label">Ausgezahlt am:</td>
                            <td className='td_input'>
                                <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={ausgezahltAm}
                                    onChange={setAusgezahltAm}
                                    disabled= {true || !canEdit || !einrichtungsId } 
                                />                                                                
                            </td>
                            
                            <td className="td_label">Ausgezahlt:</td>
                            <td>
                                {formatCurrency(ausgezahlt, 2, '€')}
                            </td>
                            <td className="td_label">Auszahlvermerk:</td>
                            <td colSpan={2}>{auszahlVermerk}</td>                                                                            
                        </tr>
                        <tr>
                            <td className="td_label">Bemerkung:</td>
                            <td  className="td_input" colSpan={5}>
                                <FormGroup
                                    type="textarea"
                                    value={bemerkung}
                                    onChange={setBemerkung}
                                    rows={2}                                 
                                >
                                </FormGroup>                                
                            </td>
                            {/* <td></td><td></td> */}
                        </tr>
                        <tr>
                            <td className="td_label">Kurs-Hinweise:</td>
                            <td className="td_input" colSpan={5}>
                                <FormGroup
                                    type="text"
                                    value={kursHinweise}
                                    onChange={setKurshinweise}
                                    disabled= {!canEdit || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11)}
                                >
                                </FormGroup>                                
                            </td>
                            {/* <td></td>                             */}
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            {/* <td>SHOW PRINT {showprintdialog.toString()}</td> */}
                            <td></td>
                            <td colSpan="6">
                            <LastEdit edituser={editUser} edittime={editTime}></LastEdit>
                            </td>
                            {/* <td></td> */}
                        </tr>
                        <tr className="table-blank-row"></tr>  
                        <tr>
                            <td colSpan={5}>
                                <div className='sm_table_div'>
                                    <table className="sm_table">
                                        <tbody>
                                            <tr>
                                            <td className="td_button">
                                <button className="exportbtn"onClick={emailClick} disabled={!canEdit || !mid || !einrichtungsId || !aspId}>
                                    <ConfigIcon><AiOutlineMail/></ConfigIcon>&nbsp;E-Mail                            
                                </button>
                            </td>
                            <td className="td_button">
                                <button className="exportbtn" onClick={briefClick} disabled={!canEdit || !mid || !einrichtungsId || !aspId}>
                                    <ConfigIcon><AiOutlineFileWord/></ConfigIcon>&nbsp;Brief                            
                                </button>
                            </td>                            
                            
                            <td className="td_button">
                                <button className="exportbtn" onClick={teilnahmeBestaettigungClick}  disabled={!canEdit || canEditTID}>
                                    <ConfigIcon>
                                        <AiOutlineFileWord/>
                                    </ConfigIcon>&nbsp;Teilnahmebest&auml;tigung
                                </button>                    
                            </td>
                            <td className="td_button">
                                <button className="exportbtn" onClick={bestaetigungsSchreibenClick}  disabled={!canEdit || canEditTID}>
                                    <ConfigIcon>
                                        <AiOutlineFileWord/>
                                    </ConfigIcon>&nbsp;Best&auml;tigungsschreiben
                                </button>
                            </td>
                            <td className="td_button">                               
                                <button className="exportbtn"
                                    onClick={cmdMassnahmeZurueckgezogenClick} 
                                    disabled={!canEdit || canEditTID || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B6 | X.B7 | X.B10)}
                                >Ma&szlig;nahme zur&uuml;ckgezogen
                                </button>
                            </td>  
                            <td className="td_button">                                
                                <button className="exportbtn"
                                    onClick={cmdStornoAufhebenClick} 
                                    disabled={!canEdit || calcDisabled(X.B6)}
                                >Storno aufheben
                                </button> 
                            </td>        

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='sm_table_div'>
                <table className="sm_table">
                    <tbody>
                        <tr>
                            <td rowSpan={5} valign='top'>
                            { vrlist && vrlist.length > 0 &&
                                <table className='sm_table'>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>Zu verrechnende Massnahmen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { vrlist.map ( 
                                            (item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <button className="exportbtn warnbtn"
                                                                mid={item.id}
                                                                onClick={cmdBescheinigungForClick}
                                                                disabled={!canEdit} // {!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                                            >
                                                            <ConfigIcon><AiOutlineFileWord/></ConfigIcon>&nbsp;&nbsp;Bescheinigung erstellen
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }                    
                                    </tbody>
                                </table>            
                            }   
                            </td>
                            <td rowSpan={5} width='20'></td>     


                            {/* <td className="td_button">
                                <button 
                                    className="exportbtn" 
                                    title="Speichern der Maßnahme"  
                                    onClick={savedata}
                                    disabled={!canEdit || !einrichtungsId || !teilnehmerId}  
                                >
                                    <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                                </button>
                            </td>                         */}
                            {/* <td className="td_button">                               
                                <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>
                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                </button> 
                            </td> 
                            <td></td>*/}
                            
                        </tr>

                        {/* <tr className="table-blank-row"></tr> */}
                        {/*<tr>
                             <td className="td_button">
                                <button className='exportbtn'
                                    onClick={cmdNachweisBearbeitenClick}
                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B11)}
                                >&nbsp;Nachweis bearbeiten
                                </button>
                            </td> 
                            <td className="td_button">
                                <button className='exportbtn'
                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                    onClick={cmdMassnahmeAufteilenClick}
                                >&nbsp;Ma&szlig;nahme aufteilen
                                </button>
                            </td>
                            <td className="td_button">
                                <button className="exportbtn"
                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                    onClick={cmdKuerzungErfassenClick}
                                >&nbsp;K&uuml;rzung erfassen
                                </button>
                            </td>*/}
                            {/* <td className="td_button">
                                <button className="exportbtn"onClick={emailClick} disabled={!canEdit || !mid || !einrichtungsId || !aspId}>
                                    <ConfigIcon><AiOutlineMail/></ConfigIcon>&nbsp;E-Mail                            
                                </button>
                            </td> */}
                            {/* <td className="td_button">
                                <button className="exportbtn"
                                    onClick={cmdBescheinigungClick}
                                    disabled={!canEdit || calcDisabled(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                                >
                                    <ConfigIcon><AiOutlineFileWord/></ConfigIcon>&nbsp;&nbsp;Bescheinigung erstellen
                                </button>
                            </td> 
                        </tr>*/}
                        {/* <tr>
                            <td className='td_button'>
                                <button
                                    className='exportbtn'
                                    onClick={cmdNachweisAendernClick}
                                    disabled={!canEdit || calcDisabled(X.B9 | X.B19)}
                                >&nbsp;Nachweis &auml;ndern
                                </button>
                            </td>
                            <td className='td_button'>
                                <button
                                    className='exportbtn'
                                    disabled={!canEdit || calcDisabled(X.B12 | X.B18)}
                                    onClick={cmdVerrechnungClick}
                                >&nbsp;Verrechnung erfassen
                                </button>
                            </td> 

                        </tr>*/}
                         {/*<tr>                           
                            <td className="td_button">                               
                                <button className="exportbtn"
                                    onClick={cmdMassnahmeZurueckgezogenClick} 
                                    disabled={!canEdit || canEditTID || calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B6 | X.B7 | X.B10)}
                                >Ma&szlig;nahme zur&uuml;ckgezogen
                                </button>
                            </td>                             */}
                            {/* <td className="td_button">
                                <button className="exportbtn"
                                    onClick={cmdZahlungsRuecklaufClick}
                                    disabled={!canEdit || calcDisabled(X.B12) || calcRuecklauf() }
                                >&nbsp;Zahlungsr&uuml;cklauf
                                </button>
                            </td> */}
                            {/* <td className="td_button">                                
                                <button className="exportbtn"
                                    onClick={cmdStornoAufhebenClick} 
                                    disabled={!canEdit || calcDisabled(X.B6)}
                                >Storno aufheben
                                </button> 
                            </td> 
                            <td>
                               {/* <button onClick={generateKorrekturSchreiben}>
                                    NON MODAL  
                                </button> 
                                Test&nbsp;<span style={{color:'red'}}>&#9650;&#9660;</span> 
                            </td>                              
                        </tr> */}
                    </tbody>
                </table>
            </div>
            <div className="sm_table_div">
                <table className="sm_table">
                    <tbody>
                        <tr className="table-blank-row"></tr>
                        {tabitems && massnahmeId && (<tr>
                            <td colSpan="8"><HorizontalTabs
                                tabitems={tabitems} 
                                setAction={tabitemsAction} 
                                callback={tabitemsCallback}
                                getDisabled={calcDisabled} /></td>
                        </tr>)
                        }
                    </tbody>
                </table>                
            </div>  
            <Modal id={modalid} mid={mid} title={'Kürzung Maßnahme ' + mid} callback={kuerzungnachzahlung_callback} component={KuerzungNachZahlungDialog} />
            <NonModal id={nonmodalid} type='yesno' title='Druckbestätigung' message={po.printmessage} callback={documentprint_callback} data={po.printid}></NonModal>            
        </div>
    );    
}

export default EditMassnahme
