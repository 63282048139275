import React, {useEffect, useState } from 'react'

import MassnahmenListItems from '../../context/MassnahmenListItems';
import { ExportService } from '../../services';
import {toShortDateString} from '../../util/date-utils';

const MassnahmenListe = (props) => {

    const [errorText, setErrorText] = useState(null)
    const [listId, setListId] = useState(null)
    const [url, setUrl] = useState(null)
    const [title, setTitle] = useState(null)
    const [lstData, setLstData] = useState(null)

    const [fcheck, setFcheck] = useState(null)
    const [fmid, setFmid] = useState(null)
    const [feid, setFeid] = useState(null)
    const [fkid, setFkid] = useState(null)
    const [faid, setFaid] = useState(null)
    const [fende, setFende] = useState(null)

    useEffect(() => {
        let id = new URL(window.location.href).searchParams.get("id");  
        let data = MassnahmenListItems.findItem(id)

        if (id && data) {
            setListId(id)
            setTitle(data.title)            
            setUrl(data.url)
            setFcheck(data.check)
            setFmid(data.mid)
            setFeid(data.eid)
            setFkid(data.kid)
            setFaid(data.aid)
            setFende(data.ende)
        }        
    }, [])

    useEffect(() => {
        async function loadTableData()
        {
            if (!url) {
                return;
            }
            try {  
                // console.log(url)                              
                const response = await fetch(url, { method: 'GET' })
                if (response.ok) {                                               
                    const result = await response.json();
                    if (Array.isArray(result))
                    {                        
                        if (result.length > 0)
                        {                            
                            // console.log(result[0])
                            const lst = []
                            setErrorText(null)
                            result.forEach(element => {
                                const data =  { 
                                    check : false, 
                                    mid : element.id, 
                                    eid : element.einrichtungen_id, 
                                    eName : element.Kurzbezeichnung, 
                                    kid : element.kontoID,
                                    aid : element.Asp_id,
                                    mEnde : element.Ende,
                                    index : lst.length,
                                } 
                                if (!data.mid) {
                                    data.mid = element.mid
                                }
                                lst.push(data)
                            });
                            // console.log(lst)
                            setLstData(lst)
                        }
                        else
                        {
                            setErrorText("keine Daten gefunden")
                        }
                    }
                    else
                    {
                        setErrorText(result)
                    }
                    return;
                }
                else 
                {
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                console.log(err)                    
                setErrorText(err.toString())                            
            }
        }        
        loadTableData()
    }, [url])

    const checkSingle = (evt) => {
        const indexattribute = evt.target.attributes['index']
        if (indexattribute) {
            const idx = Number.parseInt(indexattribute.value)                    
            lstData[idx].check = evt.target.checked          
        }        
    }

    const checkAll = (evt) => {
        const checked = evt.target.checked        
        const boxes = document.getElementsByClassName("mcheck")        
        for (let i=0; i<boxes.length; i++) {
            if (boxes.item(i).checked !== checked) {                
                boxes.item(i).click() 
            }            
        }
    }

    const listeAbbrechen =  () =>
    {
        window.history.back();        
    }

    const listeStornoUndo = async () => {
        console.log("listeStornoUndo")
        doStorno("Keine Maßnahme ausgewählt!", '/data/zahlungslaeufe/stornoundo')
    }

    const listeStorno = async () =>
    {
        console.log("listeStorno")
        doStorno("Keine Maßnahmen zum Stornieren ausgewählt!", '/data/zahlungslaeufe/storno')
    }

    const excelExport = async () => {
        console.log("Excel Export")
        let fileName = ""
        switch (listId) 
        {
            case MassnahmenListItems.IbanKey:
                fileName = "ExportFehlerhafteIBAN"
                ExportService.exportIbanError(fileName)
                break;
            case MassnahmenListItems.NotPrintedKey:
                fileName = "ExportUngedruckteNachweise"
                ExportService.exportNotPrintedError(fileName)
                break;
            case MassnahmenListItems.AspKey:
                fileName = "ExportFehlerhafteASP"
                ExportService.exportAspError(fileName)
                break;
            default:
                alert("Not implemented Export (" + listId + ")" )
                return;
        }       
    }

    const doStorno = async (msg, url) => 
    {    
        const mids = []
        lstData.forEach( (element) => {           
            if (element.check === true) {                
                mids.push(element.mid)
            }            
        })

        if (mids.length === 0) {
            alert(msg) // alert("Keine Maßnahmen zum Stornieren ausgewählt!")
        } 
        else {
            // console.log("Storniere...")
            // console.log(mids)
            let msg = null
            try
            {
                // const response = await fetch('/data/zahlungslaeufe/storno',
                const response = await fetch(url,
                {
                    method: 'POST',
                    headers: {                    
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(mids)
                })
                if (!response.ok) {
                    msg = response.status + " " + response.statusText
                }
                else 
                {
                    window.location = document.referrer;  // forces reload der zielseite
                    // window.history.back();             // liest Zielseite aus dem cache    
                }
            }
            catch (err) {
                msg = err
            }
            if (msg) {
                alert(msg)
            }        
        }
    }

    return (
        <div>            
            {/* <h3>MassnahmenListe {listId}</h3><br/>
            <h3>Title {title}</h3>
            <h3>Url {url}</h3> */}            
            <div className="sm_table_div">  
                <table>
                    <thead>
                        <tr className="table-blank-row"></tr>
                        <tr><th colSpan="3"><h3>{title}</h3></th></tr>
                        <tr className="table-blank-row"></tr>
                        <tr>
                            { !errorText && listId === MassnahmenListItems.StornoKey  &&                             
                                <td><button className="exportbtn" onClick={listeStorno}>Stornieren</button></td>                                                            
                            }
                            { !errorText && listId === MassnahmenListItems.StornoKeyUndo  &&                             
                                <td><button className="exportbtn" onClick={listeStornoUndo}>Stornierung aufheben</button></td>                                                            
                            }
                            { !errorText && listId === MassnahmenListItems.IbanKey &&
                                <td><button className="exportbtn" onClick={excelExport}>Excel Export</button></td>
                            }
                            <td><button className="exportbtn" onClick={listeAbbrechen}>Zur&uuml;ck</button></td>
                        </tr>
                        <tr className="table-blank-row"></tr>
                    </thead>
                </table>
            </div>
            <div className="sm_table_div">  
                <table>
                    <thead>                        
                        { errorText && <tr><td><h3>Fehler: {errorText}</h3></td></tr> }
                        { !errorText && <tr>
                                {fcheck && <th><input type="checkbox" value="0" onClick={checkAll}></input></th>}
                                {fmid && <th className="td_label_left">Maßnahme</th>}                                
                                {feid && <th>Einrichtung</th>}
                                {fkid && <th>Konto</th>}
                                {faid && <th>Ansprechpartner</th>}
                                {fende && <th className="td_label_left">Ende</th>}
                            </tr>
                        }
                    </thead>
                    <tbody>
                        <tr className="table-blank-row"></tr>
                        {                            
                            lstData && lstData.map( ({ check, index, mid, eid, eName, kid, aid, mEnde }) => 
                            {
                                return (
                                    <tr className="liste" key={mid}>
                                        {fcheck && <td className="td_label_center">
                                            <input className="mcheck" type="checkbox" value={check} onClick={checkSingle} index={index}></input>                                        
                                            </td>
                                        }
                                        {fmid && <td><a href={`/Massnahme/Edit?mid=${mid}`}>{mid}</a></td>}
                                        {feid && <td>(<a href={`/Einrichtung/Edit?eid=${eid}`}>{eid}</a>) {eName}</td>}
                                        {fkid && <td><a href={`/Konto/Edit?eid=${eid}&kontoid=${kid}`}>{kid}</a></td>}
                                        {faid && <td><a href={`/Ansprechpartner/Edit?eid=${eid}&aspId=${aid}`}>{aid}</a></td>}
                                        {fende && <td>{toShortDateString(mEnde)}</td>}
                                    </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>            
        </div>
    )
}

export default MassnahmenListe
