import React from 'react'
import PropTypes from 'prop-types'
import './dropdownmenuhoverable.css'

// <b>&dtrif;</b>
// &dtrif; ist laut HTML Charactertable ein Pfeil nach unten... klappt aber nicht
// &#8711; oder &#9662;

const DropdownMenuHoverable = props => {
    return (
        <div className="hdropdown">
            {
                props.onClick && 
                    <button className={"hdropbtn"+(props.className ?? '')} onClick={props.onClick}>
                        {props.menuname}
                    </button>
            }
            {
                props.menuitems && 
                <>
                    <button className="hdropbtn">{props.menuname} &#9662;</button>
                    <div id={props.menuname} className="hdropdown-content">
                        {
                            props.menuitems.map( ({ title, link, target}) => 
                            {    
                                var hash = props.menuname + "_" + title // +  Strings.hashCodePositive(title).toString()                                
                                return (
                                    <a 
                                        key={hash} 
                                        href={link} 
                                        target={(target ? target : '')}                                        
                                    >{title}</a>
                                )
                            })
                        }             
                    </div>
                </>
            }            
        </div>
    )
}

DropdownMenuHoverable.propTypes = {
    menuname : PropTypes.string,
    menuitems : PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string
        })        
    )
}

export default DropdownMenuHoverable
