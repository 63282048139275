
    import { toShortDateString } from './date-utils'
    
    export function formatTeilnehmer(data, filter) 
    {
        let F = (filter) ? "" + filter : "invgd";        
        let result  = ""
        if (data)
        {
            if ((data.id) && F.indexOf("i") >= 0 ) {
                result += "(" + data.id + ")"
            }
            if ((data.nachname) && F.indexOf("n") >= 0 ) {
                result += " " + data.nachname 
            }
            if ((data.vorname) && F.indexOf("v") >= 0 ) {
                result += ", " + data.vorname
            }
            if ((data.geburtsName) && F.indexOf("g") >= 0 ) {
                result += ", geb. " + data.geburtsName
            }
            if ((data.geburtsDatum) && F.indexOf("d") >= 0 ) {
                result += " (" + toShortDateString(data.geburtsDatum) + ")"
            }
        }
        return result
    }

    export function formatTeilnehmerDetail(id, nachname, vorname, geburtsName, geburtsDatum, filter)
    {
        return formatTeilnehmer( { id:id, nachname:nachname, vorname:vorname, geburtsName:geburtsName, geburtsDatum:geburtsDatum }, filter )       
    }

    export function  formatEinrichtung(data, filter) {
        let result = "" 
        if (data) {
            let F = (filter) ? "" + filter : "ino";        
            if (F.indexOf('i') >= 0) {
                result += "(" + data.id + ") "
            }
            if ((data.kurzbezeichnung) && (F.indexOf('n') >= 0)) {
                result += data.kurzbezeichnung
            }
            if ((data.postleitzahl) && (F.indexOf('p') >= 0)) {
                result += ", " + data.postleitzahl
                if ((data.ort) && (F.indexOf('o') >= 0)) {
                    result += " " + data.ort
                }
            }
            else if ((data.ort) && (F.indexOf('o') >= 0)) {
                result += ", " + data.ort
            }
            
            if ((data.ik) && (F.indexOf('k') >= 0 )) {
                result += ", " + data.ik
            }
        }
        return result;        
    }

    export function formatAnsprechpartner(data, filter) {
        let result = "" 
        let F = filter ? filter : "atvn"

        // Ansprechpartner kann auch null sein wenn keiner zugeordnet ist
        if (data) {
            if ((data.Anreden) && (data.Anreden.bezeichnung) && (F.indexOf('a') >= 0)) {
                result += data.Anreden.bezeichnung + " "
            }
            if (data.titel && (F.indexOf('t') >= 0)) {
                result += data.titel + " "
            }
            if (data.vorname && (F.indexOf('v') >= 0)) {
                result += data.vorname + " "
            }
            if (F.indexOf('n') >= 0) {
                if (data.nachname) {
                    result += data.nachname + " "
                } else if (data.value) {
                    result += data.value + " "
                }
            } 
        }
        return result
    }

    export function formatPlz(plz)
    {
        let value = ""
        if (plz) {
            value += plz;
            while (value.length < 5) {
                value = "0" + value;
            }
        }
        return value
    }

    export function formatIBAN (iban)  
    {
        if ((iban) && (iban.length === 22)) 
        {
            const result = iban.slice(0,4) + " " + iban.slice(4,8) + " " + iban.slice(8,12) +
                " " + iban.slice(12,16) + " " + iban.slice(16,20) + " " + iban.slice(20,22)
            return result;
        }
        return iban;
    }