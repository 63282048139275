import React, { useState, useEffect } from 'react'
import FileSaver from 'file-saver';
import { AiOutlineFileExcel, AiOutlineFileText } from 'react-icons/ai';

import ConfigIcon from '../../util/configicon';
import Spinner from '../../components/shared/spinner/Spinner';
import { toShortDateString } from '../../util/date-utils';
import FetchService from '../../services/FetchService'

const ZahlungenOverview = () => {

    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasLauf3, setHasLauf3] = useState(false);

    useEffect(() => {

        async function asyncLoadZahlungslaeufe()
        {  
            try {                
                const response = await fetch('/data/zahlungslaeufe/')
                if (response.ok) {                    
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {      
                        // console.log(result[0])                  
                        setData(result)
                        setErrorMessage(null)
                        if (result.find( elem => { return elem.zahlungslauf3 !== null })) {
                            setHasLauf3(true)
                        }
                        return;
                    }                   
                    throw new Error(result.name)                                    
                }
                else
                {                     
                   throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {                
                 console.log(err)
                 setErrorMessage(err)
            }              
        }
        asyncLoadZahlungslaeufe()
    }, [])

    const excelExport = async () => {        
        if (data) {        
            const res = await FetchService.generateZahlungslaeufeExcel()            
            if (res.status === 200 && res.data) {
                // console.log(res.data)
                FileSaver.saveAs( res.data, 'Zahlungslaeufe' + toShortDateString(Date.now()) + '.xlsx');             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    const csvExport = async () => {        
        if (data) {        
            const res = await FetchService.generateZahlungslaeufeCsv()            
            if (res.status === 200 && res.data) {
                // console.log(res.data)
                FileSaver.saveAs( res.data, 'Zahlungslaeufe' + toShortDateString(Date.now()) + '.csv');             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }    

    return (
        <div>
        { data && !errorMessage &&
            <div className="sm_table_div">            
                <table className="sm_table_90">
                    <thead>
                        <tr><th></th><th></th><th></th><th></th>
                        <th colSpan="3">Zahlungslauf 1</th>
                        <th colSpan="3">Zahlungslauf 2</th>
                        { hasLauf3 && (<th colSpan="3">Zahlungslauf 3</th>) }
                        </tr>
                        <tr>
                            <th>Jahr</th><th>Rechnung</th><th>Anzahl</th><th>Summe</th>
                            <th>Datum</th><th>Anzahl</th><th>Summe</th>
                            <th>Datum</th><th>Anzahl</th><th>Summe</th>
                            { hasLauf3 && (<><th>Datum</th><th>Anzahl</th><th>Summe</th></>) }
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-blank-row"><td></td></tr>
                        {
                            data.map( ({ zahlungslauf, rechnungsDatum, anzahlMassnahmen, gesamtSumme,
                                zahlungslauf1, anzahlLauf1, summeLauf1,
                                zahlungslauf2, anzahlLauf2, summeLauf2,
                                zahlungslauf3, anzahlLauf3, summeLauf3 })  => 
                            {                                
                                return <tr className="liste" key={zahlungslauf}>
                                    <td className="td_label">{zahlungslauf ?? ''}</td>
                                    <td className="td_label">{rechnungsDatum ? toShortDateString(rechnungsDatum) : '' }</td>
                                    <td className="td_label">{anzahlMassnahmen}</td>
                                    <td className="td_label" >{gesamtSumme ? gesamtSumme.toLocaleString( "de-DE", 
                                    {style:'currency', currency : 'EUR'}) : ''}</td>
                                    <td className="td_label">{zahlungslauf1 ? toShortDateString(zahlungslauf1) : ''}</td>
                                    <td className="td_label">{anzahlLauf1}</td>
                                    <td align="right">{summeLauf1 ? summeLauf1.toLocaleString( "de-DE",
                                    {style:'currency', currency : 'EUR'}) : ''}</td>
                                    <td className="td_label">{zahlungslauf2  ? toShortDateString(zahlungslauf2) : ''}</td>
                                    <td className="td_label">{anzahlLauf2}</td>
                                    <td className="td_label">{summeLauf2 ? summeLauf2.toLocaleString( "de-DE", 
                                    {style:'currency', currency : 'EUR'}) : ''}</td>
                                    { hasLauf3 && 
                                        (<>
                                            <td className="td_label">{zahlungslauf3 ? toShortDateString(zahlungslauf3) : ''}</td>
                                            <td className="td_label">{anzahlLauf3}</td>
                                            <td className="td_label">{summeLauf3 ? summeLauf3.toLocaleString("de-DE", 
                                            {style:'currency', currency : 'EUR'}) : ''}</td>
                                        </>)
                                    }
                                </tr>
                            })
                        }
                        <tr className="table-blank-row"><td></td></tr>
                        <tr>
                            <td></td>
                            <td colSpan="6">
                                <table>
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td className="td_button">
                                                <button className="exportbtn" onClick={excelExport}>
                                                    <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>
                                                    &nbsp;Excel Export</button>
                                            </td>
                                            <td className="td_button">
                                                <button className="exportbtn" onClick={csvExport}>
                                                    <ConfigIcon><AiOutlineFileText/></ConfigIcon>
                                                    &nbsp;CSV Export</button>
                                            </td>
                                            <td >                                             
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>        
            </div>
        }
        { !data && !errorMessage && <Spinner /> }
        { errorMessage && <h3>{errorMessage.toString()}</h3>}
        </div>
    )
}

export default ZahlungenOverview
