import React, { useState, useEffect } from 'react'
import { AiOutlineSave, AiOutlineCalculator } from 'react-icons/ai';
import FileSaver from 'file-saver';

import { toShortDateString } from '../../util/date-utils'
import FormGroup from '../../components/shared/form-group'
import ConfigIcon from '../../util/configicon';
import { FetchService, authenticationService } from '../../services';
import { X } from './X';
import Dates from '../../util/Dates';
import { round } from '../../util/number-utils'

import { PO } from './PO';
import NonModal from '../../components/shared/nonmodal/NonModal';

const po = new PO("Kuerzung")

const Kuerzung = (props) => {

    const [showprintdialog, setshowprintdialog] = useState(false)
    const nonmodalid = 'NonModal_KBescheinigung';
    po.initComponent(this, setshowprintdialog)

    function showPrintDialog()
    {  
        // console.log("showprintdialog " + po.printmessage)      
        if (po && po.printid && po.printmessage) 
        {
            const element = document.getElementById(nonmodalid)
            if (element) {
                element.style.display = 'block';                                    
            }        
        }
    }

    function documentprint_callback(cmd, param) { po.documentprint_callback(cmd, param)}

    useEffect(() => {
        async function func()
        { 
            if (showprintdialog) { showPrintDialog() }
        }
        // console.log("useeffect showprintdialog " + showprintdialog)    
        func()
    }, [showprintdialog]); 

    const [canEdit, setCanEdit] = useState(authenticationService.currentUser.category > 0)
    const [canSave, setCanSave] = useState(false)

    const [mid, setMid] = useState(null)
    const [kid, setKid] = useState(null)
    
    const [kvonString, setKvonString] = useState(null)
    const [kbisString, setKbisString] = useState(null)
    const [kprozentual, setKprozentual] = useState(0)
    const [fachId, setFachId] = useState(0)
    const [kuerzungsgrundId, setKuerzungsgrundId] = useState(null)
    const [kuerzungsgrundText, setKuerzungsgrundText] = useState(null)
    const [status, setStatus] = useState(null)

    const [mvon, setMvon] = useState(null)
    const [mbis, setMbis] = useState(null)
    const [kBis, setKbis] = useState(null)
    const [kVon, setKvon] = useState(null)
    

    const [monateBerechnet, setMonateBerechnet] = useState(0.0)
    const [monateGesamt, setMonateGesamt] = useState(0.0)
    const [monateErlaubt, setMonateErlaubt] = useState(null)
    const [errorText, setErrorText] = useState(null)
    
    useEffect(() => {
        async function asyncFetchKuerzungForSelect()
        {   
            const pd = JSON.parse(props.data)
            const m = pd[0].mid
            const k = pd[1].kid

            if (m) {
                setMid(m)
                if (k) {
                    setKid(k)                
                    try {                    
                        const response = await fetch('/data/kuerzungen/' + k, { method: 'GET' })
                        if (response.ok) {
                            // console.log("response ok")
                            const result = await response.json();                         
                            // console.log("KÜRZUNG: " + JSON.stringify(result))
                            setKvonString( toShortDateString(result.a_von))
                            setKbisString( toShortDateString(result.a_bis))
                            setKvon( Date.parse(result.a_von))
                            setKbis( Date.parse(result.a_bis))
                            setKprozentual(result.a_prozentual)
                            _setKuerzungsgrundId(result.kuerzungsgrund)
                        }
                        else {
                            console.log("kürzung response not ok")
                        }
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
                try
                {
                    const mresponse = await fetch('/data/massnahmen/' + m)
                    if (mresponse.ok) {
                        const mresult = await mresponse.json();                         
                        // console.log("MASSNAHME Status: " + JSON.stringify(mresult))
                        setStatus(mresult.status)                             
                        setMvon(Date.parse(mresult.f_von))
                        setMbis(Date.parse(mresult.f_bis))                        
                        setFachId( mresult.fachId)

                        if (!k) {
                            setKvon( Date.parse(mresult.f_von))
                            setKbis( Date.parse(mresult.f_bis))
                            setKprozentual(mresult.prozentual)                            
                        }
                    }
                    const sresponse = await fetch('/data/massnahmen/stat/' + m)
                    if (sresponse.ok)
                    {
                        // "anzEinrichtungen": 1,
                        // "betragBerechnet": 32640,
                        // "monateBerechnet": 24,
                        // "monateInEinrichtung": 24,
                        // "monateGesamt": 24
                        const sresult = await sresponse.json();
                        // console.log("monate berechnet " + sresult.monateBerechnet + "  " + typeof(sresult.monateBerechnet))
                        setMonateBerechnet( sresult.monateBerechnet )
                        setMonateGesamt( sresult.monateGesamt)
                    }                    
                }
                catch (err) {
                    console.log(err)
                }
            }
        }
        // console.log("asyncFetchKuerzungForSelect")
        asyncFetchKuerzungForSelect()
    },[])

    useEffect(() => {
        async function asyncFetchKuerzungGrund()
        {  
            try {                    
                // const response = await fetch('/data/kuerzungsgruende/' + kuerzungsgrundId, { method: 'GET' })
                const response = await fetch('/data/kuerzungsgruende/', { method: 'GET' })
                if (response.ok) {                        
                    const result = await response.json();                                               
                    // console.log("KÜRZUNG_TEXT: " + JSON.stringify(result))
                    const kg = []
                    result.forEach( (elem) => {                        
                        if (elem.id === kuerzungsgrundId ) {
                            setKuerzungsgrundText(elem.kuerzungsgrund_kurz)
                        }
                        kg.push( { key : elem.id , value: elem.kuerzungsgrund_kurz })
                    })  
                    setKuerzungsSelectData(kg)                        
                }
                else {
                    console.log("response fail")
                    setKuerzungsgrundText(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                console.log(err)
                setKuerzungsgrundText(err)
            }
        }
        // console.log("asyncFetchKuerzungGrund")
        asyncFetchKuerzungGrund()
    }, [kuerzungsgrundId])

    const [kuerzungsSelectData, setKuerzungsSelectData] = useState(null)

    const MIN_MONATE = 1
    const MAX_MONATE = 100
    const MIN_PROZENTUAL = 1
    const MAX_PROZENTUAL = 100


    function validateMonateErlaubt(mon)
    {
        setErrorText(null)
        const me = Number.parseFloat(mon) 
        if (Number.isNaN(me)) {
            setErrorText("Monate erlaubt ungültig")
            return false;
        } 
        if ((me < MIN_MONATE) || (me > MAX_MONATE)) {
            setErrorText("Monate erlaubt muss zwischen 1 und 100 liegen")
            return false;
        }
        return true;
    }

    function validateProzentual(proz)
    {
        setErrorText(null)
        const me = Number.parseFloat(proz) 
        if (Number.isNaN(me)) {
            setErrorText("Anteil ungültig")
            return false;
        } 
        if ((me < MIN_PROZENTUAL) || (me > MAX_PROZENTUAL)) {
            setErrorText("Anteil muss zwischen 1 und 100 liegen")
            return false;
        }
        return true;
    }

    const savedata = async() => {
        if (!validateProzentual(kprozentual)) {
            return;
        }
        const data = 
        {
            id          : mid,
            kid         : kid,
            kvon        : kVon,
            kbis        : kBis, 
            kprozentual : kprozentual,
            kuerzungsgrundId : kuerzungsgrundId,
        }
        console.log("kuerzung speichern")
        console.log("kuerzungsgrundId " + kuerzungsgrundId)
        console.log("kvon " + new Date(kVon))
        console.log("kbis " + new Date(kBis))
        console.log("kprozentual " + kprozentual)
        const sdata = JSON.stringify(data)
        console.log(sdata)
        const response = await fetch('/data/massnahmen/update/kuerzung', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: sdata,
        })
        if (response.ok) {
            const txt = await response.text()            
            if (txt === "OK")
            {
                console.log(txt)
                if (window.confirm("Massnahme gekürzt. Wollen sie eine Bestätigung drucken?"))
                {                        
                    const res = await FetchService.generateTeilnahmebestaetigung(mid)            
                    if (res.status === 200 && res.data) 
                    {
                        const filename = 'Teilnahmebestaetigung_' + mid + '.docx'
                        const pd = po.createPrintData(filename, res.data.printid , mid)
                        FileSaver.saveAs( res.data.document, pd.filename);                         
                    }
                    else 
                    {
                        alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                        return;
                    }                
                }
                //UPDATE
                props.callback('Kuerzung', 'reload')                 
            }
            else
            {
                const txt = await response.text()  
                alert("Kürzung fehlgeschlagen\n" + txt)
            }
        }
        else 
        {
            const txt = await response.text()      
            alert("Fehler: " + response.status + " " + response.statusText + "\n" + txt)
        }
    }

    const kuerzungBerechnen = async () => {
                
        if (!validateMonateErlaubt(monateErlaubt) || !validateProzentual(kprozentual)) {
            return;
        }

        // @TODO: Algorythmus prüfen
        const kdiff = monateGesamt - monateErlaubt
        if (kdiff <= 0) {
            setErrorText("Kürzung nicht notwendig")
            return;            
        }
        const mdiff = Dates.monthDiff(new Date(kVon), new Date(kBis))
        if (kdiff >= mdiff) {
            setErrorText("Maßnahme zu kurz. Kürzung nicht möglich")
            return;
        }
        const bis = Dates.dateSub(new Date(kBis), "m", kdiff * (100 / kprozentual))

        // console.log("KDiff     " + kdiff)
        // console.log("Monthdiff " + mdiff) 
        // console.log("bis " + bis)
        setKbis(bis)

        const result = await FetchService.calculateDauer(new Date(kVon), bis, fachId, kprozentual)
        if (result.status === 200) {            
            const diff = monateBerechnet - result.data.monateBerechnet
            setMonateBerechnet( result.data.monateBerechnet )
            setMonateGesamt( monateGesamt - diff)
        }
        else 
        {
            console.log(result.status + " " + (result.error ? result.error : result.statusText))
        }
    }

    const _setKuerzungsgrundId = ((val) => {
        const v = parseInt(val)
        if (Number.isInteger(v)) 
        {  
            setKuerzungsgrundId(v)
            setCanSave(true)
        }
        else 
        {
            setKuerzungsgrundId('')
            setCanSave(false)
        }

    })

    const _setMonateErlaubt = ((val) => {             
        validateMonateErlaubt(val)
        setMonateErlaubt(val)        
    })

    const _setKprozentual = ((val) => {
        validateProzentual(val)
        setKprozentual(val)
    })

    return (
        <div>            
            <table>
                <thead>
                    <tr><th colSpan="3" className="td_label_center">K&uuml;rzung</th></tr>
                </thead>
                {(status) && (props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)) &&
                <tbody>
                    <tr className="table-blank-row4"></tr>
                    <tr>
                        <td className="td_label">K&uuml;rzung wegen:</td>
                        <td colSpan="2" className="td_label">{kuerzungsgrundText}</td>
                    </tr>
                    <tr>
                        <td className="td_label">Zeitraum:</td>
                        <td className="td_label">{kvonString}</td>
                        <td className="td_label">{kbisString}</td>
                    </tr>
                    <tr>
                        <td className="td_label">Anteil:</td>
                        <td className="td_label">{kprozentual}%</td>
                    </tr>
                </tbody>
                }
                {(status) && (!props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)) &&
                <tbody>
                    <tr className="table-blank-row4"></tr>
                    <tr>
                        <td className="td_label">K&uuml;rzung wegen:</td>                                         
                        <td className="td_select" colSpan="2">
                            <FormGroup 
                                // contentClassName="td_select"
                                type="select"                                
                                name="kuerzungselect"
                                options={kuerzungsSelectData}
                                value={kuerzungsgrundId}
                                onChange={_setKuerzungsgrundId}                                      
                                readonly= {!canEdit || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}     
                            />                               
                        </td>                        
                        <td className="td_label">Monate berechnet:</td>
                        <td>{(Number.isNaN(monateBerechnet) ? '' : round(monateBerechnet, 2,''))}</td>
                        <td>&nbsp;</td>
                        <td rowSpan="2">
                            <button 
                                className="exportbtn" 
                                onClick={savedata}
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            >
                                <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Zeitraum:</td>
                        <td>
                            <FormGroup
                                    type="html-date"
                                    className="form-control" //"td_datepicker"
                                    value={kVon}
                                    onChange={setKvon}
                                    min={mvon} // {Moment().add(-100, 'years').toDate()}
                                    max={mbis} // {Moment().add(-16,'years').toDate()}
                                    disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            />

                            {/* <DatePicker
                                popperClassName="react-datepicker-popper"
                                className="form-control" //"td_datepicker"
                                selected={kVon}
                                onChange={setKvon} 
                                dateFormat={"dd.MM.yyyy"}
                                minDate={mvon} // {Moment().add(-100, 'years').toDate()}
                                maxDate={mbis} // {Moment().add(-16,'years').toDate()}
                                showFullMonthYearPicker={true}
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            /> */}
                        </td>
                        <td>
                            <FormGroup
                                type="html-date"
                                className="form-control" //"td_datepicker"
                                value={kBis}
                                onChange={setKbis} 
                                min={mvon} // {Moment().add(-100, 'years').toDate()}
                                max={mbis} // {Moment().add(-16,'years').toDate()}
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            />
                            {/* <DatePicker
                                popperClassName="react-datepicker-popper"
                                className="form-control" //"td_datepicker"
                                selected={kBis}
                                onChange={setKbis} 
                                dateFormat={"dd.MM.yyyy"}                                
                                minDate={mvon} // {Moment().add(-100, 'years').toDate()}
                                maxDate={mbis} // {Moment().add(-16,'years').toDate()}
                                showFullMonthYearPicker={true}
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            /> */}
                        </td>                        
                        <td className="td_label">Monate gesamt:</td>
                        <td>{(Number.isNaN(monateGesamt) ? '' :  round(monateGesamt, 2, ''))}</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="td_label">Monate erlaubt:</td>
                        <td className="td_input">
                            <FormGroup
                                type="number"
                                name="monateerlaubt"
                                min="1"
                                max="100"                                
                                value={monateErlaubt}
                                onChange={_setMonateErlaubt}   
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}                         
                            />
                        </td>
                        <td colSpan="3">{errorText}</td>                        
                        <td>&nbsp;</td>
                        <td rowSpan="2">
                        <button 
                                className="exportbtn" 
                                onClick={kuerzungBerechnen}
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}
                            >
                                <ConfigIcon><AiOutlineCalculator/></ConfigIcon>&nbsp;K&uuml;rzung berechnen
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">Anteil:</td>
                        <td className="td_input">
                            <FormGroup
                                type="number"
                                name="prozentual"
                                min="1"
                                max="100"                                
                                value={kprozentual}
                                onChange={_setKprozentual}   
                                disabled={!canEdit || !canSave || props.func(X.B3 | X.B5 | X.B7 | X.B10 | X.B11)}                         
                            />
                        </td>
                        <td>%</td>
                        <td></td>
                        <td></td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
                }
            </table>
            <div>
                <NonModal id={nonmodalid} type='yesno' title='Druckbestätigung' message={po.printmessage} callback={documentprint_callback} data={po.printid}></NonModal>
            </div>
        </div>
    )
}

export default Kuerzung
