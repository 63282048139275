import React, { useState, useEffect } from 'react'
import FileSaver from 'file-saver';
import { AiOutlineSearch, AiOutlineFileExcel } from 'react-icons/ai';
// import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';

import ConfigIcon from '../../util/configicon';
import FetchService from '../../services/FetchService';

import { getReactSearchAutocompleteStyle } from '../../util/style';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { formatEinrichtung } from '../../util/formate'
import { toShortDateString } from '../../util/date-utils'
import { getFuseOptions } from '../../util/search';
import ColumnSorter from '../../util/ColumnSorter';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';

const MassnahmenProEinrichtung = () => {

    const storageKeyId = "massnahmenProEinrichtungSearchId";
    const storageValueId = localStorage.getItem(storageKeyId);
    const storageKeyData = "massnahmenProEinrichtungSearchData";
    const storageValueData = localStorage.getItem(storageKeyData);
    // console.log("storagevalue   " + storageValueId)
    // console.log("storageKeyData " + storageValueData)

    const [elist, setElist] = useState( [{id:0,name:""}]);    
    useEffect(() => {
        async function loadElist()
        {
            const res = await FetchService.loadEinrichtungsDatalist(null)       
            if (Array.isArray(res))
            {   
                // console.log(res[0])   
                let lst = []
                res.forEach(function(item) 
                {   
                    const val = { id:item.id, name: formatEinrichtung(item, 'nopk') } 
                    // const val = [ item.id, formatEinrichtung(item, 'nopk') ] 
                    lst.push(val)
                });      
                setElist(lst)    
            }
        }
        // console.log("loadElis " + einrichtungsId)
        loadElist()
        // console.log(elist.length)        
    }, [])

    const [columnsSorter, setColumnSorter] = useState(null)    
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)

    const [einrichtungsId, setEinrichtungsId] = useState(storageValueId)
    const [einrichtungsData, setEinrichtungsData] = useState(storageValueData ? storageValueData : "")
    const [einrichtungLoaded, setEinrichtungLoaded] = useState(false)

    const handleOnSelectEid = async (item) => {  // the item selected
        console.log("handleOnSelectEid " + JSON.stringify(item))
        localStorage.setItem(storageKeyId, item.id); 
        localStorage.setItem(storageKeyData, item.name); 
        setEinrichtungsId(item.id)
        setEinrichtungsData(item.name)
        setEinrichtungLoaded(false)
    }

    const handleOnClearEid = () => { 
        setEinrichtungsId(null); 
        setmdata(null); 
        setEinrichtungsData(""); 
        setSortOrder(false)            
        setLastSort(null)
        setEinrichtungLoaded(false)
    }

    const formatResult = (item) => { return item.name; }  

    const [mdata, setmdata] = useState(null)

    const sucheStarten = async() => {
        if (einrichtungsId) 
        {
            // console.log("Suche starten zur Einrichtung " + einrichtungsId)                 
            try 
            {
                const response = await fetch('/data/massnahmen/proeinrichtung/' + einrichtungsId)
                if (response.ok) {
                    const result = await response.json();    
                    if (Array.isArray(result))
                    {                        
                        setEinrichtungLoaded(true)
                        setmdata(result)
                        
                        setColumnSorter( new ColumnSorter(result,
                            [
                                [ 'TID' , 'tid'],
                                [ 'MID' , 'mid'],
                                [ 'Teilnehmer', 'Teilnehmer'],
                                [ 'Status', 'StatusText'],
                                [ 'Von' , 'f_von'],
                                [ 'Bis' , 'f_bis'],
                                [ 'Am' , 'ausgezahlt_am'],
                                [ 'Vermerk', 'auszahlvermerk'],
                                [ 'Verwendungszweck', 'Verwendungszweck']
                            ]
                        ))

                        return;
                    }
                    // console.log("error")
                    // defaultError[0].value = "E:" + result.name
                    throw new Error(result.name)                                    
                }
                else
                {
                    // console.log("error")
                    // defaultError[0].value = "E:" + response.status
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {
                // setkvBezirkData(defaultError)

                console.log(err)
            }
        }
    }

    const columnclick = (evt) => {
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {                
                setmdata(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

    const massnahmenProEinrichtungXls = async () => {
        if ((einrichtungsId) && (mdata) && (mdata.length > 0))
        {
            console.log("Export der Maßnahmen zur Einrichtung " + einrichtungsId)
            const res = await FetchService.generateMassnahmenProTeilnehmerExcel(einrichtungsId)            
            if (res.status === 200 && res.data) {                
                FileSaver.saveAs( res.data, 'AlleDaten_EID_' + einrichtungsId + toShortDateString(Date.now()) + '.xlsx');             
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
    }

    return (
        <>
            <div className="sp_table_div">
            <table className="sm_table_80">
                <thead>                  
                    <tr>
                        <th colSpan="8"><h3>Ma&szlig;nahmen pro Einrichtung</h3></th><th></th><th></th><th></th>                        
                    </tr>         
                </thead>
                <tbody>
                    <tr className="table-blank-row"></tr>
                    <tr>  
                        {/* <td className="td_label">Alle Massnahmen einer Einrichtung:</td> */}
                        <td className="td_input">
                            <div id="div_reactsearchautocomplete_eid" className="rsac_eid">
                                <ReactSearchAutocomplete                                                                             
                                    className="rsac"     
                                    items={elist}                                
                                    fuseOptions={getFuseOptions(0.4, 3)}
                                // onSearch={handleOnSearch}
                                // onHover={handleOnHover}
                                    onSelect={handleOnSelectEid}
                                // onFocus={handleOnFocus}
                                    onClear={handleOnClearEid}
                                    autoFocus
                                    formatResult={formatResult}
                                    inputSearchString={einrichtungsData} // "" // {einrichtungsData}
                                    showIcon={false}
                                    placeholder="Einrichtungsname eingeben"
                                    styling={getReactSearchAutocompleteStyle()}
                                    maxResults={50}
                                />
                            </div>
                        </td>
                        { einrichtungsId && <td className="td_label_center"><a href={`/Einrichtung/Edit?eid=${einrichtungsId}`}>{einrichtungsId}</a></td> }
                        <td>
                            <button className="exportbtn" onClick={sucheStarten}>
                                <ConfigIcon><AiOutlineSearch/></ConfigIcon>&nbsp;Suche starten                                
                            </button>
                        </td>
                        { einrichtungsId && mdata && mdata.length > 0 && <td>
                            <button className="exportbtn" onClick={massnahmenProEinrichtungXls}>
                                <ConfigIcon><AiOutlineFileExcel/></ConfigIcon>&nbsp;Exportieren                             
                            </button>
                        </td>                        
                        }
                    </tr>                   
                    <tr className="table-blank-row"></tr>
                </tbody>
            </table>
            </div >
            {einrichtungLoaded && mdata && mdata.length > 0 && <div className="sm_table_div" >
            <table className="sm_table_90">
                <thead>
                    <tr>
                        <th colSpan="6"></th>
                        <th colSpan="2" className="td_label_center">Auszahlung</th>
                    </tr>
                    <tr>
                        <SortableTableHeader onClick={columnclick} title='MID' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader onClick={columnclick} title='TID' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader onClick={columnclick} title='Teilnehmer' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader onClick={columnclick} title='Status' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader onClick={columnclick} title='Von' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader onClick={columnclick} title='Bis' lastSort={lastSort} sortOrder={sortOrder} />
                        <SortableTableHeader onClick={columnclick} title='Am' lastSort={lastSort} sortOrder={sortOrder} />
                        <th>Vermerk</th>
                        {/* <SortableTableHeader onClick={columnclick} title='Vermerk' lastSort={lastSort} sortOrder={sortOrder} /> */}
                        <th>Verwendungszweck</th>
                        {/* <SortableTableHeader onClick={columnclick} title='Verwendungszweck' lastSort={lastSort} sortOrder={sortOrder} /> */}
                    </tr>
                </thead>
                <tbody> 
                    {
                        mdata.map( ({ mid, tid, Teilnehmer, StatusText, f_von, f_bis, 
                            ausgezahlt_am, auszahlvermerk, Verwendungszweck}) => 
                        {   
                            return (
                                <tr className="liste" key={mid}>
                                    <td className="td_fitwidth"><a href={`/Massnahme/Edit?mid=${mid}`}>{mid}</a></td>
                                    {/* <td className="td_fitwidth">{eid}</td> */}
                                    <td className="td_fitwidth"><a href={`/Teilnehmer/Edit?tid=${tid}`}>{tid}</a></td>
                                    <td className="td_fitwidth">{Teilnehmer}</td>
                                    {/* <td className="td_fitwidth">{kurzbezeichnung}</td> */}
                                    <td className="td_fitwidth">{StatusText}</td>

                                    <td className="td_fitwidth">{toShortDateString(f_von)}</td>
                                    <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
                                    <td className="td_fitwidth">{toShortDateString(ausgezahlt_am)}</td>
                                    <td className="td_fitwidth">{auszahlvermerk}</td>
                                    <td className="td_fitwidth">{Verwendungszweck}</td>
                                </tr>
                                )
                        })
                    }
                </tbody>
            </table>
            </div>
            }
            {einrichtungLoaded && mdata && mdata.length === 0 && <div>
                <table className="sm_table_60" ><thead><tr><th>Keine Daten gefunden</th></tr></thead></table>
            </div> }
        </>
    )
}

export default MassnahmenProEinrichtung
