import React, { useState } from 'react'
import { AiOutlineEdit, AiOutlineSearch, AiOutlineCloseCircle  } from 'react-icons/ai';
import FormGroup, { CHECKBOX_STATES } from '../../components/shared/form-group';
import MaterialTable from 'material-table';

import ConfigIcon from '../../util/configicon';
import json2array, { toValidJsonObject } from '../../util/json2array'
import '../../components/shared/context-menu/contextmenu.css';
import { formatPlz } from '../../util/formate';
import DoubleClickDetector from '../../util/DoubleClickDetector';
import { materialTableOptions, materialTableLocalization } from '../../util/material-table-options';

const SearchEinrichtung = () => {

    
    const uri = new URL(window.location.href)
    console.log(uri)
    console.log(window.location.href)

    const storageKey = "einrichtungSearchData";
    const storageValue = toValidJsonObject(localStorage.getItem(storageKey))
    // console.log("LOAD  storageValue " + storageValue);
    const ESearchData = (storageValue ? storageValue :
    {
        id: null,
        ik: null,
        kurzbezeichnung: null,
        namen: null,
        plz: null,
        ort: null,
        einrichtungMitVerwendungszweck : null,
        zustimmungZurVeroeffentlichung : CHECKBOX_STATES.Indeterminate,
    })

    const [id, setId] = useState(ESearchData.id);
    const [ik, setIk] = useState(ESearchData.ik);
    const [kurzbezeichnung, setKurzbezeichnung] = useState(ESearchData.kurzbezeichnung);
    const [namen, setNamen] = useState(ESearchData.namen);
    const [plz, setPlz] = useState(ESearchData.plz);
    const [ort, setOrt] = useState(ESearchData.ort);
    const [einrichtungMitVerwendungszweck, setEinrichtungMitVerwendungszweck] = useState(ESearchData.einrichtungMitVerwendungszweck)
    const [zustimmungZurVeroeffentlichung, setZustimmungZurVeroeffentlichung] = useState(ESearchData.zustimmungZurVeroeffentlichung)

    const [selectedRow, setSelectedRow] = useState(null)
    const [selectmode, setSelectMode] = useState(window.location.href.endsWith('Select'))

    const resultColumns = [
        
        { title:'Kurzbezeichnung',          field:'kurzbezeichnung',           width:'50%', minWidth:'50%',maxWidth:'50%' },
        { title:'Straße oder Postfach',     field:'strasse_hausnr_postfach',   width:'10%' },        
        { title:'Postleitzahl',             field:'postleitzahl',              width:'5%'  },
        { title:'Ort',                      field:'ort',                       width:'5%'  },        
        { title:'WB Verbund',               field:'wb_verbund',                width:'5%'  },
        // { title:'kvid_kbv',                 field:'kvid_kbv',                  width:'5%'  },
        { title:'KV Bezirk',                field:'kv_bezirk',                 width:'5%'  },
        { title:'IK',                       field:'ik',                        width:'5%'  },
        { title:'IK Alt',                   field:'ik_alt',                    width:'10%', minWidth:'10%',maxWidth:'10%' },
    ];
    const [resultData, setResultData] = useState([
        // {
            // id
            // ik
            // ik_alt
            // kurzbezeichnung
            // postleitzahl
            // ort
            // strasse_hausnr_postfach
            // wb_verbund
            // kvid_kbv
            // kv_bezirk
        // }
    ])

    const tableRef = React.createRef();
    
    const sucheStarten = () => {
        ESearchData.id = id
        ESearchData.ik = ik
        ESearchData.kurzbezeichnung = kurzbezeichnung
        ESearchData.namen = namen
        ESearchData.plz = plz
        ESearchData.ort = ort
        ESearchData.einrichtungMitVerwendungszweck = einrichtungMitVerwendungszweck
        ESearchData.zustimmungZurVeroeffentlichung = zustimmungZurVeroeffentlichung
        
        const sv = JSON.stringify(ESearchData);        
        localStorage.setItem(storageKey, sv); 

        fetch('/data/einrichtungen/search/' + sv, {
            method: 'POST'
        })
        .then( (res) => {
            // console.log("then " + res)
            if (res.ok) {
                return res.json()
            }
            throw new Error(res.status + " " + res.statusText)
        })
        .then( (json) => {
            
            if (Array.isArray(json))
            {
                 let mySource = json2array(json); 
                 // console.log("mySource.length " + mySource.length)   
                 // console.log("then json " + JSON.stringify(json))                                    
                 let myResult = [];
                 for (let num=0; num < mySource.length; num++)
                 {   
                    const kv_bezeichnung = (mySource[num].Kv) ? mySource[num].Kv.bezeichnung : null;
                    const wbverbund_bezeichnung = (mySource[num].Wbverbund) ? mySource[num].Wbverbund.bezeichnung : null;         
                    myResult.push({
                         id : mySource[num].id,
                         ik : mySource[num].ik,
                         ik_alt: mySource[num].ik_alt,
                         kurzbezeichnung : mySource[num].kurzbezeichnung,
                         postleitzahl: formatPlz(mySource[num].postleitzahl),
                         ort: mySource[num].ort,
                         strasse_hausnr_postfach: mySource[num].strasse_hausnr_postfach,
                         wb_verbund: wbverbund_bezeichnung,
                         kvid_kbv: mySource[num].kvid_kbv,
                         kv_bezirk: kv_bezeichnung, //mySource[num].Kv.Bezeichnung,
                    })
                }
                // console.log("myResult.length " + myResult.length)                               
                setResultData(myResult);          
            }
            else
            {
                throw new Error("unexpected result")
            }
        })
        .catch((err) => {
            console.log("catch " + err)
        })
    }

    const sucheClear = () => {        
        setId(null);
        setIk(null);
        setKurzbezeichnung(null);
        setNamen(null);
        setPlz(null);
        setOrt(null);
        setEinrichtungMitVerwendungszweck(null);
        setZustimmungZurVeroeffentlichung(CHECKBOX_STATES.Indeterminate);
        setResultData( [] );
        localStorage.setItem(storageKey, null); 
    }

    const einrichtungEdit = () => {
        if ((selectedRow) || Number.isInteger(selectedRow))  {
            if (selectmode) 
            {
                localStorage.setItem("FOUND_EID", resultData[selectedRow].id)
                window.history.back();
            }
            else 
            {
                window.location.href = "/Einrichtung/Edit?eid="+resultData[selectedRow].id
            }
        }
    }

    return (
        <div>
            <div className="sm_table_div">  
            <table>
                <thead>
                    <tr className="table-blank-row"></tr>         
                    <tr>
                        <th colSpan="4"><h3>Suche Einrichtung</h3></th><th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td className="td_label">ID:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="einrichtungsId"                             
                                value={id} 
                                onChange={setId} 
                            />
                        </td>
                        <td className="td_check" colSpan="2">
                            <FormGroup 
                                type="checkbox" 
                                label="Nur Einrichtung mit Verwendungszweck" 
                                value={einrichtungMitVerwendungszweck} 
                                onChange={setEinrichtungMitVerwendungszweck} 
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="td_label">IK:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="ik"                             
                                value={ik} 
                                onChange={setIk} 
                            />
                        </td>
                        <td className="td_check" colSpan="2">
                            <FormGroup 
                                type="checkbox3" 
                                label="Zustimmung zur Veröffentlichung" 
                                value={zustimmungZurVeroeffentlichung} 
                                onChange={setZustimmungZurVeroeffentlichung} 
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Kurzbezeichnung:</td>
                        <td colSpan="3" className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="kurzbezeichnung"                             
                                value={kurzbezeichnung} 
                                onChange={setKurzbezeichnung} 
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Namen:</td>
                        <td colSpan="3" className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="namen"                             
                                value={namen} 
                                onChange={setNamen} 
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Postleitzahl:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="plz"                             
                                value={plz} 
                                onChange={setPlz} 
                            />
                        </td>
                        <td className="td_label">Ort:</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="ort"                             
                                value={ort} 
                                onChange={setOrt} 
                            />
                        </td>
                        <td>                            
                        </td>
                    </tr>
                    <tr className="table-blank-row"></tr>   
                    <tr>   
                        <td className="td_label">
                            <button className="exportbtn" title="Starten der Suche" onClick={sucheStarten}>
                                <ConfigIcon><AiOutlineSearch/></ConfigIcon>&nbsp;Suche starten
                            </button>
                        </td>
                        <td>
                            <button className="exportbtn" title="Löschen aller Suchfelder" onClick={sucheClear}>
                                <ConfigIcon><AiOutlineCloseCircle/></ConfigIcon>&nbsp;Suche l&ouml;schen
                            </button>
                        </td>
                        <td></td>  
                        <td>
                            <button className="exportbtn btndefault" title="Einrichtung bearbeiten (die gewählte)" onClick={einrichtungEdit}>
                                <ConfigIcon><AiOutlineEdit/></ConfigIcon>&nbsp;Einrichtung bearbeiten
                            </button>
                        </td>
                    </tr>
                    <tr className="table-blank-row"></tr> 
                </tbody>
            </table>
            </div>
            <div onContextMenu={ (evt) => {console.log("DIV Context Menu") }} >
            {/* <div > style={{ maxWidth: '100%', justifyContent:'center'}}> */}
              <MaterialTable             
                tableRef={tableRef}
                columns={resultColumns} 
                data={resultData} 
                title={'Einrichtungen ('  + resultData.length + ')'}
                localization= {
                    materialTableLocalization()
                }              
                options={
                    Object.assign( 
                        {                    
                            rowStyle: rowData => ({
                                backgroundColor:selectedRow === rowData.tableData.id ? "#ccc" : ""
                            }),                
                        },
                        materialTableOptions()     
                    )
                }
                onRowClick={ (evt, selectedRow) => {
                    setSelectedRow(selectedRow.tableData.id)
                    if (DoubleClickDetector.mouseClickEvent(evt)) {
                        einrichtungEdit();
                    }
                }}                
                onContextMenu={ (evt) => {
                    console.log("Table Context Menu")
                }}
                
                >
                    <div>
                        {/* <ContextMenu ></ContextMenu> */}
                    </div>
                </MaterialTable>
            </div>
        </div>
    )    
}

export default SearchEinrichtung; 