import React, { useState, useEffect} from 'react'
// import { authenticationService } from '../../services';
import { formatCurrency } from '../../util/number-utils.js'

const Verrechnung = (props) => {

    // const [canEdit, setCanEdit] = useState(authenticationService.currentUser.category > 0)

    // const [mid, setMid] = useState(null)
    // const [vid, setVid] = useState(null)    
    // const [sonderfall, setSonderfall ] = useState(null)
    
    const [bemerkung, setBemerkung] = useState(null)
    const [betrag, setBetrag] = useState(null)
    const [massnahmen_id_quelle, setMassnahmen_id_quelle] = useState(null)
       
    useEffect(() => {
        async function asyncFetchVerrechnungForSelect()
        {          
            // console.log(props)
            const pd = JSON.parse(props.data)
            const m = pd[0].mid
            const v = pd[1].vid

            if (m) {
                // setMid(m)
                if (v) {
                    // setVid(v)                
                    try {                    
                        const response = await fetch('/data/verrechnungen/' + v, { method: 'GET' })
                        if (response.ok) {
                            // console.log("response ok")
                            const res = await response.json();                         
                            let result = res;
                            if (Array.isArray(res)) {
                                console.log("Verrechnung ist Array!!!")
                                result = res[0].Verrechnungen
                            }
                            // console.log("VERRECHNUNG: " + JSON.stringify(result))                            
                            // setSonderfall(result.sonderfall)
                            setBemerkung(result.bemerkung)
                            setBetrag(result.betrag)
                            setMassnahmen_id_quelle(result.massnahmen_id_quelle)
                        }
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
            }
        }
        asyncFetchVerrechnungForSelect()
    },[props.data])


    return (
        <div>
            {/* <h3>Verrechnung {props}</h3> */}
            <table>
                <thead>
                    <tr><th colSpan="4">Verrechnung</th></tr>
                </thead>
                <tbody>
                    <tr className="table-blank-row4"></tr>
                    <tr>
                        <td className="td_label">Begründung:</td>
                        <td colSpan="3">{bemerkung}</td>
                    </tr>
                    <tr>
                        <td className="td_label">Sonderbetrag:</td>
                        <td>{formatCurrency(betrag, 2, '€')}</td>
                        <td className="td_label">Aus MID:</td>
                        <td>
                            {
                            (massnahmen_id_quelle) ? 
                            <a href={`/Massnahme/Edit?mid=${massnahmen_id_quelle}&tabpage=3`}>{massnahmen_id_quelle}</a> : ""
                            }
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default Verrechnung
