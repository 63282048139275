import React, { useEffect, useState} from 'react'
import { AiOutlineFileWord, AiOutlineMail } from 'react-icons/ai';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import FileSaver from 'file-saver';

import ConfigIcon from '../../util/configicon';
import FormGroup from '../../components/shared/form-group';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';
import NonModal from '../../components/shared/nonmodal/NonModal';
import { formatTeilnehmer, formatEinrichtung, formatPlz, formatAnsprechpartner } from '../../util/formate'
import { toShortDateString } from '../../util/date-utils'
import { round } from '../../util/number-utils'
import { authenticationService, FetchService, StorageService } from '../../services';
import ColumnSorter from '../../util/ColumnSorter';

function MassnahmenProTeilnehmerListe(props) {

    const nonmodalid = 'NonModalKnz';

    const [standalone, setStandalone] = useState(true)
    const [teilnehmerId, setTeilnehmerId] = useState(null)

    // let TID = null
    // let EID = null

    useEffect( () => {
        // console.log(props)
        let T = new URL(window.location.href).searchParams.get("tid");          
        if (!T) {            
            T = props.tid
            setStandalone(false)
        }
        // TID = T

        let E = new URL(window.location.href).searchParams.get("eid");
        if (!E) {
            E = props.eid            
        }
        StorageService.updateMptData({tid:T, eid:E})                
        setTeilnehmerId(T)
        // EID = E
        // console.log("TID " + T + " EID " + E)
    }, [])    
 
    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    const [errorText, setErrorText] = useState(null)  
    
    const [vorname, setVorname] = useState(null)
    const [title, setTitle] = useState(null)
    const [nachname, setNachname] = useState(null)
    const [geburtsdatum, setGeburtsdatum] = useState(null)
    const [geburtsname, setGeburtsname] = useState(null)
    const [arztnummer, setArztnummer] = useState(null)
    const [geschlecht, setGeschlecht] = useState(null)

    const [einrichtungen, setEinrichtungen] = useState(null)
    const [einrichtungenKeyValues, setEinrichtungenKeyValues] = useState(null)
    const [einrichtungenKey, setEinrichtungenKey] = useState(-2)
    const [selectedEinrichtung, setSelectedEinrichtung] = useState(null)
    const [selectedMassnahmen, setSelectedMassnahmen] = useState(null)

    const [selectedMassnahmeIndex, setSelectedMassnahmeIndex] = useState(-1)

    const [columnsSorter, setColumnSorter] = useState(null)    
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)

    function setEinrichtungsSelection(eid)  
    {       
        if ((einrichtungen) && (einrichtungen.length > 0) && (eid))
        {
            // console.log("EID " + eid + " einrichtungen.length " + einrichtungen.length)            
            const ieid = parseInt(eid)
            let er = einrichtungen.find( (elem) => {return elem.eid === ieid} )                    
            if (!er) {
                er = einrichtungen[0];
            }
            if (er)
            {                
                setSelectedEinrichtung(er)
                setSelectedMassnahmen(er.Massnahmen)
                setSelectedMassnahmeIndex( (er.Massnahmen ? (er.Massnahmen.length === 0 ? -1 : 0) : -1))
                setColumnSorter(new ColumnSorter(er.Massnahmen, 
                    [
                        ['ID' , 'mid'],
                        ['Vorname' , 'vorname'],
                        ['Nachname','nachname'],
                        ['Von', 'f_von'],
                        ['Bis' ,'f_bis'],
                        ['%' , 'prozentual'],
                        ['Monate',  'monate'],
                        ['Fachgebiet', 'fachgebiet'],
                        ['Status', 'status']
                    ]))
                return;
            }            
            console.log("keine Einrichtung mit eid " + eid + " gefunden")         
        } 
        else
        {   
            // console.log("EID " + eid + " einrichtungen " + einrichtungen)
        }
        setSelectedEinrichtung(null)
        setSelectedMassnahmen(null)
        setSelectedMassnahmeIndex(-1)        
    }
        
    async function asyncFetchMassnahmenProTeilnehmerForSelect() 
    {
        if (teilnehmerId) 
        {   
            try 
            {
                const response = await fetch('/data/beschaeftigungen/einrichtung/massnahmen/' + teilnehmerId, 
                {
                        method: 'GET',
                        cache: 'no-store'
                })
                if (response.ok) {                
                    // console.log("response ok")                        
                    const result = await response.json();                                                     
                    const er = [];
                    const ekv = [];
                    let first = true
                    result.forEach( (elem) => 
                    {                            
                        if (first) 
                        {                                
                            first = false         
                            setVorname(elem.Teilnehmer.vorname)
                            setTitle(elem.Teilnehmer.titel)
                            setNachname(elem.Teilnehmer.nachname)                                
                            setGeburtsdatum(toShortDateString(elem.Teilnehmer.geburtsDatum))
                            setGeburtsname(elem.Teilnehmer.geburtsName)
                            setArztnummer(elem.Teilnehmer.arztnummer)
                            setGeschlecht(elem.Teilnehmer.geschlecht)
                            setTeilnehmerId(elem.Teilnehmer.tid)
                        }
                        let mm = []
                        let idx = 0
                        if (elem.Einrichtungen && elem.Einrichtungen.Massnahmens)
                        {                                
                            elem.Einrichtungen.Massnahmens.forEach( (m) => {
                                // console.log("MID:" + m.mid + " BIS: " + toShortDateString(m.f_bis))
                                mm.push( {
                                    index: idx,
                                    mid: m.mid, 
                                    nachname: formatTeilnehmer(elem.Teilnehmer, "ng"), 
                                    vorname : elem.Teilnehmer.vorname, 
                                    f_von : m.f_von, 
                                    f_bis : m.f_bis,
                                    prozentual : m.prozentual,
                                    monate: round(m.dauer, 2, ''),
                                    fachgebiet : m.Fachgebiete.bezeichnung,
                                    eingang : toShortDateString(m.eingang_antrag),
                                    status: m.Massnahmenstatus.kurzbezeichnung,
                                    ansprechpartner: formatAnsprechpartner(m.ansprechpartner, "an"), // "Anrede + Name",
                                    auszahlvermerk: m.auszahlvermerk,            
                                })
                                idx += 1;
                            })                                
                        }                           
                        er.push(
                            {                                
                                eid : elem.Einrichtungen.eid,
                                einrichtungsName : formatEinrichtung(elem.Einrichtungen, "no"), //.kurzbezeichnung, 
                                einrichtungsId : elem.Einrichtungen.eid,
                                datumletztebestaetigung : toShortDateString(elem.datumLetzteBestaetigung),
                                einwilligungsumfang : elem.einwilligungsUmfang,
                                // einwilligungsumfangtext : elem.Beschaeftigungen.EinwilligungsUmfaenge.beschreibung,
                                anzahlBestaetigungen : elem.anzahlBestaetigungen,
                                addresse1 : elem.Einrichtungen.kurzbezeichnung + " " + elem.Einrichtungen.ort,
                                addresse2 : elem.Einrichtungen.strasse_hausnr_postfach + ", " + 
                                    formatPlz(elem.Einrichtungen.postleitzahl) + " " + elem.Einrichtungen.ort,
                                monathier : round(elem.dauerineinrichtung,2,''),
                                monategesamt : round(elem.dauergesamt,2,''),
                                ik : elem.Einrichtungen.ik,
                                ikalt : elem.Einrichtungen.ik_alt,
                                Massnahmen : mm
                            }                                
                        )
                        ekv.push(
                            {
                                key: elem.Einrichtungen.eid,
                                value: formatEinrichtung(elem.Einrichtungen, "no")
                            }
                        )
                    })                        
                    _setEinrichtungen(er)
                    setEinrichtungenKeyValues(ekv)
                    if (ekv.length > 0)
                    {    
                        const eid = StorageService.getMptData().eid                        
                        // const key = (EID ? EID : ekv[0].key)
                        const key = (eid ? eid : ekv[0].key);
                        // console.log("EinrichtungenKey " + key)
                        _setEinrichtungenKey(key)                       
                    }
                    else
                    {
                        console.log("EinrichtungenKey -1")
                        _setEinrichtungenKey(-1)                        
                    }                                           
                }
                else
                {
                    throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err)
            {
                console.log(err)
                console.log(JSON.stringify(err))    
                setErrorText(err)                                                
            }
        }
    }

    const _setEinrichtungen = (er) => {
        // console.log("set Einrichtungen " + er)
        setEinrichtungen(er)
    }

    useEffect(() => {   
        async function f() { asyncFetchMassnahmenProTeilnehmerForSelect(); }
        f();
    } , [teilnehmerId])

    useEffect(() => {
        async function f() { setEinrichtungsSelection(einrichtungenKey) }
        f();
    }, [einrichtungenKey])

    const _setEinrichtungenKey = ( (evt) => {
        const eid = parseInt(evt)   
        // StorageService.setEinrichtungenId(eid)  
        // EID = eid
        // console.log("set einrichtungenKey " + eid)
        StorageService.updateMptData( { eid: eid })        
        setEinrichtungenKey(eid)   
    })

    async function reloadData()
    {   
        const sdata = StorageService.getMptData()
        if (sdata && sdata.tid) 
        {
            setTeilnehmerId(sdata.tid)
        }       
    }

    function notifyUpdate(e) 
    {     
        if (e.newValue) { // set storage != null, remove storage == null 
            if (e.key === 'UPDATEMM') {                            
                reloadData()            
            } else if (e.key === 'UPDATEBL') {                    
                reloadData();            
            } else if (e.key === 'UPDATETN') {                                   
                reloadData()
            } else if (e.key === 'UPDATEEID') {                
                reloadData()
            }
        }
    }

    useEffect(() => {
        window.addEventListener('storage', notifyUpdate)
        return () => {
            window.removeEventListener('storage', notifyUpdate)        
        }
    }, [])

    const massnahmeClick = ((evt) => {    
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = idattribute.value
            setSelectedMassnahmeIndex(Number.parseInt(val))
        }
    })

    const briefClick = ( async (evt) => {        
        if (selectedMassnahmen && selectedMassnahmen.length>0 && selectedMassnahmeIndex >= 0) {
            const mid = selectedMassnahmen[selectedMassnahmeIndex].mid
            const res = await FetchService.generateMassnahmeBrief(mid)            
            if (res.status === 200 && res.data) {
                const pd = createPrintData( 'Brief_MID_' + mid + '.docx', res.data.printid, mid)
                FileSaver.saveAs( res.data.document, pd.filename );                  
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
        else
        {
            alert("Keine Maßnahmen gefunden")
        }
    })
   
    const emailClick = ( async (evt) => {
        if (selectedMassnahmen && selectedMassnahmen.length>0 && selectedMassnahmeIndex >= 0) {
            const mid = selectedMassnahmen[selectedMassnahmeIndex].mid 
            const res = await FetchService.generateMassnahmeMail(mid)
            if (res.status === 200 && res.data) {                  
                window.location.href = res.data
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
        else
        {
            alert("Keine Maßnahmen gefunden")
        }
    })

    const teilnahmeBestaettigungClick = ( async (evt) => {
        if (selectedMassnahmen && selectedMassnahmen.length>0 && selectedMassnahmeIndex >= 0) {
            const mid = selectedMassnahmen[selectedMassnahmeIndex].mid
            const res = await FetchService.generateTeilnahmebestaetigung(mid)            
            if (res.status === 200 && res.data) {
                const pd = createPrintData( 'Teilnahmebestaetigung_' + mid + '.docx', res.data.printid, mid)
                FileSaver.saveAs( res.data.document, pd.filename); 
                
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
        else
        {
            alert("Keine Maßnahmen gefunden")
        }
    }) 
    
    const bestaetigungsSchreibenClick = ( async (evt) => {
        if (selectedMassnahmen && selectedMassnahmen.length>0 && selectedMassnahmeIndex >= 0) {
            const mid = selectedMassnahmen[selectedMassnahmeIndex].mid
            const res = await FetchService.generateBestaetigungsschreiben(mid)
            if (res.status === 200 && res.data) {
                const pd = createPrintData('Bestaetigungsschreiben_' + mid + '.docx', res.data.printid, mid)
                FileSaver.saveAs( res.data.document, pd.filename);                 
            }
            else {
                alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
            }
        }
        else
        {
            alert("Keine Maßnahmen gefunden")
        }
    })

    const neueMassnahmeClick = ( async (evt) => {
        
        // console.log("neueMassnahmeClick")     
        // console.log("Neue Maßnahme TID " + teilnehmerId + " EID " + einrichtungenKey)

        // StorageService.setTeilnehmerId(teilnehmerId)
        // StorageService.setEinrichtungenId(einrichtungenKey)
        // const tid = teilnehmerId
        // const eid = einrichtungenKey   
        
        // const ids = 
        // {
        //     bid : -1,
        //     eid : eid,
        //     tid : tid,
        //     teilnehmer : null, 
        // }
        // localStorage.setItem(StorageService.TeilnehmerEinrichtungsData, JSON.stringify(ids))
        window.location.href="/Massnahme/New"
    }) 
    
    function showpopup(id) {
        const element = document.getElementById(id)
        if (element) {
            element.style.display = 'block';                                    
        }
    }

    function showprintdialog(printid, printmessage)
    {        
        if (printid && printmessage) {                                
            showpopup(nonmodalid)
        }     
    }
        
    const [printid, setprintid] = useState(null)
    const [printmessage, setprintmessage] = useState(null)

    useEffect(() => {    
        async function asyncshowprintdialog()
        {        
            showprintdialog(printid, printmessage);
        }
        asyncshowprintdialog()      
    }, [printid, printmessage]);
    
    
    function createPrintData(filename, printid, mid) {
        setprintid(printid)
        setprintmessage('\nIst die Datei\n' + filename + '\nerfolgreich gedruckt worden?\n')

        const pd = {
            filename : filename,
            printid : printid,
            mid : mid
        }
        
        return pd;
    }

    async function documentprint_callback (cmd, param)
    {       
        console.log(cmd + " " + JSON.stringify(param)) 
        if (param) {                        
            if (cmd === 'yes') {
                const res = await FetchService.updatePrintjob([param], true)
                console.log(res)
            } else if (cmd === 'no')  {
                const res = await FetchService.updatePrintjob([param], false)
                console.log(res)
            }
        } else if (cmd === 'close') {
            setprintmessage(null)
            setprintid(0)
        }
    }

    const columnclick = (evt) => {   
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {                
                setSelectedMassnahmen(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

    return (
    <div>
        <div className="sm_table_div">
            <table className="sm_table_80">
                <thead>
                    <tr className="table-blank-row"></tr>         
                    <tr>
                        <th><h3>{errorText}</h3></th>
                    </tr>
                    {standalone && <tr>
                        <th colSpan="8"><h3>Ma&szlig;nahmen pro Teilnehmer</h3></th><th></th><th></th><th></th>                        
                    </tr>}
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <th align="left">Teilnehmer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>ID</td><td>Vorname</td><td>Nachname</td><td>Geburtsname</td><td>Geburtsdatum</td><td>Geschlecht</td><td>Titel</td><td>Arztnummer</td>
                    </tr>
                    <tr>
                        <td><a href={"/Teilnehmer/Edit?tid=" + teilnehmerId}>{teilnehmerId}</a></td>
                        <td>{vorname}</td>
                        <td>{nachname}</td>
                        <td>{geburtsname}</td>
                        <td>{geburtsdatum}</td>
                        <td>{geschlecht}</td>
                        <td>{title}</td>
                        <td>{arztnummer}</td>                        
                    </tr>                    
                    <tr className="table-blank-row"></tr>
                    <tr><td colSpan="8"><hr></hr></td></tr>
                </tbody>
            </table>
        </div>

        <div className="sm_table_div">
             <table className="sm_table_80">
                <thead>                    
                    <tr>
                        <th align="left">Einrichtung</th>
                        <th className="td_select">
                            <FormGroup
                                type="select" label=""
                                name="einrichtungsselect"
                                value={einrichtungenKey} 
                                onChange={_setEinrichtungenKey} 
                                options={einrichtungenKeyValues} 
                                empty={false}    
                            />                            
                        </th>
                        <td className="td_label_left">
                            {einrichtungenKeyValues && einrichtungenKeyValues.length === 0 &&
                                <>Dem Teilnehmer sind keine Einrichtungen zugeordnet</>
                            }
                            {einrichtungenKeyValues && einrichtungenKeyValues.length === 1 &&
                                <>eine Einrichtung</>
                            }
                            {einrichtungenKeyValues && einrichtungenKeyValues.length > 1 &&
                                <> {einrichtungenKeyValues.length} Einrichtungen </>
                            }                            
                        </td>
                    </tr>
                </thead>   
                <tbody>                    
                    {selectedEinrichtung && 
                    <>
                        <tr>
                            <td>Einrichtungsname:</td><td colSpan="3">{selectedEinrichtung.einrichtungsName}</td>
                            <td>ID:</td>
                            <td><a href={"/Einrichtung/Edit?eid=" + selectedEinrichtung.eid}>{selectedEinrichtung.eid}</a></td>
                        </tr>
                        <tr>
                            <td>Letzte Bestätigung:</td><td>{selectedEinrichtung.datumletztebestaetigung}</td>
                            <td className="td_label_right">{selectedEinrichtung.einwilligungsumfangtext}</td><td></td>
                            <td>Anzahl Bestätigungen:</td><td>{selectedEinrichtung.anzahlBestaetigungen}</td>
                        </tr>
                        <tr>
                            <td></td><td colSpan="3">{selectedEinrichtung.addresse1}</td>
                            <td>Monat hier:</td><td>{selectedEinrichtung.monathier}</td>
                        </tr>
                        <tr>
                            <td></td><td colSpan="3">{selectedEinrichtung.addresse2}</td>
                            <td>Monate gesamt:</td><td>{selectedEinrichtung.monategesamt}</td>
                        </tr>
                        <tr>
                            <td>IK:</td><td>{selectedEinrichtung.ik}</td>
                            <td className="td_label_right">Vorherige IK:</td><td>{selectedEinrichtung.ikalt}</td><td></td>
                        </tr>
                    </>
                    }                
                    <tr className="table-blank-row"></tr>
                    <tr><td colSpan="12"><hr></hr></td></tr>
                    <tr className="table-blank-row"></tr> 
                </tbody>
            </table>
        </div>

        <div className="sm_table_div">
            <table className="sm_table_90">
                <thead>
                    <tr>
                        <th align="left">Massnahmen</th>    
                        <th colSpan="2">
                            <button className="exportbtn" onClick={neueMassnahmeClick} disabled={!canEdit}>
                                <ConfigIcon><HiOutlineDocumentAdd/></ConfigIcon>&nbsp;Neue Ma&szlig;nahme
                            </button>
                        </th>                    
                    </tr>
                </thead>
                <tbody>
                    { selectedMassnahmen && 
                    <>
                        <tr>                            
                            <SortableTableHeader onClick={columnclick} title='ID' lastSort={lastSort} sortOrder={sortOrder} />
                            <SortableTableHeader onClick={columnclick} title='Vorname' lastSort={lastSort} sortOrder={sortOrder} />                            
                            <SortableTableHeader onClick={columnclick} title='Nachname' lastSort={lastSort} sortOrder={sortOrder} />                            
                            <SortableTableHeader onClick={columnclick} title='Von' lastSort={lastSort} sortOrder={sortOrder} />
                            <SortableTableHeader onClick={columnclick} title='Bis' lastSort={lastSort} sortOrder={sortOrder} />                            
                            <SortableTableHeader onClick={columnclick} title='%' lastSort={lastSort} sortOrder={sortOrder} />  
                            <SortableTableHeader onClick={columnclick} title='Monate' lastSort={lastSort} sortOrder={sortOrder} />
                            <SortableTableHeader onClick={columnclick} title='Fachgebiet' lastSort={lastSort} sortOrder={sortOrder} />                                                        
                            <SortableTableHeader onClick={columnclick} title='Status' lastSort={lastSort} sortOrder={sortOrder} />                                                        
                            <th>Ansprechpartner</th>
                            <th>Auszahlung</th>   
                        </tr>                    
                        { selectedMassnahmen.length > 0 && 
                            selectedMassnahmen.map( (
                                {  index, mid, nachname, vorname, 
                                   f_von, f_bis, prozentual, 
                                   monate, fachgebiet,
                                //    eingang, 
                                   status, ansprechpartner, auszahlvermerk 
                                }) => 
                            {
                                return (
                                <tr id={index} key={index} onClick={massnahmeClick} style={ (index===selectedMassnahmeIndex) ? {backgroundColor:'#DDD'} : {} }>                        
                                    <td className="td_label_center"><a href={"/Massnahme/Edit?mid=" + mid}>{mid}</a></td>
                                    <td className="td_label_center">{vorname}</td>
                                    <td className="td_label_center">{nachname}</td>                                
                                    <td className="td_label_center">{toShortDateString(f_von)}</td>
                                    <td>{toShortDateString(f_bis)}</td>
                                    <td className="td_label_right">{prozentual}</td>
                                    <td className="td_label_right">{monate}</td>
                                    <td className="td_label">{fachgebiet}</td>
                                    {/* <td>{eingang}</td> */}
                                    <td className="td_label">{status}</td>
                                    <td className="td_label_center">{ansprechpartner}</td>
                                    <td className="td_label_center">{auszahlvermerk}</td>
                                </tr>
                                )
                            })                        
                        }     
                        {selectedMassnahmen.length === 0 && <tr><td>Keine Ma&szlig;nahmen</td></tr> }
                    </>               
                    }
                    <tr className="table-blank-row"></tr>
                    <tr><td colSpan="12"><hr></hr></td></tr>
                    <tr className="table-blank-row"></tr>
                    <tr className="table-blank-row"></tr>
                </tbody>
            </table>
        </div>

        <div className="sm_table_div">
            <table>
                <tbody>
                <tr>

                    <td className="td_button">
                        <button className="exportbtn" onClick={briefClick} disabled={!canEdit} >
                            <ConfigIcon>
                                <AiOutlineFileWord/>
                            </ConfigIcon>&nbsp;Brief                            
                        </button>
                    </td>
                    <td className="td_button">
                        <button className="exportbtn"onClick={emailClick} disabled={!canEdit}>
                            <ConfigIcon>
                                <AiOutlineMail/>
                            </ConfigIcon>&nbsp;E-Mail                            
                        </button>
                    </td>
                    <td className="td_button">
                        <button className="exportbtn" onClick={teilnahmeBestaettigungClick}  disabled={!canEdit}>
                            <ConfigIcon>
                                <AiOutlineFileWord/>
                            </ConfigIcon>&nbsp;Teilnahmebest&auml;tigung
                        </button>                    
                    </td>
                    <td className="td_button">
                        <button className="exportbtn" onClick={bestaetigungsSchreibenClick}  disabled={!canEdit}>
                            <ConfigIcon>
                                <AiOutlineFileWord/>
                            </ConfigIcon>&nbsp;Best&auml;tigungsschreiben
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <NonModal id={nonmodalid} type='yesno' title='Druckbestätigung' message={printmessage} callback={documentprint_callback} data={printid}></NonModal>
        </div>
    </div>
    )
}

export default MassnahmenProTeilnehmerListe;