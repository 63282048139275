import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';

import { toShortDateString } from '../../util/date-utils';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';

const RechnungsListePageOffenePosten = props => 
{

  const [sortOrder , setSortOrder] = useState(false)
  const [lastSort , setLastSort] = useState(null) 
  const [rdata, setRdata] = useState(null)

  useEffect(() => {
    async function filterData()
    {
      if (props.data) {
        // console.log("DATA LENGTH " + props.data.length)
        const rd = []
        props.data.forEach(element => {
          if (element.status === 3 || element.status === 11 || element.status === 5) {
            rd.push(element)
          }                    
        });
        console.log("FILTER LENGTH Offene Posten " + rd.length)
        setRdata(rd)
      }
    }
    filterData()
  }, [props.data])
  
 
  const columnclick = (evt) => {        
    const txt = evt.currentTarget.innerText.trim()       
    // console.log("'" + txt + "'")
    if (lastSort !== txt) {
        setSortOrder(false)
        setLastSort(txt)
    }
    let idx = null;
    
    switch (txt) 
    {
        case 'Status' : idx = 'status'; break;
        case 'MID' : idx = 'id'; break;
        case 'Prozentual': idx = 'prozentual'; break;
        case 'Fachrichtung': idx = 'fachId'; break;
        case 'Von' : idx = 'f_von'; break;
        case 'Bis' : idx = 'f_bis'; break;
        case 'Verändert' : idx = 'BetragBerechnetIstNeu'; break;
        // case 'Vermerk' : idx = 'auszahlvermerk'; break;
        // case 'Verwendungszweck' : idx = 'Verwendungszweck'; break;  
        default : idx = null; break;
    }
    if (idx)
    {
        // console.log("sort by column " + idx)
        rdata.sort( (a, b) => {  
            if (a[idx] < b[idx]) {
                return (sortOrder ? -1 : 1);
            } else if(a[idx] > b[idx]) {
                return (sortOrder ? 1 : -1);
            }
            return 0;
        })
        setSortOrder(!sortOrder)            
        setRdata(rdata)
    }
  }
 
  return (
    <div>
    { rdata && <table className="sm_table">
        { (rdata.length === 0) && <thead>
            <tr><th>Keine Datensätze gefunden</th></tr>
        </thead> 
        }
        { (rdata.length > 0) && <thead>
            <tr>
              <th colSpan={7}></th>
              <th>Betrag</th>
              <th>Betrag</th>
              <th>Betrag</th>
            </tr>
            <tr>
                <SortableTableHeader onClick={columnclick} title='MID' lastSort={lastSort} sortOrder={sortOrder} />
                <SortableTableHeader onClick={columnclick} title='Status' lastSort={lastSort} sortOrder={sortOrder} />
                <SortableTableHeader onClick={columnclick} title='Prozentual' lastSort={lastSort} sortOrder={sortOrder} />
                <SortableTableHeader onClick={columnclick} title='Fachrichtung' lastSort={lastSort} sortOrder={sortOrder} />
                <SortableTableHeader onClick={columnclick} title='Von' lastSort={lastSort} sortOrder={sortOrder} />
                <SortableTableHeader onClick={columnclick} title='Bis' lastSort={lastSort} sortOrder={sortOrder} />
                <SortableTableHeader onClick={columnclick} title='Verändert' lastSort={lastSort} sortOrder={sortOrder} />

                {/* <th className="th_sortable" onClick={clickSort}>MID{lastSort === 'MID' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>                            
                <th className="th_sortable" onClick={clickSort}>Status{lastSort === 'Status' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                <th className="th_sortable" onClick={clickSort}>Prozentual{lastSort === 'Prozentual' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>                        
                <th className="th_sortable" onClick={clickSort}>Fachrichtung{lastSort === 'Fachrichtung' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                <th className="th_sortable" onClick={clickSort}>Von{lastSort === 'Von' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                <th className="th_sortable" onClick={clickSort}>Bis{lastSort === 'Bis' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th>
                <th className="th_sortable" onClick={clickSort}>Verändert{lastSort === 'Verändert' && (sortOrder ? <IoIosArrowRoundUp/> : <IoIosArrowRoundDown/>)}</th> */}
                <th>Daten</th>
                <th>Berechnet</th>
                <th>Bestaetigt</th>
            </tr>
        </thead>
        }
        <tbody>                  
        {
            rdata.map( ({ id, status, Massnahmenstatus,
                BetragBerechnet, BetragBestaetigt, BetragBerechnetNeu, BetragBerechnetIstNeu, f_von, f_bis,
                fachId,  Fachgebiete, prozentual
              })  => 
            {                                
                return <tr className="liste" key={id}>
                    <td className="td_fitwidth"><a href={`/Massnahme/Edit?mid=${id}`}>{id}</a></td>  
                    <td className="td_fitwidth">{Massnahmenstatus.kurzbezeichnung}</td>                        
                    <td className="td_fitwidth">{prozentual}</td>
                    <td className="td_fitwidth">{Fachgebiete.bezeichnung}</td>
                    <td className="td_fitwidth">{toShortDateString(f_von)}</td>
                    <td className="td_fitwidth">{toShortDateString(f_bis)}</td>
                    <td className="td_fitwidth">{BetragBerechnetIstNeu ? 'ja' : 'nein'}</td>                                
                    <td className="td_fitwidthR">{(BetragBerechnet ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})}</td>
                    <td className="td_fitwidthR">{(BetragBerechnetNeu ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})}</td>
                    <td className="td_fitwidthR">{(BetragBestaetigt ?? 0).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits : 2})}</td> 
                </tr>
            })
        }
        <tr className="table-blank-row"><td></td></tr>
        </tbody>
      </table>             
    } 
    </div>
  )
}

RechnungsListePageOffenePosten.propTypes = {
  data : PropTypes.arrayOf(
    PropTypes.shape({
      status : PropTypes.number
    })
  )
}

export default RechnungsListePageOffenePosten