import React from 'react'; // , { ResourceProvider, Resource } 
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useHistory } from 'react-router-dom';

import { authenticationService } from "./services/";
import logo from './Deutsche_Krankenhausgesellschaft_logo.svg';
import './App.css';

// import PrivateRoute from './components/shared/private-route';


import { I18nProvider, LOCALES } from './i18n';

import StartPage from './pages/start';
import LoginPage from './pages/login';
import DoublettenSuche from './pages/doubletten/DoublettenSuche';
import SearchEinrichtung from './pages/search-einrichtung/';
import { SearchMassnahme, } from './pages/search-massnahme';

import SearchTeilnehmer from './pages/search-teilnehmer';
// import TestMaterialTable  from './pages/start/test/TestMaterialTable';
// import { TestMUIDataTable } from './pages/start/test/TestMUIDataTable';
import EditTeilnehmer from './pages/edit-teilnehmer/EditTeilnehmer';
import MassnahmenProTeilnehmerListe from './pages/massnahmen-pro-teilnehmer/MassnahmenProTeilnehmerListe';
import MassnahmenProTeilnehmer from './pages/massnahmen-pro-teilnehmer/MassnahmenProTeilnehmer';
import MassnahmenProEinrichtung from './pages/massnahmen-pro-einrichtung/MassnahmenProEinrichtung';
import PrintJobs from './pages/printjobs/Printjobs';
import Kuerzungen from './pages/edit-massnahme/Kuerzungen';

import EditMassnahme from './pages/edit-massnahme/EditMassnahme';
import EditEinrichtung from './pages/edit-einrichtung/EditEinrichtung';
import { AppContext } from './context/AppContext';
import KontenEdit from './pages/konten/KontenEdit';
import AnsprechpartnerEdit from './pages/anprechpartner/AnsprechpartnerEdit';
import BeschaeftigungEdit from './pages/beschaeftigung/BeschaeftigungEdit';
import Berichte from './pages/berichte/Berichte';
// import DropdownMenu from './components/shared/dropdown-menu/DropdownMenu';
import DropdownMenuHoverable from './components/shared/dropdown-hoverable/DropdownMenuHoverable';
// import { DropdownMenuHoverable } from 'guicommon';
import ZahlungenOverview from './pages/zahlungslaeufe/ZahlungenOverview';
import ZahlungenAktuell from './pages/zahlungslaeufe/ZahlungenAktuell';
import KontrollListenNachweisBestaetigt from './pages/zahlungslaeufe/KontrollListenNachweisBestaetigt';
import KontrollListenRechnungGestellt from './pages/zahlungslaeufe/KontrollListenRechnungGestellt';
import RechnungsListe from './pages/zahlungslaeufe/RechnungsListe';
import MassnahmenListe from './pages/zahlungslaeufe/MassnahmenListe';
import EditKuerzungNachzahlung from './pages/admin-edit/EditKuerzungNachzahlung';
import EditVerrechnung from './pages/admin-edit/EditVerrechnung';
import EditVorlagen from './pages/admin-edit/EditVorlagen';
import Hilfe from './pages/hilfe/Hilfe';
import EtikettenPreview from './pages/etikett/EtikettenPreview';
import Tickets from './pages/tickets/Tickets';
import Antrag from './antraege/Antrag';

import { version } from '../package.json';
import EditAnsprechpartnerListe from './pages/admin-edit/EditAnsprechpartnerListe';
// import EtikettenPrint from './pages/etikett/EtikettenPrint';


const App = () => {

  // const authService = authenticationService.getInstance();  
  const menuitemsneu = [
    { title: 'Maßnahme', link: '/Massnahme/New' },
    { title: 'Einrichtung', link: '/Einrichtung/New' },
    { title: 'Teilnehmer', link: '/Teilnehmer/New' },
    { title: 'Etiketten', link: '/EtikettenPreview', target:'EtikettenPreview'},
  ]

  const menuitemssuche = [
    { title: 'Maßnahme' , link: '/SearchMassnahme' },
    { title: 'Einrichtung', link: '/SearchEinrichtung' },
    { title: 'Teilnehmer', link: '/SearchTeilnehmer' },
    { title: 'Maßnahmen pro Einrichtung' , link : '/MassnahmenProEinrichtung' },
    { title: 'Maßnahmen pro Teilnehmer', link: '/MassnahmenProTeilnehmer', target:'MassnahmenProTeilnehmer'},
    { title: 'Druckaufträge', link: '/Printjobs'},
    { title: 'Kürzungen nach Zahlung', link: '/Kuerzungen'},
  ]

  const menuitemszahlungslauf = [
    { title :'Aktuell', link : '/Zahlungslauf/Aktuell'},
    { title :'Übersicht', link : '/Zahlungslauf/Overview'},
  ]

  const menuitemsadmin = [
    { title: 'Ansprechpartner', link : '/Admin/Ansprechpartner'},
    { title: 'Vorlagen', link :'/Admin/Vorlagen'}
  ]

  const H = useHistory()

  window.onpopstate = () => {
    console.log("History State " + H.state)
  }

  function withoutHeader () {
    // console.log("pathname " + window.location.pathname)
    // console.log(window.location.pathname === '/Hilfe')
    return (window.location.pathname !== '/Hilfe/') && 
      (window.location.pathname !== '/EtikettenPrint')
  }

  function testSystemName() {
    console.log(version)
    if (version && version.length > 0) {
      const versionParts = version.split(".")      
      const rev = Number.parseInt(versionParts[versionParts.length-1])
      if (!Number.isNaN(rev) && ((rev % 2) ===1) ) {
        return " Test System "
      }
    }
    return ""    
  }

  return (
    <I18nProvider locale={LOCALES.GERMAN}>      
      <Router>
        <AppContext.Provider value="BLA">
          <div className="App">            
            { withoutHeader() && 
            <header className="App-header" 
              style={{background: authenticationService.isAdmin ? '#FF0000' : 'default'}}        
            >              
              <table className="sm_table">
                <thead></thead>
                <tbody>
                  <tr>                    
                    {
                    authenticationService.loggedIn && (                      
                    <>                    
                    <td>
                      <Link to="/">
                        <img src={logo} className="App-logo" alt="logo" height="50px" />  
                      </Link>
                    </td>
                    {/* DROP DOWN */}                    
                    <td>
                      <DropdownMenuHoverable menuname="Suche" menuitems={menuitemssuche}/>&nbsp;
                    </td>
                    { authenticationService.currentUser.category > 0 &&
                      <td >
                        <DropdownMenuHoverable menuname="Neu" menuitems={menuitemsneu}/>&nbsp;
                      </td>
                    }
                    { authenticationService.currentUser.category > 0 &&
                      <td>
                        <DropdownMenuHoverable menuname="Zahlungsläufe" menuitems={menuitemszahlungslauf}/>&nbsp;
                      </td>
                    }
                    <td>
                      <DropdownMenuHoverable menuname="Berichte" onClick={berichteClicked} />&nbsp;
                    </td>
                    { authenticationService.isAdmin &&
                      <td>
                        <DropdownMenuHoverable menuname="Admin" menuitems={menuitemsadmin}/>&nbsp;
                      </td>
                    }
                    <td width="40%"></td>
                    {authenticationService.isAdmin && <td align="right"> 
                        <button onClick={ticketClicked}>Tickets</button>
                      </td>
                    }                                       
                    {authenticationService.isAdmin && <td align="right"> 
                        <button onClick={antragClicked}>Antrag</button>
                      </td>
                    }                    
                    <td align="right">
                      <button onClick={helpClicked}>
                        Hilfe
                      </button>
                    </td>
                    <td align="right"> 
                      {/* width="60%"> */}
                      <DropdownMenuHoverable className="logout" menuname={`Logout ` + authenticationService.userName} onClick={logoutClicked}/>&nbsp;
                    </td>                   
                    </>)
                    }
                    {
                    !authenticationService.loggedIn && (
                      <td className="td_label_center"><h1 style={{color:'#fff'}}>Service Login{testSystemName()}</h1></td>
                    )                    
                    }
                  </tr>
                </tbody>
              </table>
            </header> }     
            <div className="container">  
              {/* { console.log("APP.js " + JSON.stringify(authenticationService.currentUser)) }
              { console.log("APP.js " + authenticationService.loggedIn) } 
              { console.log("History " + JSON.stringify(H.location)) } */}              
              <Switch>              
                <Route path="/login/" component={LoginPage}></Route>
                { 
                  authenticationService.loggedIn && (
                    <>
                    <Route path="/" exact component={StartPage}></Route>  
                    <Route path="/Doubletten" component={DoublettenSuche}></Route>
                    <Route path="/SearchEinrichtung" component={SearchEinrichtung}></Route>
                    <Route path="/SearchMassnahme" component={SearchMassnahme} ></Route>
                    <Route path="/SearchTeilnehmer" component={SearchTeilnehmer}></Route>
                    <Route path="/MassnahmenProEinrichtung" component={MassnahmenProEinrichtung}></Route>
                    <Route path="/MassnahmenProTeilnehmer" component={MassnahmenProTeilnehmer}></Route>
                    <Route path="/PrintJobs" component={PrintJobs}></Route>
                    <Route path="/Kuerzungen" component={Kuerzungen}></Route>
                    {/* <Route path="/TestMaterialTable" component={TestMaterialTable}></Route> */}
                    {/* <Route path="/TestMuiTable" component={TestMUIDataTable}></Route> */}
                    <Route path="/Teilnehmer/Edit" component={EditTeilnehmer}></Route>
                    <Route path="/Teilnehmer/New" component={EditTeilnehmer}></Route>
                    <Route path="/Teilnehmer/Overview" component={MassnahmenProTeilnehmerListe}></Route>
                    
                    <Route path="/Massnahme/Edit" component={EditMassnahme}></Route>
                    <Route path="/Massnahme/New" component={EditMassnahme}></Route>
                    <Route path="/Einrichtung/Edit" component={EditEinrichtung}></Route>
                    <Route path="/Einrichtung/New" component={EditEinrichtung}></Route>
                    <Route path="/Konto/Edit" component={KontenEdit}></Route>
                    <Route path="/Konto/New" component={KontenEdit}></Route>
                    <Route path="/Ansprechpartner/Edit" component={AnsprechpartnerEdit}></Route>
                    <Route path="/Ansprechpartner/New" component={AnsprechpartnerEdit}></Route>
                    <Route path="/Beschaeftigung/Edit" component={BeschaeftigungEdit}></Route>
                    <Route path="/Beschaeftigung/New" component={BeschaeftigungEdit}></Route>
                    <Route path="/Berichte" component={Berichte}></Route>
                    <Route path="/Zahlungslauf/Aktuell" exact component={ZahlungenAktuell}></Route>
                    <Route path="/Zahlungslauf/Aktuell/KontrollListenNachweis" component={KontrollListenNachweisBestaetigt}></Route>
                    <Route path="/Zahlungslauf/Aktuell/KontrollListenRechnung" component={KontrollListenRechnungGestellt}></Route>
                    <Route path="/Zahlungslauf/Aktuell/RechnungsListe" component={RechnungsListe}></Route>
                    <Route path="/Zahlungslauf/Overview" component={ZahlungenOverview}></Route>
                    <Route path="/Massnahmen/Liste" component={MassnahmenListe}></Route>
                    <Route path="/KuerzungNachzahlung/Edit" component={EditKuerzungNachzahlung}></Route>
                    <Route path="/Verrechnung/Edit" component={EditVerrechnung}></Route>
                    <Route path="/Hilfe" component={Hilfe}></Route>
                    <Route path="/EtikettenPreview" component={EtikettenPreview}></Route>
                    {authenticationService.isAdmin && <Route path="/Antrag" component={Antrag}></Route>}
                    {authenticationService.isAdmin && <Route path="/Tickets" component={Tickets}></Route>}
                    {authenticationService.isAdmin && <Route path="/Admin/Ansprechpartner" component={EditAnsprechpartnerListe}></Route>}
                    {authenticationService.isAdmin && <Route path="/Admin/Vorlagen" component={EditVorlagen}></Route>}
                    {/* <Route path="/EtikettenPrint" component={EtikettenPrint}></Route> */}

                    </>
                  )
                }
                {! authenticationService.loggedIn && 
                  (<Redirect to='/login/'/>)
                }           
              </Switch>                 
              </div>        
              {/* <PrivateRoute exact path="/" component={StartPage} /> */} 
            </div> 
          <div/>
        </AppContext.Provider>
      </Router>    
    </I18nProvider>
  );
}

const logoutClicked = async () => {
   await authenticationService.logout();
   redirectPage();   
}

const helpClicked = async () => {
  // console.log("Help Clicked")
  // console.log(window.location.host)
  // console.log(window.location.hostname)
  // console.log(window.location.port)
  // console.log(window.location.protocol)
  // console.log(window.location.search)
  let path = window.location.pathname.replaceAll("/", "_")  
  if (path.startsWith("_") && path.length > 1) {
    path = path.substring(1)
  }  
  const target = 'Hilfe#' + path
  // const target = 'Hilfe/index.js#' + path 
  // const target = 'data/help/#' + path
  // const target = window.location.protocol + '//' + window.location.hostname + ':8000/help/' + '#' + path
  window.open(target,'DKG Hilfe');  
}

const antragClicked = async () => {
  const target = 'Antrag'
  window.open(target, 'Antrag')
}

const ticketClicked = async () => {
  const target = 'Tickets'
  window.open(target, 'Tickets')
}

const redirectPage = () => {
  window.location.replace("/")
}

const berichteClicked = () => {
  window.location = "/Berichte"
}

export default App;

// <SearchMassnahme></SearchMassnahme>