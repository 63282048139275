import  { DateTime, Interval } from 'luxon';
import { Strings } from './strings';
import Values from './Values';

class Dates
{
    static toIsoDateString(date)
    {        
        let d1 = null

        if (!date) {
            d1 = new Date()
        } else if (typeof(date) === 'number') {            
            d1 = new Date(date)            
        } else if (typeof(date) === 'string') {
            d1 = new Date(Date.parse(date))
        } else if (typeof(date) === 'object') {            
            d1 = date
        } else {
            console.log(date + " unknown type")
        }
        if (d1) {            
            const result = "" + 
                Strings.padLeft(d1.getFullYear(), "0", 4) + "-" + 
                Strings.padLeft((d1.getMonth()+1), "0", 2) + "-" + 
                Strings.padLeft(d1.getDate(), "0", 2)
            // console.log("RESULT: " + result)
            return result;
        }        
        return date
    }

    static monthDiff(jsDateVon, jsDateBis)
    {        
        // console.log("jsDateVon date " + JSON.stringify(jsDateVon))
        // console.log("jsDateBis date " + jsDateBis)

        // const fv = new Date(jsDateVon.getFullYear(), jsDateVon.getMonth(), jsDateVon.getDate())
        // const fb = new Date(jsDateBis.getFullYear(), jsDateBis.getMonth(), jsDateBis.getDate()+1)   
        // console.log("fv " + fv)
        // console.log("fb " + fb)
        let head = 0
        let tail = 0

        if (
            (jsDateVon.getDate() > 1) && (
                (jsDateVon.getFullYear() !== jsDateBis.getFullYear()) || 
                (jsDateVon.getMonth() !== jsDateBis.getMonth()))) 
        {
            // console.log("Startdatum nach dem 1. (" + jsDateVon.getDate() + ")" )
            head = Dates.monthDiff(jsDateVon, Dates.lastDateInMonth(jsDateVon))
            // console.log("head = " + head);
            jsDateVon = new Date(jsDateVon.getFullYear(), jsDateVon.getMonth()+1, 1)
        }
        const lastDayInMonth = new Date(jsDateBis.getFullYear(), jsDateBis.getMonth(), 0).getDate()
        if ( (jsDateBis.getDate() !== lastDayInMonth) && (
            (jsDateVon.getFullYear() !== jsDateBis.getFullYear()) ||
            (jsDateVon.getMonth() !== jsDateBis.getMonth())))
        {
            // console.log("Enddatum vor dem ende des Monats. (" + jsDateBis.getDate() + ")" )
            tail = Dates.monthDiff(new Date(jsDateBis.getFullYear(), jsDateBis.getMonth(),1), jsDateBis)
            // console.log("tail = " + tail);
            jsDateBis = new Date(jsDateBis.getFullYear(), jsDateBis.getMonth(), 0)
        }
        
        const fv = DateTime.utc(jsDateVon.getFullYear(), jsDateVon.getMonth()+1, jsDateVon.getDate())
        const tmp = DateTime.utc(jsDateBis.getFullYear(), jsDateBis.getMonth()+1, jsDateBis.getDate())   
        const fb = tmp.plus( { days:1} );     
        // console.log("Lux in von " + fv)
        // console.log("Lux on bis " + fb)
        const diff = Interval.fromDateTimes(fv, fb)        
                // console.log("Duration " + diff.length("months")) //.count(Luxon.Duration.months))
        // console.log("Duration " + diff.length("days"))
        return diff.length("months") + head + tail;
    }

    static lastDateInMonth(jsDate)
    {
        return new Date(jsDate.getFullYear(), jsDate.getMonth()+1, 0);
    }

    static dateAdd(jsDate, what, anzahl) 
    {
        const tmp = DateTime.utc(jsDate.getFullYear(), jsDate.getMonth()+1, jsDate.getDate())   
        let fb
        if (what === "d") {        
            fb = tmp.plus( { days:anzahl } );   
        }
        else if (what === "m") {
            fb = tmp.plus( { months:anzahl })            
        } 
        else {
            throw new Error("not implemented")
        }
        return fb.toJSDate()
    }

    static dateSub(jsDate, what, anzahl) 
    {
        const tmp = DateTime.utc(jsDate.getFullYear(), jsDate.getMonth()+1, jsDate.getDate())   
        let fb
        if (what === "d") {        
            fb = tmp.minus( { days:anzahl } );   
        }
        else if (what === "m") {
            fb = tmp.minus( { months:anzahl })            
        } 
        else {
            throw new Error("not implemented")
        }
        return fb.toJSDate()
    }

    static toValidDate(date)
    {
        if (this.isvalid(date))
        {
            return date;

        }        
        return null; // return (d && d !== 'Invalid Date') ? d : null;        
    }

    static isvalid(date) 
    {    
        if (this.testNullOrInvalid(date)) {
            // console.log("data valid1 " + date + " " + typeof(date) + " " + false)
            return false;
        }
        const d = new Date(date);
        if (this.testNullOrInvalid(d)) {
            // console.log("data valid2 " + d + " " + typeof(d) + " " + false)
            return false;
        }
        // console.log("data valid2 " + d + " " + typeof(d) + " " +  true)
        return true
    }

    static testNullOrInvalid(date) {
        if ( (date === null) || (date.toString() === Values.INV_DATE)) {
            return true;
        }
        return false;
    }
}

export default Dates;