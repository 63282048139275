import PropTypes from 'prop-types'
import './horizontaltabs.css'

function HorizontalTabs(props) 
{

    // aufgerufen vom Hauptfenster zum Öffnen des Tabreiters 'pagename'
    function handleAction(pagename, cmd)
    {
        // console.log("handleAction(pagename, cmd) " + pagename + " " + cmd)
        if (pagename) {
            switch (cmd) {
                case 'edit':                    
                    openTabPage(pagename)
                    return;
                default:
                    throw new Error("Not implemented")
            }
        }               
    }

    // Dem Hauptfenster die Function zur Tabselection bekanntmachen
    props.setAction( handleAction )

    // Intern aufgerufen beim Click auf einen Tabreiter
    function openTabPage(pageName)
    {
        // console.log("openTabPage(pagename) " + pageName)
        
        var i, tabcontent, tablinks;
      
        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
          tabcontent[i].className = tabcontent[i].className.replace(" active", "");
        }
      
        // Get all elements with class="tablinks" and remove the class "active"
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }        
      
        // Show the current tab, and add an "active" class to the button that opened the tab
        const html = document.getElementById(stripblanks(pageName))
        if (html) {
            // console.log(html)
            html.className += " active";
            html.style.display = "block";
        }
        const btn = document.getElementById('btn' + stripblanks(pageName))
        if (btn) {
            // console.log(btn)
            btn.className += " active";
        }
    }
    
    const openTab = ((evt) => 
    {
        // console.log("opencity")
        // Declare all variables
        const cityName = evt.currentTarget.getAttribute('cityname');
        openTabPage(cityName, evt.currentTarget)
    } )

    function stripblanks(text) {
        return text.replace(" ", "_")
    }

    return (
        <>
            <div className="horizontaltab">
                { 
                    props.tabitems && props.tabitems.map( ({ title }) => 
                    {    
                        return (<button key={'btn' + stripblanks(title)} id={'btn' + stripblanks(title)} className="tablinks" onClick={openTab} cityname={title}>{title}</button>)
                    })
                }               
            </div>
            {
                props.tabitems && props.tabitems.map( (item) => 
                {                    
                    return <div key={stripblanks(item.title)} id={stripblanks(item.title)} className="tabcontent">{item.component(
                        { 
                            data : item.data, 
                            func : props.getDisabled,  
                            callback : props.callback,                         
                        }
                        )}
                        </div>
                })
            }           
        </>
    )
}

HorizontalTabs.propTypes = {
    setAction : PropTypes.func,
    getDisabled : PropTypes.func,
    callback : PropTypes.func,
    tabitems : PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            component: PropTypes.func            
        })       

    )



}

export default HorizontalTabs

