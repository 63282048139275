import React, { useEffect, useState } from 'react'
import MaskedInput from 'react-maskedinput'
import { AiOutlineSave } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';

import ConfigIcon from '../../util/configicon';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '../../components/shared/form-group'
import LastEdit from '../../components/app/last-edit'
import { validateIban } from '../../util/validate-utils.js';
import FetchSerive from '../../services/FetchService';
import UpdateHandler from '../../util/UpdateHandler';


function KontenEdit( props ) {    
    
    const uri = new URL(window.location.href)
    const eid = uri.searchParams.get("eid");
    const kontoid = uri.searchParams.get("kontoid");

    const [ibanWarningText, setIbanWarningText] = useState(null)
    const [ibanStyle, setIbanStyle] = useState(null)

    const [einrichtungsName, setEinrichtungsName] = useState(null);    
    const [ik, setIk] = useState(null);
    const [blz, setBlz] = useState(null);
    const [iban, setIban] = useState("DE");    
    const [konto, setKonto] = useState(null);
    const [bic, setBic] = useState(null);
    const [inaktiv, setInaktiv] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [editTime, setEditTime] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {        
        async function asyncLoadKonto()
        {         
            // console.log("eid " + eid)
            // console.log("kontoid " + kontoid)  
            try {                
                const response = await fetch('/data/konten/' + eid + "/" + kontoid)
                if (response.ok) {                    
                    const result = await response.json();    
                    if (Array.isArray(result) && result.length > 0)
                    {                        
                        // result.id
                        // result.einrichtungen_id
                        setIk(result[0].ik)
                        setBlz(result[0].blz)
                        setKonto(result[0].kontonr)
                        const ib = result[0].iban
                        setAndCheckIbanValue(ib)
                        
                        setBic(result[0].bic)
                        // result.iban_isok
                        // result.bic_isok
                        setInaktiv(result[0].inaktiv)
                        setEditUser(result[0].LetzterBearbeiter)
                        setEditTime(result[0].LetzteAenderung)              
                        setEinrichtungsName("Konto der Einrichtung " +
                            result[0].Einrichtungen.kurzbezeichnung + ", " +
                            result[0].Einrichtungen.ort)         
                        // setIsLoaded(true)   
                       // const node = document.getElementById("iban");
                       // console.log("iban-node " + node.style.toString());    
                       // node.style = node.style['background'] = 'tomato'
                        return;
                    }                                     
                    throw new Error("Konto mit ID = " + kontoid + " nicht gefunden")                                    
                }
                else
                {                     
                   throw new Error(response.status + " " + response.statusText)
                }
            }
            catch (err) {                
                 console.log(err)
                 setErrorMessage(err)
            }              
        }
        if ((kontoid) && (eid)) {
            asyncLoadKonto()
        }
    }, [kontoid, eid])

    useEffect(() => {
        async function asyncLoadEinrichtung()
        {         
            // console.log("eid " + eid)
            // console.log("kontoid " + kontoid)  
            try {                                
                const response = await fetch('/data/einrichtungen/' + eid)
                if (response.ok) {   
                    const result = await response.json();
                    setEinrichtungsName("Neues Konto für die Einrichtung " +
                        result.kurzbezeichnung + ", " +
                        result.ort) 
                    setIk(result.ik)    
                    return;
                }
                throw new Error(response.status + " " + response.statusText)
            }
            catch (err) {                
                console.log(err)
                setErrorMessage(err)
           }              
        }
        if ((!kontoid) && (eid)) {
            asyncLoadEinrichtung()
        }        
    }, [kontoid, eid])
    
    const savedata = async () =>
    {   
        let msg = await validateIban(iban)        
        if (msg !== "OK")
        {
            alert("Fehler: " + msg)
            return;
        } 
        msg = await FetchSerive.saveKonto(kontoid, eid, ik, inaktiv, iban);
        if (!msg || !msg.startsWith("OK"))
        {
            alert("Fehler: " + msg)
            return;
        }         
        // window.location = document.referrer;
        UpdateHandler.updateStorageKTO(eid)
        window.history.back();        
    }

    const canceldata = () =>
    {
        window.history.back();
    }

    // const pasteIban = (e) => {
    //     console.log(e)
    // }

    const setAndCheckIban = async (target) =>
    {
        let value = target.value.replace(/\s+/g, '');
        setAndCheckIbanValue(value)
    }
    
    const setAndCheckIbanValue = async (value) =>
    {
        setIbanWarningText(null)
        setIban(value)     
                
        if (value && value.indexOf('_') === -1 && value.length === 22)
        {   
            const msg = await validateIban(value)
            if (msg !== "OK") {
                 setIbanStyle({'background':'tomato'})
                 setIbanWarningText(msg)
                 return false;
            }
        }
        setIbanStyle(null)
    }

    return (
        <>
        {
            (errorMessage) && <h3>{`${errorMessage}`}</h3>   
        }
        { 
            (!errorMessage) && <div className="sm_table_div"> 
            <table> 
                <thead>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <th colSpan="6"><h3>{`${einrichtungsName}`}</h3></th>                      
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td className="td_label">IK dieses Kontos:</td>
                        <td colSpan="2" className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="ik"                             
                                value={ik} 
                                onChange={setIk} 
                                readonly
                            />                          
                        </td>
                        <td></td>                        
                        <td width="20px"></td>
                        <td className="td_label">BLZ (obsolete):</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="blz"                             
                                value={blz} 
                                onChange={setBlz} 
                                readonly
                            />                          
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">IBAN:</td>
                        {/* <td colSpan="2" className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="iban"                             
                                value={iban} 
                                onChange={setIban}                              
                            />                     
                        </td> */}

                        <td colSpan="2" className="td_input">
                        {/* 1 - number
                        a - letter
                        A - letter, forced to upper case when entered
                        * - alphanumeric
                        # - alphanumeric, forced to upper case when entered */}
                        <Tooltip 
                            title={ibanWarningText == null ? "" : ibanWarningText}  
                            id='ibantt'
                            placement='right'
                            arrow={true}
                            // wenn man HTML mit Interaktionen (Button, href) als Tooltip hat                            
                            // interactive={!warningText}
                            // leaveDelay='1000'
                            
                        >
                            <MaskedInput  
                                className='td_maskinput'                               
                                style={ibanStyle} 
                                mask="AA11 1111 1111 1111 1111 11"                                 
                                name="iban" 
                                id="iban"
                                size="39" 
                                value={iban} 
                                onChange={(e) => setAndCheckIban(e.target)}                                 
                            />   
                        </Tooltip>                     
                        </td>


                        <td></td>
                        <td width="20px"></td>
                        <td className="td_label">Konto (obsolete):</td>
                        <td className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="konto"                             
                                value={konto} 
                                onChange={setKonto}       
                                readonly                       
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="td_label">BIC (obsolete):</td>
                        <td colSpan="2" className="td_input">
                            <FormGroup 
                                type="text"
                                label=""
                                name="bic"                             
                                value={bic} 
                                onChange={setBic}       
                                readonly                       
                            />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>                        
                    </tr>
                    <tr>
                        <td className="td_label">Inaktiv:</td>
                        <td colSpan="2">
                            <FormGroup
                                type="checkbox" label="" 
                                value={inaktiv} 
                                onChange={setInaktiv} 
                            />
                        </td>
                        <td></td><td></td><td></td><td></td><td></td>
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr>
                    
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <LastEdit edituser={editUser} edittime={editTime}></LastEdit>
                        </td>
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr>
                        <td className="td_label">
                            <button className="exportbtn" title="Änderungen speichern" onClick={savedata}>                                
                                <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                            </button>                                
                        </td>                        
                        <td >
                            <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>                                
                                <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                            </button>    
                        </td>           
                    </tr>
                </tbody>
            </table>            
        </div>
        }
        </>
    )
}

export default KontenEdit
