import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AiOutlineSave } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';

import { toShortDateString } from '../../util/date-utils';
import FormGroup from '../../components/shared/form-group';
import Accordion from '../../components/shared/accordion/Accordion';
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';
import ColumnSorter from '../../util/ColumnSorter';
// import { Accordion, SortableTableHeader, SortableTableData, ColumnSorter } from 'guicommon';
import TicketKommentare from './TicketKommentare';
import TicketHistory from './TicketHistory';
import TicketDocuments from './TicketDocuments';
import ConfigIcon from '../../util/configicon';

// import { Dates } from 'dkgcommon';

const Tickets = props => {

    // console.log(Dates.INV_DATE)

    const [errormessage, seterrormessage] = useState(null)
    const [tickets, settickets] = useState(null)
    const [selectedticketid, setselectedticketid] = useState(0)
    const [selectedticket, setselectedticket] = useState(null)

    const [statusoptions, setstatusoptions] = useState(null)
    const [priooptions, setpriooptions] = useState(null)
    const [typeoptions, settypeoptions] = useState(null)
    const [editmode, setEditmode] = useState(false)

    useEffect(() => 
    {
        async function loadTickets() {
            try 
            {                                
                const response = await fetch('/data/admin/redmine/issues/')
                if (response.ok) {
                    const result = await response.json();    
                    if (result.issues.length > 0) {
                        // console.log(result.issues)
                        settickets(result.issues) 
                        setColumnSorter(new ColumnSorter(result.issues, 
                            [
                                [ 'ID' , 'id'],
                                [ 'Art', 'tracker.name'],
                                [ 'Thema', 'subject'],
                                [ 'Status', 'status.name'],                                
                                [ 'Angelegt am', 'created_on'],
                                [ 'Erstellt von', 'author.name'],
                                [ 'Zugewiesen an', 'assigned_to.name'],
                                [ 'Geändert am', 'updated_on' ],
                                [ 'Priorität' , 'priority.name'],                                
                            ]
                        ))      
                        setSortOrder(false)                    
                        setLastSort(null)                              
                        seterrormessage("")        
                    }
                    else 
                    {
                        seterrormessage("No Tickets")
                        settickets(null)
                    }
                }
                else
                {
                    seterrormessage("" + response.status + " " + response.statusText)
                }
            }
            catch (err) {
                seterrormessage("" + err)
            }

        }
        loadTickets()    
    }, [])

    async function loadSelectOptions(path)
    {
        try 
        {                                
            const response = await fetch('/data/' + path)
            if (response.ok) {
                const result = await response.json();  
                return result; 
            }
            console.log(response.status + " "  + response.statusText)
        }
        catch (err) {
            console.log(""+err)
        } 
        return null
    }

    useEffect(() => {
        async function loadStatusOptions() {
            const opt = await loadSelectOptions('admin/redmine/issue_statuses')
            setstatusoptions(opt)            
        }
        loadStatusOptions()
    }, [])

    useEffect(() => {
        async function loadPrioOptions() {
            const opt = await loadSelectOptions('admin/redmine/issue_priorities')
            setpriooptions(opt)            
        }
        loadPrioOptions()
    }, [])

    useEffect(() => {
        async function loadTypeOptions() {
            const opt = await loadSelectOptions('admin/redmine/trackers')
            settypeoptions(opt)                        
        }
        loadTypeOptions()
    }, [])
    

    useEffect(() => 
    {             
        if (tickets && tickets.length>0) {
            const ticket = tickets.find( (item) => { return item.id === selectedticketid} )
            setselectedticket(ticket)            
            console.log(ticket)
        } 
        else {
            setselectedticket(null)
        }
    }, [selectedticketid])    

    const ticketClick = ((evt) => {    
        const idattribute = evt.target.parentElement.attributes['id']
        if (idattribute) {
            const val = Number.parseInt(idattribute.value)
            if (val !== selectedticketid) {
                setselectedticketid(val)
            }
        }
    })

    const savedata = () => {
        console.log("save data");

        setEditmode(false)
    }

    const canceldata = () => {
        console.log("cancel edit");

        setEditmode(false)
    }

    const adddata = () => {
        console.log("add new data");
        setEditmode(true)
    }

    const editdata = () => {
        console.log("edit data");
        setEditmode(true)
    }

    // const TicketHistory = () => {
    //     // console.log("History Selected TicketID " + selectedticketid)
    //     return (<h1>History</h1>)
    // }

    // const TicketDocuments = () => {
    //     // console.log("Documents Selected TicketID " + selectedticketid)
    //     return (<h1>Documents</h1>)
    // } 

    const [columnsSorter, setColumnSorter] = useState(null)    
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)
    
    const columnclick = (evt) => {
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {                
                settickets(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

  return (
    <div>
        {errormessage}
        {tickets && <div>
            <table className='sm_table'>
                <thead>
                    <tr>
                        <SortableTableHeader className='th_sortable' onClick={columnclick} title='ID' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>ID</th> */}
                        <SortableTableHeader className='th_sortable' onClick={columnclick} title='Art' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Art</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Thema' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th className='td_label'>Thema</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Status' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Status</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Angelegt am' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Angelegt am</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Erstellt von' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Erstellt von</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Zugewiesen an' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Zugewiesen an</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Geändert am' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Geändert am</th> */}
                        <SortableTableHeader className='td_label' onClick={columnclick} title='Priorität' lastSort={lastSort} sortOrder={sortOrder} />
                        {/* <th>Priorität</th> */}
                    </tr>
                </thead>
                <tbody>
                    { tickets.map( ( { id, subject, status, tracker, start_date, created_on, updated_on, 
                                    priority, author, assigned_to, estimated_hours, fixed_version, is_private,
                                    custom_fields } ) => {
                        return (
                            <tr id={id} key={id} onClick={ticketClick} style={ (id===selectedticketid) ? {backgroundColor:'#DDD'} : {} }>
                                <td>{id}</td>
                                {/* <td>{start_date}</td> */}
                                <td>{tracker.name}</td>
                                <td>{subject}</td>
                                <td>{status.name}</td>
                                <td>{toShortDateString(created_on)}</td>
                                <td>{author.name}</td>
                                <td>{assigned_to ? assigned_to.name : ''}</td>
                                <td>{toShortDateString(updated_on)}</td>
                                <td>{priority.name}</td>
                                <td>{estimated_hours}</td>
                                <td>{fixed_version ? fixed_version.name : ''}</td>
                                {/* <td>{new Boolean(is_private).toString()}</td>
                                { custom_fields.map ( (item) => {
                                    return <td>{item.name}</td>

                                })} */}
                            </tr>
                        )
                        })
                    }
                </tbody>
            </table>
            {selectedticket && <div className='sm_table_div'>
                <table className='sm_table_60'>
                    <colgroup>
                        <col style={{"width":"120px"}} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className='td_label'>Author:</td>
                            <td className='td_label'>{selectedticket.author.name}</td> 
                        </tr>
                        <tr>
                            <td className='td_label' >ID:</td>
                            <td className='td_label'>{selectedticket.id}</td>
                        </tr>
                        <tr>
                            <td className='td_label'>Art:</td>
                            <td className='td_select'>
                                <FormGroup
                                    type={'select'}
                                    options={typeoptions}
                                    value={selectedticket.tracker.id}
                                    empty={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='td_label'>Status:</td>
                            <td className='td_select'>
                                <FormGroup
                                    type={'select'}
                                    options={statusoptions}
                                    value={selectedticket.status.id}
                                    empty={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='td_label'>Priorität:</td>
                            <td className='td_select'>
                                <FormGroup
                                    type={'select'}
                                    options={priooptions}
                                    value={selectedticket.priority.id}
                                    empty={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='td_label'>Thema:</td>
                            <td className='td_label'>{selectedticket.subject}</td>
                        </tr>
                        <tr>
                            <td className='td_label'>Beschreibung:</td>
                            <td className='td_input'>
                                <FormGroup
                                    type={'textarea'}
                                    value={selectedticket.description}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button className='exportbtn' onClick={adddata} disabled={editmode}>
                                    &nbsp;Neu
                                </button>
                                &nbsp;
                                <button className='exportbtn' onClick={editdata} disabled={editmode}>                                    
                                    &nbsp;Bearbeiten
                                </button>
                                &nbsp;
                                <button className="exportbtn" title="Speichern des Tickets" onClick={savedata} disabled={!editmode}>
                                    <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                                </button>
                                &nbsp;                            
                                <button className="cancelbtn" title="Abbrechen" onClick={canceldata} disabled={!editmode}>
                                    <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                                </button>                            
                            </td>
                        </tr>
                    </tbody>
                </table>                
            </div>
            }      
            {selectedticket && <div>
                <Accordion 
                    title='Kommentare' 
                    display=''
                    notify= { (cmd, param) => { console.log (cmd + ", " + param)}}
                    getpanel= { () => { return (<TicketKommentare data={selectedticketid}></TicketKommentare>)}}
                />
                <Accordion
                    title='History'
                    display=''
                    notify= { (cmd, param) => {}}
                    getpanel= { () => { return (<TicketHistory data={selectedticketid}></TicketHistory>)}}
                />
                <Accordion
                    title='Dokumente'
                    display=''
                    notify= { (cmd, param) => {}}
                    getpanel= { () => { return (<TicketDocuments data={selectedticketid}></TicketDocuments>)}}
                />  
                
                {/* 
                Altes Accordion
                <Accordion title='Kommentare' getpanel={TicketKommentare} data={ selectedticketid }></Accordion>
                <Accordion title='History' getpanel={TicketHistory} data={ selectedticketid }></Accordion>
                <Accordion title='Dokumente' getpanel={TicketDocuments} data={ selectedticketid }></Accordion> 
                */}              
            </div>
            }
        </div>
        }
    </div>
  )
}

Tickets.propTypes = {}

export default Tickets