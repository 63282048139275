import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import HorizontalTabs from '../../components/shared/horizontal-tabs/HorizontalTabs';
import MGekuerzt from './MGekuerzt';
import MVerrechnet from './MVerrechnet';
import ZVerrechnet from './ZVerrechnet';

const Kuerzungen = (props) => {
    
    const tabitems = 
    [   
        { title : 'Zu Verrechnende Maßnahmen', component : ZVerrechnet, data: null },     
        { title : 'Gekürzte Maßnahmen', component : MGekuerzt, data : null },
        // JSON.stringify( [{ mid: result.id } , { aid: result.auszahlungen_id }] )  },
        { title : 'Verrechnete Maßnahmen', component : MVerrechnet, data : null},
        // JSON.stringify([{ mid : result.id } , { kid: result.kuerzungen_id }]  ) },
        

        // { title : 'Verrechnung', component : Verrechnung, data : null},
        // // JSON.stringify( [{ mid: result.id } , { vid: result.verrechnungen_id }] ) }, 
        // { title : 'Kürzung nach Zahlung', component: KuerzungNachZahlung, data : null },
        // // JSON.stringify( [{ mid: result.id } , { kid: result.kuerzungennachzahlung_id }] ) },
        // { title : 'Massnahme teilen', component : MassnahmeAufteilen, data : null }, 
        // //JSON.stringify( [{mid: result.id }] ) }
    ]

    let tabitemsActionFunc = null
    const tabitemsAction = (action) => { tabitemsActionFunc = action }
    const calcDisabled = (flags) => { }
    const tabitemsCallback = (pagename, cmd) => { }

    useEffect(() => {
        async function func()
        {
            if (tabitemsActionFunc) {                
                tabitemsActionFunc(tabitems[0].title, 'edit')
            }
        }
        func();
    }, [tabitemsActionFunc]);

  return (
    <div>
        <div className="data_table_div">
            <table className="data_table">
                <thead>
                    <tr className="table-blank-row"></tr>
                    {tabitems && (<tr>
                        <td colSpan="8"><HorizontalTabs 
                            tabitems={tabitems} 
                            setAction={tabitemsAction} 
                            callback={tabitemsCallback}
                            getDisabled={calcDisabled} /></td>
                    </tr>)
                    }
                </thead>
            </table>                
        </div>         
    </div>
    )
};

Kuerzungen.propTypes = {};

export default Kuerzungen;
