import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver';

import DragDropUploadForm from '../../components/shared/dndupload/DragDropUploadForm';
import { toShortDateTimeString } from '../../util/date-utils.js'
import SortableTableHeader from '../../components/shared/tables/SortableTableHeader';
import SortableTableData from '../../components/shared/tables/SortableTableData';
import ColumnSorter from '../../util/ColumnSorter';
import authenticationservice from '../../services/authenticationService';
import FormGroup from '../../components/shared/form-group/FormGroup';
import CHECKBOX_STATES from '../../components/shared/editor-controls/checkbox3-control/CHECKBOX_STATES';


const DokumenteEinerMassnahme = props => {

    const [mid, setmid] = useState(null)
    const [tid, settid] = useState(null)
    const [eid, seteid] = useState(null)
    const [aid, setaid] = useState(null)
    const [list, setlist] = useState(null)

    const [columnsSorter, setColumnSorter] = useState(null)    
    const [sortOrder , setSortOrder] = useState(false)
    const [lastSort , setLastSort] = useState(null)
    const [reload, setreload] = useState(false)
    const [einausgang, seteinausgang] = useState(CHECKBOX_STATES.Checked)

    async function load()
    {            
        const pd = JSON.parse(props.data)            
        // console.log(pd)
        setmid(pd.mid)
        settid(pd.tid)
        seteid(pd.eid)
        setaid(pd.aid)
        if (pd.mid) {                
            try {  
                let sel = ''
                switch (einausgang) {
                    case CHECKBOX_STATES.Empty:
                        sel = 'printed/'
                        break;
                    case CHECKBOX_STATES.Checked:
                        sel= 'uploaded/'
                        break;
                    case CHECKBOX_STATES.Indeterminate:
                        break;
                }                  
                const response = await fetch('/data/documents/list/' + sel + mid, { method: 'GET' })
                if (response.ok) {       
                    // console.log('response ok')                 
                    const result = await response.json();                     
                    setlist(result)
                    setColumnSorter(new ColumnSorter(result, 
                        [
                            ['Dateiname', 'filename'],
                            ['Erstellt am', 'created'],
                            ['Größe', 'Document.filesize'],
                            ['Typ', 'mimetype']
                        ]))
                    return;
                }
            }
            catch (err) {
                console.log(""+err)
            }
        }
        setlist(null)  
        setColumnSorter(null)
        setSortOrder(false)
        setLastSort(null)          
    }

    useEffect(() =>  { load() }, [reload])    
    useEffect(() =>  { load() }, [])

    const onDownload = ( async (e) => {
        e.preventDefault()
        const href = e.currentTarget.getAttribute('href')
        if (href && href.length > 1) {
            const docid = Number.parseInt( href.substring(1) )            
            if (!Number.isNaN(docid))
            {                
                const res = await fetch ("/data/documents/download/" + docid)                
                if (res.status === 200) {             
                    const json = await res.json()
                    // console.log(json)
                    const ar = new Uint8Array(json.Document.filedata.data)                            
                    // console.log (ar)
                    const blob = new Blob([ar] ,{ type:"application/octet-stream" });
                    // console.log(blob)                    
                    FileSaver.saveAs( blob, json.filename );                    
                }
                else {
                    alert("Fehler " + ( res.error ? res.error : "" + res.status + " " + res.statusText))
                }                
            }            
        }
    })

    const deleteDocument = ( async(evt) => {
        const idattr = evt.currentTarget.getAttribute('id')
        if (idattr && idattr.startsWith('button'))
        {            
            const docid = Number.parseInt( idattr.substring(6) )
            if (!Number.isNaN(docid)) {  
                console.log("DOCID " + docid)              
                let message = ""
                try {
                    const res = await fetch ("/data/documents/delete/" + docid,  { method: 'DELETE' } )                
                    if (res.status === 200) {  
                        setreload(!reload)
                        message = "OK"
                    } else {
                        message = res.status + " " + res.statusText
                    }
                }
                catch (err) {
                    message = "" + err
                }
                console.log(message)
            }
        }
    })

    const columnclick = (evt) => {   
        if (columnsSorter) {
            const res = columnsSorter.sortColumn(evt)
            if (res) {                
                setlist(columnsSorter.rdata)
                setSortOrder(columnsSorter.sortOrder)
                setLastSort(columnsSorter.lastSort)
            }
        }
    }

    const dragDropUploadCallback = (cmd, param) => {
        console.log('upload')
        if (cmd === 'upload') {
            setreload(!reload)
        }
    }

    const _seteinausgang = (value) => {
        // console.log(value)
        seteinausgang(value)
        setreload(!reload)
    }

  return (
    <div className='sm_table_div'>
        { mid && <table className='sm_table'>
            <tbody>
                <tr>
                    <td width='80%'  valign='top'>                        
                        {/* { list && list.length>0 && <div className='sm_table_div'> */}
                        <div className='sm_table_div'>
                            <table className='sm_table' >
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Dokumente einer Maßnahme</th>
                                        <td>
                                            <FormGroup
                                                type='checkbox3'
                                                name='documenttype'
                                                value={einausgang}                                                             
                                                label={ 
                                                    (einausgang === CHECKBOX_STATES.Empty ? ` Ausgang ` : 
                                                     einausgang === CHECKBOX_STATES.Checked ? ' Eingang ' : ' Alle ') }
                                                onChange={_seteinausgang}
                                            />
                                        </td>
                                    </tr>
                                    <tr className='table-blank-row'></tr>
                                    <tr>                                        
                                        <SortableTableHeader className='w25 al pl5' onClick={columnclick} title='Dateiname' lastSort={lastSort} sortOrder={sortOrder} />                                        
                                        <SortableTableHeader className='w5 ar pl5' onClick={columnclick} title='Größe' lastSort={lastSort} sortOrder={sortOrder} />                                        
                                        <SortableTableHeader className='w10 al pl5' onClick={columnclick} title='Erstellt am' lastSort={lastSort} sortOrder={sortOrder} />                                        
                                        <SortableTableHeader className='w35 al pl5' onClick={columnclick} title='Typ' lastSort={lastSort} sortOrder={sortOrder} />
                                        <th className='w5 al pl5'>Download</th>   
                                        {authenticationservice.isAdmin && <th className='w10 al pl5'>Aktion</th> }
                                    </tr>
                                </thead> 
                                { list && list.length>0 && 
                                <tbody>                                    
                                    { list.map ( ({ id, created, documents_id, mimetype, filename, Document }) =>
                                        {
                                            return (<tr key={id}>
                                                <td className='w25'>{filename}</td>
                                                <td className='w5 ar pr20'>{Document.filesize}</td>
                                                <td className='w10'>{toShortDateTimeString(created)}</td>
                                                <td className='w35'>{mimetype}</td>
                                                <td className='w5'>
                                                    {documents_id ? <a onClick={onDownload} href={`#${documents_id}`}>Datei</a> : ''}
                                                </td>
                                                {authenticationservice.isAdmin && <td>
                                                    <button 
                                                        title="Löschen" 
                                                        onClick={deleteDocument}
                                                        id={`button${documents_id}`}
                                                        // hidden={!massnahmeId}
                                                        // disabled={!canEdit || !einrichtungsId } // || !massnahmeId}
                                                    >
                                                        <img src="delete.png" width="17" height="15" alt="löschen"/>
                                                    </button>
                                                </td>}
                                            </tr>)
                                        })
                                    }
                                </tbody>  
                                }                        
                            </table>
                        </div> 
                        {/* } */}
                    </td>                    
                    <td className='td_label_center'>
                        <DragDropUploadForm 
                            mid={mid} 
                            eid={eid}
                            tid={tid}
                            aid={aid}
                            callback={dragDropUploadCallback}
                        />
                    </td>
                </tr>
            </tbody>
        </table> }
    </div>
  )
}

DokumenteEinerMassnahme.propTypes = {}

export default DokumenteEinerMassnahme