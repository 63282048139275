import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import { AiOutlineSave } from 'react-icons/ai';
import { FcCancel } from 'react-icons/fc';


import { registerLocale } from 'react-datepicker';
import de from "date-fns/locale/de";

import ConfigIcon from '../../util/configicon';
import LastEdit from '../../components/app/last-edit'
import BeschaeftigungList from '../beschaeftigung/BeschaeftigungList'
// import { toShortDateString } from '../../util/date-utils.js'
import { selectForGeschlechtCombo } from '../../util/combo-utils.js'
import FormGroup from '../../components/shared/form-group'
import FetchService from '../../services/FetchService';
import ParseResult from '../../util/ParseResult';
import { authenticationService, StorageService } from '../../services';
import { toValidJsonObject } from '../../util/json2array';
import { formatTeilnehmer } from '../../util/formate';
import DragDropUploadForm from '../../components/shared/dndupload/DragDropUploadForm';
import UpdateHandler from '../../util/UpdateHandler';
import { Dates } from 'dkgcommon';
import { WindowNames } from '../WindowNames';


registerLocale("de", de)

const EditTeilnehmer = (props) => { 

    const H = useHistory();
    
    const [errorText, setErrorText] = useState(null)   
    const [geschlechtOptions, setGeschlechtOptions] = useState(null)
    
    const [teilnehmerId, setTeilnehmerId] = 
        useState(Number.parseInt(new URL(window.location.href).searchParams.get("tid"))); 
    
    const [id, setId] = useState(0);
    const [title, setTitle] = useState(null);
    const [vorname, setVorname] = useState(null);
    const [nachname, setNachname] = useState(null);
    const [geburtsName, setGeburtsName] = useState(null);
    const [geburtsDatum, setGeburtsDatum] = useState("Invalid Date");
    const [arztNummer, setArztNummer] = useState(null);
    const [geschlecht, setGeschlecht] = useState(null);
    const [datumApprobation, setDatumApprobation] = useState("Invalid Date");
    const [editUser,setEditUser] = useState(null)
    const [editTime, setEditTime] = useState(null)
    const [teilnehmer, setTeilnehmer] = useState(null);
    const [canEdit] = useState(authenticationService.currentUser.category > 0)
    const minDate = '1942-01-01'
    const maxDate = Dates.now(-18);
    const maxDateApprobation = Dates.now(0);
    // console.log(maxDate)
    
    useEffect(() => {
        async function asyncFetchTeilnehmerForSelect()
        {     
            const teilnehmerId = new URL(window.location.href).searchParams.get("tid");
            if (teilnehmerId) {               
                try {
                    const response = await fetch('/data/teilnehmer/' + teilnehmerId, { method: 'GET' })
                    if (response.ok) {
                        // console.log("response ok")
                        
                        const result = await response.json();  
                        // console.log(result)
                        // console.log(" GeburtsDatum " + result.geburtsDatum)
                        // console.log(" Approbation  " + result.datumApprobation)                        
                        // setErrorText(JSON.stringify(result))

                        setId(result.id)
                        setTitle(result.titel)
                        setVorname(result.vorname)
                        setNachname(result.nachname)
                        setGeburtsName(result.geburtsName)
                        if (result.geburtsDatum) {
                            setGeburtsDatum(new Date(Date.parse(result.geburtsDatum)))
                        }
                        setArztNummer(result.arztNr)
                        setGeschlecht(result.geschlecht)
                        if (result.datumApprobation) {
                            setDatumApprobation(new Date(Date.parse(result.datumApprobation)))                            
                        }
                        
                        setEditUser(result.LetzterBearbeiter)
                        setEditTime(result.LetzteAenderung)
                        setTeilnehmer(formatTeilnehmer(result, "in"))

                        return result;
                    }
                    else 
                    {
                        throw new Error(response.status + " " + response.statusText)
                    }
                }
                catch (err) {
                    console.log(err)
                    console.log(JSON.stringify(err))    
                    setErrorText(err)       
                            
                }    
            } 
            else 
            {
                const sd = toValidJsonObject(localStorage.getItem(StorageService.TeilnehmerData))                
                if (sd) 
                {
                    console.log(JSON.stringify(sd))                    
                    setNachname(sd.nachname)
                    setVorname(sd.vorname)
                    setTitle(sd.titel)
                    setGeburtsName(sd.geburtsname)
                    if (sd.geburtsdatum) {
                        setGeburtsDatum(new Date(Date.parse(sd.geburtsdatum)))
                    }
                    if (sd.datumApprobation) {
                        setDatumApprobation(new Date(Date.parse(sd.datumApprobation)))                            
                    }                    
                    setTeilnehmer(sd , "in")
                }
            }
        }    
        asyncFetchTeilnehmerForSelect();
    } , [])  

    useEffect(() => {
        async function asyncFetchGeschlechtForSelect()
        {    
            setGeschlechtOptions(await selectForGeschlechtCombo()) 
        }
        asyncFetchGeschlechtForSelect();
    } , [])  

    const savedata = async () =>
    {    
        if (!nachname) {
            alert("Fehler: Es muss ein Nachname angegeben werden.")
            return
        }
        if (!vorname) {
            alert("Fehler: Es muss ein Vorname angegeben werden.")
            return
        }
        if (!geschlecht) {
            alert("Fehler: Es muss ein Geschlecht ausgewählt werden.")
            return
        }
        const gebDatum = Dates.toValidDate(geburtsDatum)
        const approDatum = Dates.toValidDate(datumApprobation) 
        if (gebDatum)
        {
            if (gebDatum < new Date(minDate)) {
                alert("Fehler: Das Geburtsdatum ist kleiner als der " + new Date(minDate).toLocaleDateString() + ".")
                return;
            }
            if (gebDatum > new Date(maxDate)) {
                alert("Fehler: Das Geburtsdatum ist größer als der " + new Date(maxDate).toLocaleDateString() + ".")
                return;                
            }
        } 
        if (approDatum)
        {
            if (approDatum < new Date(minDate)) {
                alert("Fehler: Das Approbationsdatum ist kleiner als der " + new Date(minDate).toLocaleDateString() + ".")
                return;
            }
            if (approDatum > new Date(maxDateApprobation)) {
                alert("Fehler: Das Approbationsdatum ist größer als der " + new Date(maxDateApprobation).toLocaleDateString() + ".")
                return;                
            }
        }
        if (gebDatum && approDatum)
        {
            if (approDatum < gebDatum) {
                alert("Fehler: Das Approbationsdatum liegt vor dem Geburtsdatum.")
                return;
            }
            const minAlter = 18
            const minApprodatum = Dates.addYears(gebDatum, minAlter)
            if (approDatum < new Date(minApprodatum))
            {
                alert("Fehler: Das Approbationsdatum liegt weniger als "+  minAlter + " Jahre nach dem Geburtsdatum.")
                return;
            }
        }


        const data = 
        {
            id : id,
            titel : title,
            vorname : vorname,         // @todo Feld ist in der DB nullable
            nachname : nachname,       // OK Nachname cannot be NULL
            geburtsName : geburtsName,
            geburtsDatum : gebDatum,
            arztNr : arztNummer,
            geschlecht : geschlecht,      
            datumApprobation : approDatum
        }                
        const msg = await FetchService.saveTeilnehmer(data)
        if (!msg || !msg.startsWith("OK"))
        {
            alert("Fehler: " + msg)
            return;
        } 
        if (id) {            
            UpdateHandler.updateStorageTN(id)
            if (window.name === WindowNames.TID)
            {
                window.close()
            }
            else
            {
                window.history.back();
            }                 
        } else {
            const newId = ParseResult.parseIdFromText(msg);
            setId(newId);
            setTeilnehmerId(newId);
            H.replace('/Teilnehmer/Edit?tid=' + newId)                     
            UpdateHandler.updateStorageTN(newId)
        }
    }

    const canceldata = () =>
    {
        // window.location = document.referrer;          
        if (window.name === WindowNames.TID)
        {
            window.close()
        }
        else
        {
            window.history.back();
        } 
    }
    
    const _setGeburtsDatum = (d) => { setGeburtsDatum(d) }

    const _setDatumApprobation = (d) => { setDatumApprobation(d) }    

    return (
        <div>           
            { errorText && <h3>{errorText}</h3> }            
            <div className="sm_table_div">
            <table >
                <thead>   
                    <tr className="table-blank-row"></tr>                          
                    <tr>
                        <th colSpan={4}>
                            <h3>{ teilnehmerId ? 'Teilnehmer bearbeiten' : 'Teilnehmer anlegen'}{canEdit ? '' : ' (Ansicht)'}</h3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-blank-row"><td></td><td></td><td></td><td></td></tr>
                    <tr>
                        <td className="td_label">ID:</td>
                        <td>{id}</td>
                        <td width={6}></td>
                        {/* <td rowSpan={9}>
                            {teilnehmerId && 
                                <DragDropUploadForm title='Dokumente' tid={teilnehmerId} aid={4567}></DragDropUploadForm>
                            }
                        </td> */}
                    </tr>
                    <tr>
                        <td className="td_label">Titel:</td>
                        <td>
                            <FormGroup 
                                type="text"
                                value={title}
                                onChange={setTitle}
                                disabled={!canEdit}
                            />                            
                        </td><td></td><td></td>
                    </tr>
                    <tr>                        
                        <td className="td_label">Nachname:</td>
                        <td>
                            <FormGroup 
                                type="text"
                                value={nachname}
                                onChange={setNachname}
                                disabled={!canEdit}
                            />
                        </td><td></td><td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Vorname:</td>                        
                        <td>
                            <FormGroup 
                                type="text"
                                value={vorname}
                                onChange={setVorname}
                                disabled={!canEdit}
                            />
                        </td><td></td><td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Geburtsdatum:</td>                        
                        <td>
                            <FormGroup
                                type="html-date"
                                className="form-control" //"td_datepicker"
                                value={geburtsDatum}
                                onChange={_setGeburtsDatum}
                                disabled={!canEdit}
                                min={minDate}
                                max={maxDate}                                
                            />                           
                        </td><td></td><td></td>
                    </tr>
                    <tr>
                        <td className="td_label">Geburtsname:</td>
                        <td>
                            <FormGroup 
                                type="text"
                                value={geburtsName}
                                onChange={setGeburtsName}
                                disabled={!canEdit}
                            />
                        </td><td></td><td></td>
                    </tr>
                    <tr>                        
                        <td className="td_label">Geschlecht:</td>
                        <td>
                            <FormGroup 
                                type="select"
                                options={geschlechtOptions}
                                value={geschlecht}
                                onChange={setGeschlecht}
                                readonly={!canEdit}
                            />
                        </td><td></td><td></td>                   
                    </tr>
                    <tr>
                        <td className="td_label">Arzt Nummer:</td>
                        <td>
                            <FormGroup 
                                type="text"
                                value={arztNummer}
                                onChange={setArztNummer}
                                disabled={!canEdit}
                            />  
                        </td><td></td><td></td>                      
                    </tr>
                    <tr>
                        <td className="td_label">Datum Approbation:</td>
                        <td>
                            <FormGroup
                                type="html-date"
                                className="form-control" //"td_datepicker"
                                value={datumApprobation}
                                onChange={_setDatumApprobation}
                                disabled={!canEdit}
                                min={minDate}
                                max={maxDateApprobation}
                            />                  
                        </td><td></td><td></td>
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr>                        
                        <td className='td_center' colSpan={4}>
                            <LastEdit edituser={editUser} edittime={editTime}></LastEdit>
                        </td>
                    </tr>
                    <tr className="table-blank-row"><td></td><td></td></tr>
                    <tr>
                        <td className="td_label_right">
                            <button className="exportbtn" title="Speichern des Teilnehmers" onClick={savedata} disabled={!canEdit}>
                                <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                            </button>                            
                        </td>                        
                        <td >                           
                            <button className="cancelbtn" title="Zurück ohne Speichern" onClick={canceldata}>
                                <ConfigIcon><FcCancel/></ConfigIcon>&nbsp;Abbrechen
                            </button>                            
                        </td>
                    </tr>
                    <tr className="table-blank-row"></tr>
                    <tr className="table-blank-row"></tr>                                  
                </tbody>
            </table>            
            </div>
            { !Number.isNaN(teilnehmerId) && <div>
                <BeschaeftigungList tid={teilnehmerId} teilnehmer={teilnehmer}/> 
            </div> }
            <div id="oscilloscope">
                {/* <form action="/data/documents/upload" method="post" encType="multipart/form-data" > */}
                {/* <form onSubmit={submitFile} >                
                    <input name="file1" type="file" ></input><br></br>
                    <input name="tid" type='hidden' value={125} />
                    <input name="mid" type='hidden' value={1125} />
                    <input name="eid" type='hidden' value={5125} />
                    <input name="aid" type='hidden' value={6125} />
                    <input type="submit"></input>
                </form> */}
                
            </div>
            {/* <div>
                <DragDropUploadForm></DragDropUploadForm>
            </div> */}
            {/* <div className='sm_table_div'>
            <table className="sm_table_60">
                <tbody>
                    <tr>
                        <td><DragDropUploadForm></DragDropUploadForm></td> 
                    </tr>
                </tbody>
            </table>
            </div> */}
        </div>
    );

}

export default EditTeilnehmer

