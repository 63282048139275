import React, { useState, useEffect } from 'react'
import { AiOutlineSave } from 'react-icons/ai';
import ConfigIcon from '../../util/configicon';

import { authenticationService } from '../../services';
import FormGroup from '../../components/shared/form-group'
import { X } from './X';

const Auszahlung = (props) => {

    const [canEdit, setCanEdit] = useState(authenticationService.currentUser.category > 0)
    
    const [mid, setMid] = useState(null)
    const [aid, setAid] = useState(null)

    // const [massnahmeId, setMassnahmeId] = useState(null)
    // const [auszahlungsId, setAuszahlungsId] = useState(null)
    // "verwendungszweck" : "DKG, 45937, Renz, Foerderung der Allgemeinmed."

    const [teilnehmer_name, setTeilnehmer_name ] = useState(null)
    const [teilnehmer_vorname, setTeilnehmer_vorname] = useState(null)
    const [asp_titel, setAsp_titel] = useState(null)
    const [asp_vorname, setAsp_vorname] = useState(null)
    const [asp_nachname, setAsp_nachname] = useState(null)
    const [asp_anrede, setAsp_anrede] = useState(null)
    const [verwendungszweck, setVerwendungszweck] = useState(null)
    const [verwendungszweckZahlung, setVerwendungszweckZahlung] = useState(null)
    const [massnahmeStatus, setMassnahmeStatus] = useState(0);
    
    useEffect(() => {
        async function asyncFetchAuszahlungForSelect()
        {          
            // console.log(props.data)
            const pd = JSON.parse(props.data)
            const m = pd[0].mid
            const a = pd[1].aid

            if (m) {
                setMid(m)
                if (a) {
                    setAid(a)                
                    try {                    
                        const response = await fetch('/data/auszahlungen/' + a, { method: 'GET' })
                        if (response.ok) {
                            // console.log("response ok")
                            const result = await response.json();                         
                            console.log("AUSZAHLUNG: " + JSON.stringify(result))
                            setTeilnehmer_name(result.teilnehmer_name)
                            setTeilnehmer_vorname(result.teilnehmer_vorname)
                            setAsp_titel(result.asp_titel)
                            setAsp_vorname(result.asp_vorname)
                            setAsp_nachname(result.asp_nachname)
                            setAsp_anrede(result.asp_anrede)
                            setVerwendungszweck(result.verwendungszweck)
                            setVerwendungszweckZahlung(result.verwendungszweckZahlung)
                            if (result.Massnahmen) {
                                console.log("Massnahmestatus: " + result.Massnahmen.status)
                                setMassnahmeStatus(result.Massnahmen.status)
                            }
                        }
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
            }
        }
        asyncFetchAuszahlungForSelect()
    },[])

    const savedata = async () => {
        console.log("Savedata")
        const response = await fetch('/data/auszahlungen/updateverwendung', 
        { 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                verwendungszweck : verwendungszweck,
                id : aid,
                mid: mid
            }),
            method: 'POST' 
        })
        if (response.status === 200) {        
        } else {
            const txt = await response.text()
            alert(txt)
        }
    }

    return (
        <div className="sm_table_div_">            
            <table className="sm_table_50">
                <thead>
                    <tr>
                        <th colSpan="2" className="td_label_center">Name bei Auszahlung</th>
                        <th width='50px'></th>
                        <th colSpan="4" className="td_label_center">Ansprechpartner bei Nachweis/Auszahlung</th>
                    </tr>
                    <tr className="table-blank-row4"></tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="td_label">Vorname:</td><td>{teilnehmer_vorname}</td>
                        <td></td>
                        <td className="td_label">Anrede:</td>
                        <td className="td_label">Titel:</td>
                        <td className="td_label">Vorname:</td>
                        <td className="td_label">Nachname:</td>
                    </tr>                    
                    <tr>
                        <td className="td_label">Nachname:</td><td>{teilnehmer_name}</td>  
                        <td></td>                          
                        <td className="td_label">{asp_anrede}</td>                 
                        <td className="td_label">{asp_titel}</td>
                        <td className="td_label">{asp_vorname}</td>
                        <td className="td_label">{asp_nachname}</td>                    
                    </tr>
                    <tr className="table-blank-row4"></tr>                                   
                    <tr>                        
                        <td className="td_label">Verwendungszweck:</td>                        
                        {!canEdit || X.calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11, massnahmeStatus) ?
                            <td colSpan="6">
                                <>
                                    {verwendungszweck}
                                </>
                            </td>
                            :                            
                            <>                                                       
                                <td colSpan={5}>
                                    <FormGroup 
                                        type="text"
                                        value={verwendungszweck}
                                        // disabled= {!canEdit || X.calcDisabled(X.B1 | X.B2 | X.B3 | X.B4 | X.B5 | X.B7 | X.B8 | X.B10 | X.B11, massnahmeStatus)}
                                        onChange={setVerwendungszweck}
                                    />     
                                </td>
                                <td>
                                    <button 
                                        className="exportbtn" 
                                        title="Speichern der Maßnahme"  
                                        onClick={savedata}
                                        // disabled={!canEdit || !einrichtungsId || !teilnehmerId}  
                                    >
                                        <ConfigIcon><AiOutlineSave/></ConfigIcon>&nbsp;Speichern
                                    </button>
                                </td>
                            </>
                        }                        
                    </tr>
                    <tr>
                        <td className="td_label">SEPA-Verwendung:</td><td colSpan="5">{verwendungszweckZahlung}</td>
                    </tr>
                </tbody>
            </table>                 
        </div>
    )
}

export default Auszahlung
