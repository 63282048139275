import React from 'react';
import Translater from '../translater/';
import Dates from '../../../util/Dates';

import { TextControl, TextAreaControl, PasswordControl, 
         CheckboxControl, SelectControl, UploadControl, 
         Checkbox3Control, NumberControl, RadioControl,
         DateControl, ReactDateControl } from '../editor-controls/';
//import { DateControl from '../editor-controls/date-control';


const FormGroup = ({ type, label, children, name, value, options, onChange, 
    rows, cols, hidden, className, contentClassName, accept, readonly,
    disabled, autocomplete, placeholder, min, max, checked, empty }) => {      

    let content = children;

    if ((type === 'text') || (type === 'hidden')) {          
        content = <TextControl 
            type={type}
            name={name} 
            value={value} 
            onChange={onChange} 
            readonly={readonly || false} 
            disabled={disabled || false}
            autocomplete={autocomplete}
            placeholder={placeholder}
            hidden={hidden}
        />
    } 
    else if (type === 'number') {
        content = <NumberControl
            name={name} 
            value={value} 
            min={min}
            max={max}
            onChange={onChange} 
            readonly={readonly || false} 
            disabled={disabled || false}
            autocomplete={autocomplete}
            placeholder={placeholder}
        />
    }
    else if (type === 'textarea'){
        content = <TextAreaControl 
            name={name} 
            value={value}
            rows={rows}
            cols={cols}
            className={className}
            onChange={onChange} 
            readonly={readonly || false} 
            disabled={disabled || false}
        />
    }
    else if (type === 'password'){
        content = <PasswordControl name={name} value={value} onChange={onChange} />
    } 
    else if (type === 'upload') {
        content = <UploadControl 
            name={name} 
            value={value || ''} 
            onChange={onChange} 
            accept={accept} 
            disabled={disabled || false} />
    } 
    else if (type === 'select'){
        content = <SelectControl 
                    name={name} value={value} onChange={onChange} 
                    className={contentClassName} options={options}  
                    disabled={disabled || false}  
                    readonly={readonly || false}
                    empty={empty && true}
                 />
    }
    else if (type === 'checkbox'){
        content = <CheckboxControl name={name} value={value} onChange={onChange} disabled={readonly || false}/>

        return (
            <div className={`form-group form-check ${hidden === true ? 'd-none' : ''} ${className}`} >  
                <label htmlFor={name} className="form-check-label" >
                    { content }
                    <Translater text={label} />
                </label>
            </div>
        )
    }
    else if (type === 'checkbox3') {
        content = <Checkbox3Control name={name} value={value} onChange={onChange} />
        return (
            <div className={`form-group form-check ${hidden === true ? 'd-none' : ''} ${className}`} >  
                <label htmlFor={name} className="form-check-label" >
                    { content }
                    <Translater text={label} />
                </label>
            </div>
        )
    }
    else if (type === 'radio') {
        content = <RadioControl name={name} value={value} checked={checked} onChange={onChange} />
        return (
            <div className={`form-group form-check ${hidden === true ? 'd-none' : ''} ${className}`} >
                <label htmlFor={name} className="form-check-label" >
                    { content }
                    <Translater text={label} />
                </label>
            </div>
        )
    }
    else if (type === 'html-date') {
        content = <DateControl 
                    className={className}
                    name={name} 
                    value={Dates.toIsoDateString(value)} 
                    onChange={onChange} 
                    disabled={disabled} 
                    min={min}
                    max={max}
                  />
        return (            
            <>{content}</>
        )
    }
    else if (type === 'react-date') {
        content = <ReactDateControl 
                    name={name} 
                    value={value} 
                    onChange={onChange} 
                    disabled={disabled} 
                    min={min}
                    max={max}
                  />
        return (            
            <>{content}</>
        )
    }

    return (
        <div className={`form-group ${hidden === true ? 'd-none' : ''}${className}`}>
            <label htmlFor="id">
                <Translater text={label} />
            </label>
            
            { content }
        </div>
    )
}
export default FormGroup; 