import DocxResultMapper from "../util/DocxResultMapper";

class FetchService 
{
    static async saveKonto(id, einrichtungen_id, ik, inactiv, iban)
    {
        const data = 
        {
            id : id,
            einrichtungen_id : einrichtungen_id,
            ik : ik,
            blz : null,
            kontonr : null,
        	iban : iban,
            bic : null,
            iban_isok: null,
            bic_isok: null,
            inaktiv: inactiv,
            LetzterBearbeiter: null,
            LetzteAenderung: null,
        }
        try {
            const response = await fetch("/data/konten/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async saveAnsprechpartner(data)
    {
        try {
            const response = await fetch("/data/ansprechpartner/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async saveBeschaeftigung(data)
    {
        try {
            const response = await fetch("/data/beschaeftigungen/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async saveTeilnehmer(data)
    {
        try {
            const response = await fetch("/data/teilnehmer/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async saveEinrichtung(data)
    {
        try {
            const response = await fetch("/data/einrichtungen/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async saveMassnahme(data)
    {
        try {
            const response = await fetch("/data/massnahmen/update", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async loadJoblist()
    {
        try {
            const response = await fetch("/data/printjobs");
            if (response.ok) {
                const res = await response.json();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async updatePrintjob( printids, printed)
    {
        try {
            const path = (printed ? 'updateprinted' : 'updatedeleted')
            const response = await fetch('/data/printjobs/' + path, {
                headers: {'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(printids),
            });
            if (response.ok) {
                return await response.text()
            }
            return response.status + " " + response.statusText;
        }
        catch (err) {
            return err;
        }
    }
    

    static async loadEinrichtungsDatalist(tid)
    {
        try {
            if(!tid) {
                tid=0
            }
            const response = await fetch("/data/einrichtungen/datalist/" + tid);
            if (response.ok) {
                const res = await response.json();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async loadTeilnehmerDatalist(eid)
    {
        try {
            if (!eid) {
                eid = 0
            }
            const response = await fetch("/data/teilnehmer/datalist/" + eid);
            if (response.ok) {
                const res = await response.json();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async loadEinrichtungsData(eid) 
    {
        try {
            const response = await fetch("/data/einrichtungen/" + eid);
            if (response.ok) {
                const res = await response.json();
                return res;
            }
            return response.status + " " + response.statusText;
        }
        catch(err) {
            return err;
        }
    }

    static async generateDocx(path) {
        const result = {
            data : null,
            status : 200,
            statusText : "",
            error : "",
        }
        try {                    
            const response = await fetch( path );
            if (response.ok) 
            {    
                const blob = await response.blob();                                           
                result.data = new DocxResultMapper(blob);
                await result.data.init();
            }
            else
            {
                result.error = await response.text();
            }
            result.status = response.status;
            result.statusText = response.statusText;
        }
        catch(err) {
            result.error = err;            
        }
        return result;
    }

    static async generateMassnahmeBrief (mid) {
        return await FetchService.generateDocx("/data/export/doc/massnahme/brief/" + mid)        
    }

    static async generateEinrichtungsBrief (eid, aid) {
        return await FetchService.generateDocx("/data/export/doc/einrichtung/brief/" + eid + "/" + aid);
    }

    static async generateTeilnahmebestaetigung (mid) {
        return await FetchService.generateDocx("/data/export/doc/teilnahmebestaetigung/" + mid)       
    }

    static async generateBestaetigungsschreiben (mid) {
        return await FetchService.generateDocx("/data/export/doc/bestaetigungsschreiben/" + mid)
    }

    static async generateNachweisSchreiben(mid) {        
        return await FetchService.generateDocx("/data/export/doc/nachweis/" + mid)
    }

    static async generateKorrekturSchreiben(mid) {        
        return await FetchService.generateDocx("/data/export/doc/korrekturschreiben/" + mid)
    }

    static async generateMassnahmeMail (mid) {
        return await FetchService.generateMail("/data/export/mail/massnahme/anschreiben/" + mid)        
    }

    static async generateEinrichtungsMail (eid, aid) {
        return await FetchService.generateMail("/data/export/mail/einrichtung/anschreiben/" + eid + "/" + aid)        
    }

    static async downloadDocument(docid) {
        return await FetchService.generateDocx("/data/documents/download/" + docid)
    }

    static async generateMail(path) {
        const result = {
            data : "",
            status : 200,
            statusText : "",
            error : "",
        }
        try {            
            const response = await fetch(path);
            if (response.ok) {
                result.data = await response.text();        
            } else {
                result.error = await response.text();
            }
            result.status = response.status;
            result.statusText = response.statusText;
        }
        catch(err) {
            result.error = err;            
        }
        return result;
    }
    
    static async generateMassnahmenProTeilnehmerExcel(einrichtungsId) {
        return await FetchService.generateXls("/data/export/excel/massnahmen/" + einrichtungsId )
    } 
        
    static async generateZahlungslaeufeCsv() {
        return await FetchService.generateCsv("/data/export/csv/zahlungslaeufe/")
    }

    static async generateZahlungslaeufeExcel() {
        return await FetchService.generateXls("/data/export/excel/zahlungslaeufe/")       
    }   

    static async generateAbrechnungsUebersicht(yearValue, type = 1) {
        if (type === 1 ) {
            return await FetchService.generateCsv("/data/export/csv/p32report/" + yearValue)
        } 
        else if (type === 2) {
            return await FetchService.generateXls("/data/export/excel/p32report/" + yearValue)
        }
        throw new Error("Unknown Export Type: " + type)
    }  

    static async generateP31Report(yearValue, type = 1) {
        if (type === 1) {
            return await FetchService.generateCsv("/data/export/csv/p31report/" + yearValue)
        }
        else if (type === 2) {
            return await FetchService.generateXls("/data/export/excel/p31report/" + yearValue)
        }
        throw new Error("Unknown Export Type: " + type)
    }

    static async generateP33Report(yearValue, type = 2) {
        // if (type === 1) {
        //     return await FetchService.generateCsv("/data/export/csv/p31report/" + yearValue)
        // }
        // else 
        if (type === 2) {
            return await FetchService.generateXls("/data/export/excel/p33report/" + yearValue)
        }
        throw new Error("Unknown Export Type: " + type)
    }

    

    static async readKontollListen(type) {
        if ((type === 9) || (type === 19)) {            
            return await FetchService.generateJson("/data/zahlungslaeufe/controllist/" + type)                                                   
        }
        throw new Error("Unknown KontrollListen Type: " + type)
    }

    static async readRechnungsDaten() {
        return await FetchService.generateJson("/data/zahlungslaeufe/rechnungsdaten/")
    }

    static async generateKontrollListen(type) {
        if ((type === 9) || (type === 19)) {
            return await FetchService.generateXls("/data/export/excel/controllist/" + type)
        }
        throw new Error("Unknown Export Type: " + type)
    }

    static async generateRechnungsDaten() {
        return await FetchService.generateXls("/data/export/excel/rechnungsdaten/")
    }

    static async generateSepaAuszahlung() {
        return await FetchService.generateJson("/data/zahlungslaeufe/sepaauszahlung/")
    }

    static async loadRechnungsDatenTemplate() {
        return await FetchService.generateXls("/data/export/excel/rechnungsdaten/template/")
    }

    static async loadRechnungsDatenFile() {
        return await FetchService.generateXls("/data/export/excel/rechnungsdaten/file/")
    }

    static async finalizeSepaAuszahlung(jahr, znumber, date) 
    {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }
        const data = {
            zjahr : jahr,
            znumber : znumber,
            zdate : date
        }
        try
        {
            const response = await fetch("/data/zahlungslaeufe/sepaauszahlung/update", {
                headers: 
                {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                result.data = res
                result.statusText = response.statusText
                result.status = response.status
                return result
            }
            else
            {
                if (response.status < 500) {
                    result.data = await response.text();
                }
                result.statusText = response.statusText
                result.status = response.status
                return result
            }
        }
        catch (err)
        {         
            result.error = JSON.stringify(err)         
            return result        
        }
    }        

    static async finalizeZahlungslauf(jahr, znumber, date)
    {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }
        const data = {
            zjahr : jahr,
            znumber : znumber,
            zdate : date
        }
        try
        {
            const response = await fetch("/data/zahlungslaeufe/update", {
                headers: 
                {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const res = await response.text();
                result.data = res
                result.statusText = response.statusText
                result.status = response.status
                return result
            }
            else
            {
                if (response.status < 500) {
                    result.data = await response.text();
                }
                result.statusText = response.statusText
                result.status = response.status
                return result
            }
        }
        catch (err)
        {         
            result.error = JSON.stringify(err)         
            return result        
        }
    }        

    static async generateJson(path) {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }
        try 
        {
            const response = await fetch(path);
            if (response.ok) {
                const txt = await response.text()                               
                result.data = txt
                result.error = txt
                const json = JSON.parse(txt) 
                result.data = json
                result.error = ""                                        
            } else {
                const txt = await response.text();
                console.log(txt)
                result.error = txt
            }
            result.status = response.status;
            result.statusText = response.statusText;
        }        
        catch(err) {
            result.error = err;
        }
        return result;
    }

    static async generateCsv(path) {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }
        try 
        {
            const response = await fetch(path);
            if (response.ok) {
                const txt = await response.text()                
                result.data = new Blob([txt], {type: "text/plain;charset=utf-8"});                        
            } else {
                result.error = await response.text();
            }
            result.status = response.status;
            result.statusText = response.statusText;
        }
        catch(err) {
            result.error = err;
        }
        return result;
    }

    static async generateXls(path) {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }
        try
        {
            const response = await fetch(path);
            if (response.ok) {
                result.data = await response.blob();        
            } else {
                result.error = await response.text();
            }
            result.status = response.status;
            result.statusText = response.statusText;
        }         
        catch(err) {
            result.error = err;
        }
        return result;
    }

    static async validatePassword(pass) 
    {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }

        const data = 
        {
            "pass": pass
        }
        try
        {
            const response = await fetch("/data/pwd/validate", {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            if (response.ok) {             
                result.data = await response.text();                   
            } else {
                result.error = await response.text();
            }
            result.status = response.status;
            result.statusText = response.statusText;
        }
        catch (err) {
            result.error = err;
        }
        return result;
    }

    static async calculateErstattung(f_von, f_bis, fachId, prozentual)
    {
        return this.calculate('/data/massnahmen/stat/calculateerstattung', f_von, f_bis, fachId, prozentual)
    }

    static async calculateDauer(f_von, f_bis, fachId, prozentual)
    {
        return this.calculate('/data/massnahmen/stat/calculatedauer', f_von, f_bis, fachId, prozentual)
    }

    static async calculate(path, f_von, f_bis, fachId, prozentual)
    {
        const result = {
            data : "",
            status : 500,
            statusText : "",
            error : "",
        }        
        const data = 
        {
            f_von : f_von,
            f_bis : f_bis,
            fachId: fachId,
            prozentual : prozentual
        }
        const body = JSON.stringify(data)
        try
        {       
            const response = await fetch(path, {
                headers: {                    
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: body
            })
            if (response.ok)
            {
                const sresult = await response.json();
                result.data = sresult;
            }  
            else 
            {
                const sresult = await response.json();
                result.error = sresult
            }   
            result.status = response.status;
            result.statusText = response.statusText;               
        }
        catch (err) {
            result.error = err
        }
        return result;
    }

}

export default FetchService;